import React from 'react'
import { css } from 'emotion'
import { SIDEBAR_WIDTH } from '../FreshworksSideNavigation'

const ACTUAL_HEADER_HEIGHT = 64
const HEADER_BORDER_BOTTOM = 1
const HEADER_HEIGHT = ACTUAL_HEADER_HEIGHT + HEADER_BORDER_BOTTOM

export { HEADER_HEIGHT, ACTUAL_HEADER_HEIGHT }

export default function HeaderContentBox({ children, ...props }) {
  return (
    <header
      className={css`
        width: calc(100vw - ${SIDEBAR_WIDTH}px);
        height: ${ACTUAL_HEADER_HEIGHT}px;
        background: white;
        border-bottom: solid ${HEADER_BORDER_BOTTOM}px #d3dbda;
        display: flex;
      `}
      {...props}>
      {children}
    </header>
  )
}
