import React from 'react'

// used for performance testing your component
// on why did it render
// read more about it here https://github.com/welldone-software/why-did-you-render
;(async function () {
  if (process.env.NODE_ENV === 'development') {
    const whyDidYouRender = await import('@welldone-software/why-did-you-render')

    whyDidYouRender.default(React, {
      // uncomment the below
      // include: [/^Dashboard/],
      // include: [/^Dashboard/, /^UserCoverSection/, /^UserInfoSection/],
      // include: [/^Organisation/],
      // include: [/^Secu/, /^Auth/],
      // include: [/^LanguageProvider/],
      // include: [/^CreateGroup/],
      // include: [/^Acti/],
      // exclude: [/^Box/]
      // exclude: [/^Flex/, /^Box/, /^DataManager/, /^div/, ]
    })
  }
})()
