import humps from 'humps'
const TRANSLATION_BASE_PATH = `my_profile.`
export const PROFILE_FIELD_TYPES = {
  TEXT: 'text',
  SOCIAL: 'social',
  LANGUAGE_DROPDOWN: 'language_dropdown',
  TIMEZONE_DROPDOWN: 'timezone_dropdown'
}

export const FIELD_NAME_PROFILE_PICTURE = 'image'
export const FIELD_NAME_LOCALE = 'locale'
export const FIELD_NAME_TIMEZONE = 'timezone'
export const FIELD_NAME_FIRST_NAME = 'first_name'
export const FIELD_NAME_LAST_NAME = 'last_name'

export const camelizedFirstNameFieldName = humps.camelize(FIELD_NAME_FIRST_NAME)
export const camelizedLastNameFieldName = humps.camelize(FIELD_NAME_LAST_NAME)

export default [
  {
    fieldGroup: 'work_info',
    translationKey: `${TRANSLATION_BASE_PATH}work_information`,
    fields: [
      {
        name: FIELD_NAME_FIRST_NAME,
        type: PROFILE_FIELD_TYPES.TEXT,
        min: 1,
        max: 128,
        translationKey: `${TRANSLATION_BASE_PATH}first_name`,
        displayInUI: true,
        rowIndex: 1,
        shouldSendToServer: true,
        canShowTooltip: false,
        adminRow: 1,
        autoComplete: 'given-name',
        //required field will be overridden based on the profile restrictions and pre-filled Logic
        required: true
      },
      {
        name: 'middle_name',
        type: PROFILE_FIELD_TYPES.TEXT,
        max: 128,
        translationKey: `${TRANSLATION_BASE_PATH}middle_name`,
        displayInUI: true,
        rowIndex: 1,
        shouldSendToServer: true,
        canShowTooltip: false,
        adminRow: 1,
        autoComplete: 'additional-name',
        required: false
      },
      {
        name: FIELD_NAME_LAST_NAME,
        type: PROFILE_FIELD_TYPES.TEXT,
        max: 128,
        translationKey: `${TRANSLATION_BASE_PATH}last_name`,
        displayInUI: true,
        rowIndex: 1,
        shouldSendToServer: true,
        canShowTooltip: false,
        adminRow: 1,
        autoComplete: 'family-name',
        required: true
      },
      {
        name: 'phone',
        type: PROFILE_FIELD_TYPES.TEXT,
        translationKey: `${TRANSLATION_BASE_PATH}work_number`,
        displayInUI: true,
        max: 50,
        rowIndex: 2,
        shouldSendToServer: true,
        canShowTooltip: false,
        adminRow: 2,
        autoComplete: 'tel',
        required: false
      },
      {
        name: 'mobile',
        type: PROFILE_FIELD_TYPES.TEXT,
        translationKey: `${TRANSLATION_BASE_PATH}mobile_number`,
        displayInUI: true,
        max: 50,
        rowIndex: 2,
        shouldSendToServer: true,
        canShowTooltip: false,
        adminRow: 2,
        autoComplete: 'tel',
        required: false
      },
      {
        name: 'email',
        type: PROFILE_FIELD_TYPES.TEXT,
        translationKey: `${TRANSLATION_BASE_PATH}email`,
        displayInUI: false,
        shouldSendToServer: false,
        canShowTooltip: true,
        adminRow: 2,
        autoComplete: 'email',
        required: false
      },
      {
        name: 'company_name',
        type: PROFILE_FIELD_TYPES.TEXT,
        min: 1,
        max: 128,
        translationKey: `${TRANSLATION_BASE_PATH}company`,
        displayInUI: true,
        rowIndex: 3,
        shouldSendToServer: true,
        canShowTooltip: false,
        adminRow: 3,
        autoComplete: 'organization',
        required: false
      },
      {
        name: 'job_title',
        type: PROFILE_FIELD_TYPES.TEXT,
        min: 1,
        max: 128,
        translationKey: `${TRANSLATION_BASE_PATH}job_title`,
        displayInUI: true,
        rowIndex: 3,
        shouldSendToServer: true,
        canShowTooltip: false,
        adminRow: 3,
        autoComplete: 'organization-title',
        required: false
      },
      {
        name: FIELD_NAME_PROFILE_PICTURE,
        displayInUI: true,
        translationKey: `${TRANSLATION_BASE_PATH}profile_image`,
        shouldSendToServer: true,
        canShowTooltip: true,
        adminRow: 3,
        required: false
      }
    ]
  },
  {
    fieldGroup: 'other_info',
    translationKey: `${TRANSLATION_BASE_PATH}other_information`,
    fields: [
      {
        name: FIELD_NAME_LOCALE,
        type: PROFILE_FIELD_TYPES.LANGUAGE_DROPDOWN,
        translationKey: `${TRANSLATION_BASE_PATH}locale`,
        displayInUI: true,
        rowIndex: 1,
        shouldSendToServer: true,
        canShowTooltip: false,
        adminRow: 1,
        required: false
      },
      {
        name: FIELD_NAME_TIMEZONE,
        type: PROFILE_FIELD_TYPES.TIMEZONE_DROPDOWN,
        translationKey: `${TRANSLATION_BASE_PATH}timezone`,
        displayInUI: true,
        rowIndex: 1,
        shouldSendToServer: true,
        canShowTooltip: false,
        adminRow: 1,
        required: false
      },
      {
        name: 'facebook_link',
        type: PROFILE_FIELD_TYPES.SOCIAL,
        min: 1,
        max: 512,
        translationKey: `${TRANSLATION_BASE_PATH}facebook_url`,
        displayInUI: true,
        rowIndex: 2,
        icon: 'facebook',
        shouldSendToServer: true,
        canShowTooltip: false,
        adminRow: 1,
        required: false
      },
      {
        name: 'linkedin_link',
        type: PROFILE_FIELD_TYPES.SOCIAL,
        min: 1,
        max: 512,
        translationKey: `${TRANSLATION_BASE_PATH}linkedin_url`,
        displayInUI: true,
        rowIndex: 2,
        icon: 'linkedin',
        shouldSendToServer: true,
        canShowTooltip: false,
        adminRow: 2,
        required: false
      },
      {
        name: 'twitter_link',
        type: PROFILE_FIELD_TYPES.SOCIAL,
        min: 1,
        max: 512,
        translationKey: `${TRANSLATION_BASE_PATH}twitter_url`,
        displayInUI: true,
        rowIndex: 2,
        icon: 'twitter',
        shouldSendToServer: true,
        canShowTooltip: false,
        adminRow: 2,
        required: false
      }
    ]
  }
]
