import { useContext } from 'react'
import { matchPath } from 'react-router-dom'
import delve from 'dlv'
import CONST, { isRenderedUnderPrefix } from './constants'
import ENV from '../env.config.json'
import translationFile from './../default_translations/en-us.json'
import 'url-search-params-polyfill'
import ROUTE_CONFIG from './route-config'
import Yup from 'yup'
import SECURITY_TEMPLATES from '../pages/Security/Templates/SecurityTemplates'
import { addQueryParams } from './queryParams'
import DateUtils from './dateUtils'
import {
  REACT_APP_CDN_URL,
  RELEASE_SCOPE,
  BUILD,
  ENABLE_UBX_AUDIT_LOGS,
  ENABLE_CUSTOM_DOMAIN,
  ENABLE_SESSION_MANAGEMENT_FEATURE
} from '../config'
import AppStateContext, {
  getDataFromAppStateContext
} from '../components/AppDataProvider/AppStateContext'
import { shouldRenderAccount } from '../pages/Dashboard/utils'
import APP_CONSTANTS from './constants'

const yupPasswordValidation = SECURITY_TEMPLATES.yupPasswordValidation

const OAUTH_URL_PATH = '/oauth/authorize'
const OAUTH_CONSENT_PATH = '/oauth/consent'
const OAUTH_URL_PARAM = { response_type: 'code' }

const passwordErrorToKeyList = {
  PASSWORD_POLICY_INVALID_HISTORY: 'historyCount',
  PASSWORD_CONTAINS_PERSONAL_INFORMATION: 'noPersonalInfo'
}

const REGEX_DEFAULTS = {
  DELIMITERS: {
    START: '^',
    END: '$'
  },
  NEGATE: '^',
  MATCH: {
    ZERO_MORE: '*',
    ONE_MORE: '+'
  },
  SET: {
    START: '[',
    END: ']'
  }
}
const NAME_FIELD_BLACK_LISTED_CHARS = '.<>&=();%":/'
// the following string construct translates to '^[^<>&=()%".:;/]*$'
const NAME_FIELD_REGEX_STR =
  REGEX_DEFAULTS.DELIMITERS.START +
  REGEX_DEFAULTS.SET.START +
  REGEX_DEFAULTS.NEGATE +
  NAME_FIELD_BLACK_LISTED_CHARS +
  REGEX_DEFAULTS.SET.END +
  REGEX_DEFAULTS.MATCH.ZERO_MORE +
  REGEX_DEFAULTS.DELIMITERS.END
const NAME_FIELD_REGEX = new RegExp(NAME_FIELD_REGEX_STR)

const NAME_FIELD_CONFIG = {
  NAME_FIELD_BLACK_LISTED_CHARS,
  NAME_FIELD_REGEX
}

let getTranslationFileLocation = (userLang = '') => {
  // this is because when the CDN URL is '/'
  // it takes the relative path like /user/{id}/profile/translation/json/file
  // hence having this as absolute urls
  const url = REACT_APP_CDN_URL === '/' ? `${CONST.DEFAULT_REDIRECT_PATH}/` : REACT_APP_CDN_URL
  return `${url}${CONST.TRANSLATION_LOCATION}/${userLang}.json`
}

function flattenMessages(nestedMessages, prefix = '') {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    let value = nestedMessages[key]
    let prefixedKey = prefix ? `${prefix}.${key}` : key

    if (typeof value === 'string') {
      messages[prefixedKey] = value
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey))
    }

    return messages
  }, {})
}

function getNonEmptyValues(object) {
  const keys = Object.keys(object)
  let nonEmptyValuesObject = {}
  for (let i = 0; i < keys.length; i++) {
    const value = object[keys[i]]
    if (value && value.length !== 0) {
      nonEmptyValuesObject[keys[i]] = value
    } else {
      nonEmptyValuesObject[keys[i]] = null
    }
  }

  return nonEmptyValuesObject
}

function scrollTop(elem) {
  if (elem) {
    if (elem.scroll && typeof elem.scroll === 'function') {
      elem.scroll({ top: 0, behavior: 'smooth' })
    } else if (elem.scrollTop) {
      // this is for IE browser
      elem.scrollTop = 0
    }
  }
}

function getSubdomain(url) {
  let host
  let subdomain
  try {
    host = new URL(url).host
    subdomain = host.split('.').length > 2 ? host.split('.')[0] : ''
  } catch (e) {
    /* tslint:disable */
    logger.error(`Error while trying to deduce account name for ${url} `, e)
    /* tslint:disable */
  }
  return subdomain
}

function getUrlSegments(url) {
  let urlSegments = {}
  try {
    let _url = new URL(url).host
    let urlFragments = _url.split('.')
    if (urlFragments.length > 2) {
      urlSegments.subDomain = urlFragments.slice(0, urlFragments.length - 2).join('.')
      urlSegments.freshworksDomain = urlFragments
        .slice(urlFragments.length - 2, urlFragments.length)
        .join('.')
    }
  } catch (e) {
    /* tslint:disable */
    logger.error(`Error while trying to fetch segments of URL: ${url} `, e)
    /* tslint:disable */
  }
  return urlSegments
}

const getOrgDomainFromUrlSegments = ({ subDomain, freshworksDomain }) =>
  `${subDomain}.${freshworksDomain}`

function getNormalizedAccountName(account) {
  let accountProto = {
    name: '',
    domain: '',
    urls: [],
    productName: ''
  }
  /**
   * Assigning accountProto to account object to eliminate
   * script errors (that caused rerendering)
   * when the required account keys are undefined
   */
  account = Object.assign(accountProto, account)

  const accountNamePlaceholder = {
    freshchat: 'Freshchat App',
    freshmarketer: 'CRO Suite',
    freshconnect: 'Freshconnect Account'
  }

  const productsHavingSubdomain = [
    'freshdesk',
    'freshsales',
    'freshteam',
    'freshcaller',
    'freshservice',
    'freshmarketer',
    'freshconnect',
    'freshrelease',
    'freshsuccess'
  ]

  const productsHavingSingleAccount = []

  let accountName
  if (productsHavingSubdomain.indexOf(account.productName.toLowerCase()) !== -1) {
    if (account.name && account.name.length > 0) {
      accountName = account.name
    } else {
      account.urls.some((url) => {
        const subdomain = getSubdomain(url)
        if (subdomain) {
          accountName = subdomain
          return true
        } else {
          return false
        }
      })
    }
  } else if (productsHavingSingleAccount.indexOf(account.productName.toLowerCase()) !== -1) {
    accountName = accountNamePlaceholder[account.productName.toLowerCase()]
  } else {
    if (account.name) {
      accountName = account.name
    } else {
      accountName = accountNamePlaceholder[account.productName.toLowerCase()]
      // special condition for freshchat
      // add account id as suffix
      if (account.productName.toLowerCase() === 'freshchat') {
        try {
          const accountID = account.domain.split('.')[0]
          accountName = `${accountName} - ${accountID}`
        } catch (e) {
          console.error('Error deducing account name for freshchat')
        }
      } else if (
        Object.keys(accountNamePlaceholder).indexOf(account.productName.toLowerCase()) === -1
      ) {
        accountName = account.productName.toLowerCase()
      } else {
        accountName = accountNamePlaceholder[account.productName.toLowerCase()]
      }
    }
  }

  return accountName
}

function getNormalizedAccountDomain(account) {
  let accountDomain = account.domain
  if (!!account.unifiedUrlEnabled) {
    accountDomain = `/${account.alias}`
  }
  return accountDomain
}

function isBrowserIE() {
  var ua = window.navigator.userAgent

  var msie = ua.indexOf('MSIE ')
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10) ? true : false
  }

  var trident = ua.indexOf('Trident/')
  if (trident > 0) {
    // IE 11 => return version number
    var rv = ua.indexOf('rv:')
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10) ? true : false
  }

  var edge = ua.indexOf('Edge/')
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10) ? true : false
  }

  // other browser
  return false
}

function getQueryParams(name, queryParam = '') {
  var search = new URLSearchParams(queryParam || window.location.search)
  return search.get(name)
}

const getBooleanQueryParam = (paramKey = '', queryParam) =>
  (getQueryParams(paramKey, queryParam) || '').toLowerCase() === String(true)

function processErrors({ request, response }) {
  if (request.status === 0) {
    // network failure
    return [translationFile.error.connection_failure]
  } else {
    const { data } = response
    // Server sent errors
    if (data && data.error) {
      // 4xx error
      return [data.error]
    } else if (data && data.status === 'error' && data.errors && data.errors.length > 0) {
      const errorMessages = data.errors.map((error) => error.message)
      return errorMessages
    } else {
      // generic server error
      return [translationFile.error.generic_server_error]
    }
  }
}

/**
 * This function was originally copied from react-emotion/styles package
 * this is the way they are figuring out to take out the template strings or a
 * normal string passed to your function template
 *
 * Eg: CDN_URL`image.svg` OR CDN_URL`${image}.${ext}`
 * It handle both the above cases
 *
 * To Parse the template string passed to your function template
 *
 * @templateString args, the template string args
 * @function parseFunc, the args will run through this function if provided
 */
function templeFunctionArgsParser(args, parserFunc) {
  let template = []
  if (args[0] == null || args[0].raw === undefined) {
    template.push.apply(template, parserFunc ? parserFunc(args) : args)
  } else {
    template.push(parserFunc ? parserFunc(args[0][0]) : args[0][0])
    const len = args.length

    for (let i = 1; i < len; i++) {
      template.push(args[i], parserFunc ? parserFunc(args[0][i]) : args[0][i])
    }
  }

  return template
}

function isBrowserIE11() {
  return !!navigator.userAgent.match(/Trident.*rv[ :]*11\./)
}

function isBrowserEdge() {
  return /Edge\/\d./i.test(navigator.userAgent)
}

function getErrorList(errorData = {}) {
  const { details } = errorData,
    errorList = []
  if (details && details.length > 0) {
    details.map((error) => {
      const { field_violations } = error
      if (field_violations && field_violations.length > 0) {
        field_violations.map(({ field }) => {
          errorList.push(field && field.toUpperCase())
        })
      }
    })
  }

  return {
    errorList
  }
}

const getRedirectUriFromError = (err) => {
  const { data: { details = [] } = {} } = err
  for (let detail of details) {
    if (!!detail.errorMap && !!detail.errorMap[CONST.REDIRECT_URI]) {
      return detail.errorMap[CONST.REDIRECT_URI]
    }
  }
  return CONST.DEFAULT_REDIRECT_PATH
}

const handle400ErrorForHashes = (err, errorToHandle, fallbackError, redirectUri) => {
  const { data: { error } = {} } = err
  if (errorToHandle.indexOf(error) > -1) {
    window.location.assign(redirectUri || getRedirectUriFromError(err))
    throw new Error(error)
  }
  if (!!fallbackError) {
    throw new Error(fallbackError)
  }
}

function getErrorCode(status, data, translationPath) {
  const { errorList } = getErrorList(data)
  let errorCode = []
  if (!errorList.length) {
    errorCode =
      status === 429 ? [APP_CONSTANTS.IGNORE_TOO_MANY_REQUESTS] : ['error.generic_server_error']
    return errorCode
  } else {
    const objectOfServerError = delve(translationFile, `${translationPath}.server_errors`, {})
    errorCode = errorList.map((error) =>
      !!objectOfServerError[error]
        ? `${translationPath}.server_errors.${error}`
        : 'error.generic_server_error'
    )
  }
  return errorCode
}

function getServerErrors({ status, data }, translationPath) {
  let errorCode = []

  switch (status) {
    case 0:
      errorCode = ['error.connection_failure']
      break
    case 401:
      errorCode = ['error.unauthorized']
      break
    case 403:
      errorCode = ['error.forbidden_admin_access']
      break
    case 500:
    case 503:
      throw new Error('SERVER_ERROR')
      break
    default:
      /**
       * Though DataManager is setting `errorList` during error response
       * we are explicitly deriving `errorList` from `data` yet again since this utility method is
       * also used in components that do not use DataManager for HTTP calls - like Profile
       */

      errorCode = getErrorCode(status, data, translationPath)
  }

  return errorCode
}

/**
 * strips out the PEM header and carriage return (enter) from the given string
 * and returns the certificate/Validation key as a single liner string
 */
function sanitizePEM(certificate) {
  if (typeof certificate !== 'string') {
    return
  }

  if (certificate.indexOf('BEGIN') !== -1) {
    const lines = certificate.split(/\r?\n|\r/g)
    return lines.slice(1, lines.length - 1).join('')
  } else {
    return certificate.replace(/\r?\n|\r/g, '')
  }
}

function getTitleRoute(pathname) {
  // iterating all the routes from `ROUTE_CONFIG` to get the matched route and its corresponding translationKey
  // this was necessary due to the limitation discussed here 'https://github.com/ReactTraining/react-router/issues/5870'
  // the limitation is `match.params` object is not available globally and only available within the matched component
  // for eg: `/security` route's `match.params` object will be available only within the `Secuirty` component
  // this stops us from identifying the user's `current route`, especially if it involves "hash" as part of the "path param"
  // so identifying the corresponding translationKey is not possible without having `ROUTE_CONFIG (ROUTE -> TRANSLATION KEY)`
  const matchedRoute = ROUTE_CONFIG.filter((value) => {
    let match = matchPath(pathname, {
      path: value.routePath,
      exact: true,
      strict: false
    })
    if (match) return value.translationKey
  })
  return matchedRoute[0] && matchedRoute[0].translationKey
}

function getUserName({ firstName = '', lastName = '' }) {
  if (!(firstName || lastName)) return CONST.FALLBACK_USERNAME
  else {
    if (firstName) return `${firstName} ${lastName}`.trim()
    else return lastName
  }
}

const USER_MGMT_TRANSLATION_KEY = 'user_management'

const getUserRoleTextAndLevel = ({ admin, employee }) => {
  let text = '',
    level = admin ? 'success' : 'neutral'
  const {
    ROLES: { ORG_ADMIN, USER, RESTRICTED_REQUESTOR }
  } = CONST
  if (admin) {
    text = `${USER_MGMT_TRANSLATION_KEY}.roles.${ORG_ADMIN}.role_name`
  } else if (employee) {
    text = `${USER_MGMT_TRANSLATION_KEY}.roles.${USER}.role_name`
  } else {
    text = `${USER_MGMT_TRANSLATION_KEY}.roles.${RESTRICTED_REQUESTOR}.role_name`
  }

  return {
    text,
    level
  }
}

const getUserNameWithEmailFallback = ({ firstName = '', lastName = '', email }) => {
  return !(firstName || lastName) ? email : getUserName({ firstName, lastName })
}

function heapTrack(eventName, eventProperties = {}) {
  if (eventName && window.heap && typeof window.heap.track === 'function') {
    window.heap.track(eventName, eventProperties)
  }
}

function isMobile() {
  return /iPhone|iPad|iPod|Android|Windows Phone|BB/i.test(navigator.userAgent)
}

function isEquivalent(firstObj, secondObj) {
  // Create arrays of property names
  let firstObjProperties = Object.keys(firstObj)
  let secondObjProperties = Object.keys(secondObj)

  // If number of properties is different,
  // objects are not equivalent
  if (firstObjProperties.length != secondObjProperties.length) {
    return false
  }

  for (let i = 0; i < firstObjProperties.length; i++) {
    let propertyName = firstObjProperties[i]

    // If values of same property are not equal,
    // objects are not equivalent
    if (firstObj[propertyName] !== secondObj[propertyName]) {
      return false
    }
  }

  // If we made it this far, objects
  // are considered equivalent
  return true
}

function getPasswordValidator(config, toIntlString) {
  let validator = Yup.string()

  // adding generic max length validation here to avoid
  // setting it in all password pages and also to
  // maintain single source of truth across all instances
  validator = validator['max'](
    ...[
      CONST.FORM_FIELD_CONSTANTS.PASSWORD_LENGTH.MAX,
      CONST.FORM_FIELD_CONSTANTS.LENGTH_VALIDATION_KEY
    ]
  )
  if (config === null) {
    validator = validator['min'](
      ...[CONST.FORM_FIELD_CONSTANTS.PASSWORD_LENGTH.MIN, toIntlString('validation.too_short')]
    )
    return validator
  }

  for (let key in config) {
    if (yupPasswordValidation[key]) {
      const { paramValue, type } = yupPasswordValidation[key]
      if (config[key]) {
        validator = validator[type](
          ...[
            key === CONST.FORM_FIELD_CONSTANTS.LENGTH_VALIDATION_KEY ? config[key] : paramValue,
            `${key}`
          ]
        )
      }
    }
  }
  return validator
}

//To get the objects for a particular key and Value from a array of similar objects
function getObjectsFromArrayForKeyValue(arrayOfObject = [], key, value) {
  return arrayOfObject.filter((obj) => obj[`${key}`] === value)
}

function _getOAuthUrl(queryParams, isConsent) {
  return isConsent
    ? `${OAUTH_CONSENT_PATH}?${queryParams}`
    : `${OAUTH_URL_PATH}?${addQueryParams(queryParams, OAUTH_URL_PARAM)}`
}

function callOauthEndpoint(search, isConsent = false) {
  // This is to prevent the yet another re-authorize initiation
  const filteredSearchParams = getAllQueryParamsExcept([CONST.STEP_UP_AUTH_TYPE_PARAM_KEY], search)
  window.location.assign(
    `${CONST.DEFAULT_REDIRECT_PATH}${_getOAuthUrl(filteredSearchParams, isConsent)}`
  )
}

function getServerErrorForPassword(errorList = []) {
  const errorKeys = []
  errorList.forEach((item) => {
    if (passwordErrorToKeyList[item]) {
      errorKeys.push(passwordErrorToKeyList[item])
    }
  })
  return errorKeys
}

const isUrlAbsolute = (url) => /^https?:\/\/|^\/\//i.test(String(url))

const StringUtils = {
  capitaliseFirstLetter: (str = '') => `${str.substring(0, 1).toUpperCase()}${str.substring(1)}`,
  getCountBasedTerm: (count = 0, singularTerm = '', pluralTerm = '') =>
    count === 1 ? singularTerm : pluralTerm || singularTerm
}

const ArrayUtils = {
  isEqual: (array1, array2) => {
    //works for primitives only.. can not compare arrays of objects
    if (
      !(array1 instanceof Array) ||
      !(array2 instanceof Array) ||
      array1.length !== array2.length
    ) {
      return false
    }
    const arr1 = [...array1].sort()
    const arr2 = [...array2].sort()
    return arr1.every((value, index) => value === arr2[index])
  },
  keyToValueObj: (array = [], objIdSelector, returnOnlyKeys = false) => {
    const returnObj = {}
    array.forEach((entry) => {
      const id = delve(entry, objIdSelector, '')
      if (id) {
        returnObj[id] = entry
      }
    })
    if (returnOnlyKeys) {
      return Object.keys(returnObj)
    }
    return returnObj
  },
  mapByKey: (arr, keyName) => new Map(arr.map((i) => [i[keyName], i])),
  fromMap: (map, keyName) =>
    new Array(...map).map(([key, data]) => (keyName ? { ...data, [keyName]: key } : { ...data })),
  getItemIndexByKey: (haystack = [], needle, key = 'id') => {
    let needleIndex = -1
    if (!needle) {
      return needleIndex
    }
    haystack.some((item, index) => {
      if (needle === item[key]) {
        needleIndex = index
        return true
      }
      return false
    })
    return needleIndex
  },
  fromSet: (set) => {
    const returnArray = []
    set.forEach((item) => returnArray.push(item))
    return returnArray
  }
}

const queryParamsToObject = (search) => {
  let allUrlQueryParams = {}
  for (let [key, value] of new URLSearchParams(search).entries()) {
    allUrlQueryParams[key] = value
  }
  return allUrlQueryParams
}

const logger = ['log', 'warn', 'error'].reduce((acc, curr, i) => {
  acc[curr] = process.env.NODE_ENV === 'development' ? console[curr] : () => {}
  return acc
}, {})

const getOrgDomain = () => {
  const stateContext = useContext(AppStateContext)
  return getOrgDomainFromAppStateContext(stateContext)
}

const getOrgDomainFromAppStateContext = (stateContext) => {
  const orgSummary = getDataFromAppStateContext(stateContext, 'getOrgSummary', {})
  return orgSummary?.organisation?.domain
}

const getUrlPrefix = (defaultPrefix) => (isRenderedUnderPrefix ? ENV.PREFIX : defaultPrefix)

const getHostFromUrl = (url) => {
  let host = null
  try {
    host = new URL(url).host
  } catch (e) {
    /* tslint:disable */
    logger.error(`Invalid URL: ${url} `)
    /* tslint:disable */
  }
  return host
}

const isUserGroupsEnabled = (orgSummary = {}) => !!orgSummary.usergroupEnabled

const hasAccountWithRoles = (orgSummary = {}) => !!orgSummary.hasAnyAccountWithRoles

const getPageTitleTranslationPath = (route, orgSummary) => {
  if (route === 'user_management') {
    return isUserGroupsEnabled(orgSummary) ? `${route}.page_title` : `${route}.users.title`
  }
  return `${route}.page_title`
}

const isUndefined = (value) => {
  return value === undefined
}

const isValueUndefinedOrNull = (value) => {
  return value === undefined || value === null
}

const getFeedbackBotEnv = () => (RELEASE_SCOPE === BUILD.STABLE ? 'prod' : 'test')

const getPluralizedKey = (count, singularKey, pluralKey) => (count > 1 ? pluralKey : singularKey)

const changeOmnibarLanguage = (locale) => {
  emitOmnibarEvent('setOmnibarLocale', {
    detail: {
      selectedLocale: locale
    }
  })
}

const isUserInactiveInAccount = (account = {}) =>
  account.userAccountStatus === CONST.ACCOUNT_STATUS.INACTIVE

const getPasswordFieldConfig = (
  name = 'password',
  translationKey = 'common_login.title.password',
  autoComplete = 'off'
) => ({
  name: name,
  type: 'password',
  min: CONST.FORM_FIELD_CONSTANTS.PASSWORD_LENGTH.MIN,
  max: CONST.FORM_FIELD_CONSTANTS.PASSWORD_LENGTH.MAX,
  translationPath: translationKey,
  required: true,
  autoComplete: autoComplete
})

const getProfileRouteAndEditParams = (isEditable = true) => {
  const {
    EDIT_PROFILE: { QUERY_PARAM_KEY, QUERY_PARAM_VALUE }
  } = CONST
  return {
    pathname: '/profile',
    search: isEditable ? `?${QUERY_PARAM_KEY}=${QUERY_PARAM_VALUE}` : ''
  }
}

const getAllQueryParamsExcept = (params = [], searchParams) => {
  const search = new URLSearchParams(searchParams || window.location.search)
  params.forEach((param) => {
    search.delete(param)
  })
  return search.toString()
}

const setQueryParam = (name, value) => {
  //This will replace the param value if it is already present
  const search = new URLSearchParams(window.location.search)
  search.set(name, value)
  return search.toString()
}

const openSignUpOmnibarInConsumingComponent = (productName) => {
  emitOmnibarEvent('showProductForSignup', {
    detail: {
      productName
    }
  })
}

const logOmnibarLoadFailAnalytics = (eventName) => {
  const ERROR_KEY = 'OMNIBAR_LOAD_FAILED'
  const ERROR_DESC = `timeout of 10s reached during ${eventName}`
  const TIMEOUT = 10 * CONST.DURATION.SECOND // 10000ms
  const INTERVAL_FREQUENCY = 500 // 500ms

  let interval = null
  let intervalCounter = 0
  interval = setInterval(() => {
    window.customElements.whenDefined('freshworks-omnibar').then(() => {
      let eventTarget = document.querySelector('[data-omnibar-event-target]')
      if (!!eventTarget) {
        clearInterval(interval)
      }
    })
    // if setInterval has polled repeatedly for 10s in 20 cycles,
    // exit and log in Heap & Sentry
    if (++intervalCounter * INTERVAL_FREQUENCY === TIMEOUT) {
      clearInterval(interval)
      heapTrack(ERROR_KEY, {
        error: ERROR_DESC
      })
      window.Sentry && window.Sentry.captureException(new Error(`${ERROR_KEY}: ${ERROR_DESC}`))
    }
  }, INTERVAL_FREQUENCY)
}

const emitOmnibarEvent = (eventName, eventProperties = {}) => {
  window.customElements &&
    window.customElements.whenDefined &&
    window.customElements.whenDefined('freshworks-omnibar').then(() => {
      let eventTarget = document.querySelector('[data-omnibar-event-target]')
      if (!!eventTarget) {
        let openOmnibarEvent = new CustomEvent(eventName, eventProperties)
        eventTarget.dispatchEvent(openOmnibarEvent)
      }
    })
  // start short poll to log analytics only when Omnibar fails to load
  // this is a temporary change to understand the no. of occurrences of
  // failures and get insights in terms of geolocation, os, browser, etc.
  // ** PLEASE NOTE IT DOES NOT FIX THE ISSUE, RATHER COLLECTS DATA ON FAILURE **
  // TODO: uncomment when omnibar emit event is stopped from prelogin screens
  //logOmnibarLoadFailAnalytics(eventName)
}

// utility to check and return if user has staged email
// otherwise primary email is returned
// `staged email`: interim state when user's email is updated by
// org admin but updated email is not activated by the user yet
const getUserEmail = (user) => user.stagedEmail || user.email

const heapAddUserProperties = (userProperties) => {
  if (
    window.heap &&
    window.heap.addUserProperties &&
    typeof window.heap.addUserProperties === 'function'
  ) {
    window.heap.addUserProperties(userProperties)
  }
}

const closeRts = () => {
  // When logout is clicked from header, BE redirects the user to the third party login site post logout,
  // as this redirection happens RTS logout is also kicked in
  // so another redirection is again being initiated from FrontEnd
  // which stops 🛑the loading of third party site's login url.

  // This is a very edge case, in this case the 3rd party site take almost 2.5s to respond
  // hence this issue occurs the same doesn't occur for facebook.com.

  // window.freshworksOmnibar.closeRts is defined by Omnibar
  const closeRts = delve(window, 'freshworksOmnibar.closeRts', () => {})
  closeRts()
}

const isPortalAccount = ({ showInOmnibar = true }) => !showInOmnibar

const checkIfPortalAccountUser = (user = {}, productList = []) =>
  productList.some(({ accounts = [] }) => accounts.some(isPortalAccount)) && !user.admin

const isActivatedUser = (user) => user.status === CONST.USER_STATUS.ACTIVATED

const debounce = (ctx, func, delay) => {
  let timer = null
  return function () {
    const args = arguments
    timer && clearTimeout(timer)
    timer = setTimeout(() => func.apply(ctx, args), delay)
  }
}

// TODO : Before enabling the billing audits logs this task must be completed - FRESHID-83501
// FRESHID-83501 - Hide Billing Audit Logs for CRM Sandbox accounts
const featureGate = {
  isBillingAuditLogsEnabled() {
    return ENABLE_UBX_AUDIT_LOGS === 'true'
  }
}

/**
 * @Author Sumeet
 * @Desc Arranges cloudTypes by there bunldeTypeId
 * @returns {bundleTypeId1: [cloudTypoes], bundleTypeId2:[cloudTypes]}
 */
const getCloudTypeListByBundleType = (cloudTypes = []) => {
  const bundleTypeCloudTypesMap = {}
  cloudTypes.forEach((cloudType) => {
    if (!bundleTypeCloudTypesMap[cloudType.bundleTypeId]) {
      bundleTypeCloudTypesMap[cloudType.bundleTypeId] = []
    }
    bundleTypeCloudTypesMap[cloudType.bundleTypeId].push(cloudType)
  })
  return bundleTypeCloudTypesMap
}

const isCloudTypeSupportedBundleType = (bundleType) =>
  typeof bundleType.name === 'string' &&
  CONST.CLOUD_TYPE_SUPPORTED_BUNDLES.has(bundleType.name.toUpperCase())

const getDisplayRankForOffering = (offering) => {
  return delve(
    CONST.OFFERING_DISPLAY_RANK,
    delve(offering, 'name', '').toUpperCase(),
    CONST.DEFAULT_DISPLAY_RANK
  )
}

const getObjectDifference = (initialObj, submittingObject) => {
  let diffs = {}
  Object.keys(submittingObject).forEach((key) => {
    if (initialObj[key] !== submittingObject[key]) {
      diffs[key] = submittingObject[key]
    }
  })
  return diffs
}

// allow ChangePassword only if
// 1. the selected user is an ORG Admin (or)
// 2. with ORG password module is enabled
const isChangePasswordAllowed = (isAdmin, isPasswordModuleEnabled) =>
  isAdmin || isPasswordModuleEnabled

function getDomainIfUrlIsAbsolute(url) {
  try {
    return new URL(url).hostname
  } catch (e) {
    return url
  }
}

/**
 * Pass a account domain and get it truncated in the middle
 * Eg: yashwanth123456789.freshmarketter.com (37 chars)
 * OP: yashwanth12...freshmarketter.com (32 chars)
 * @param {string} accountDomain Input Account domain eg: yashwanth.freshsuccess.com
 * @param {number} maxCharToTruncate Maximum characters the truncated account domain can have
 */
function truncateAccountDomain(accountDomain = '', maxCharToTruncate = 32) {
  if (accountDomain.length <= maxCharToTruncate) {
    return accountDomain
  }

  const firstDotIndex = accountDomain.indexOf('.'),
    productDomain = accountDomain.slice(firstDotIndex + 1, accountDomain.length),
    actualAccountDomain = accountDomain.slice(0, firstDotIndex),
    threeDots = '...',
    truncatedAccountDomain = actualAccountDomain.substring(
      0,
      maxCharToTruncate - (productDomain.length + threeDots.length)
    )

  return `${truncatedAccountDomain}${threeDots}${productDomain}`
}

const featureFlagForCustomDomain = {
  isCustomDomainEnabled() {
    return ENABLE_CUSTOM_DOMAIN === 'true'
  }
}

const featureFlagForSessionManagement = {
  isSessionManagementFeatureEnabled() {
    return ENABLE_SESSION_MANAGEMENT_FEATURE === 'true'
  }
}

const getInfoToDisplayAccount = (
  getProductById,
  account = {},
  bundlesDefinition = {},
  cloudTypesMapById
) => {
  const { bundles = [], bundleTypes = [] } = bundlesDefinition
  const product = getProductById(account.productId)
  const canRenderAccounts = product && product.displayName && shouldRenderAccount(account)
  if (!canRenderAccounts) {
    return { canRenderAccounts }
  }

  const isMainBundleAccount = account.anchor
  let productName = product.displayName
  let accountName = getNormalizedAccountName({
    ...account,
    productName: product.displayName
  })
  let src = product.name.toLowerCase()
  let displayRank = getDisplayRankForOffering(product)

  if (isMainBundleAccount) {
    const {
      name,
      src: bundleDewSrc,
      displayRank: accountDisplayRank
    } = getBundleAttributesForAccount(account, bundles, bundleTypes, cloudTypesMapById)
    productName = name
    src = bundleDewSrc
    accountName = name
    displayRank = accountDisplayRank
  }

  return {
    src,
    productName,
    accountName,
    canRenderAccounts,
    displayRank
  }
}

const getBundleAttributesForAccount = (
  account,
  bundles = [],
  bundleTypes = [],
  cloudTypesMapById = new Map()
) => {
  let accountAttributes = { name: '', src: 'freshworks', displayRank: CONST.DEFAULT_DISPLAY_RANK }

  bundles.some((bundle) => {
    if (account.bundleIdentifier === bundle.id) {
      accountAttributes.name = bundle.name
      bundleTypes.some((bundleType) => {
        if (bundle.bundleTypeIdentifier === bundleType.id) {
          if (
            isCloudTypeSupportedBundleType(bundleType) &&
            bundle.cloudTypeIdentifier &&
            cloudTypesMapById.has(bundle.cloudTypeIdentifier)
            // for backward compatibility
            // if cloud_type_identifier is not present,
            // take properties from bundle_type entity
          ) {
            const bundleCloudType = cloudTypesMapById.get(bundle.cloudTypeIdentifier)
            accountAttributes.src = bundleCloudType.name.toLowerCase()
            accountAttributes.displayRank = getDisplayRankForOffering(bundleCloudType)
            accountAttributes.name = bundleCloudType.displayName
          } else {
            accountAttributes.src = bundleType.name.toLowerCase()
            accountAttributes.displayRank = getDisplayRankForOffering(bundleType)
          }
          return true
        }
      })
      return true
    }
  })

  return accountAttributes
}

export {
  getTranslationFileLocation,
  flattenMessages,
  ENV,
  getInfoToDisplayAccount,
  getBundleAttributesForAccount,
  getNormalizedAccountName,
  getNormalizedAccountDomain,
  isBrowserIE,
  getNonEmptyValues,
  scrollTop,
  getQueryParams,
  getBooleanQueryParam,
  processErrors,
  templeFunctionArgsParser,
  isBrowserIE11,
  getSubdomain,
  getUrlSegments,
  getOrgDomainFromUrlSegments,
  isBrowserEdge,
  getErrorList,
  getRedirectUriFromError,
  handle400ErrorForHashes,
  getServerErrors,
  sanitizePEM,
  getTitleRoute,
  getUserName,
  getUserNameWithEmailFallback,
  heapTrack,
  isMobile,
  isEquivalent,
  getPasswordValidator,
  getObjectsFromArrayForKeyValue,
  callOauthEndpoint,
  isUrlAbsolute,
  StringUtils,
  ArrayUtils,
  logger,
  DateUtils,
  getOrgDomain,
  getHostFromUrl,
  hasAccountWithRoles,
  isUserGroupsEnabled,
  getPageTitleTranslationPath,
  getServerErrorForPassword,
  isUndefined,
  isValueUndefinedOrNull,
  getPluralizedKey,
  getUserRoleTextAndLevel,
  changeOmnibarLanguage,
  isUserInactiveInAccount,
  getPasswordFieldConfig,
  getProfileRouteAndEditParams,
  getAllQueryParamsExcept,
  setQueryParam,
  NAME_FIELD_CONFIG,
  openSignUpOmnibarInConsumingComponent,
  getUserEmail,
  heapAddUserProperties,
  closeRts,
  checkIfPortalAccountUser,
  isPortalAccount,
  getFeedbackBotEnv,
  isActivatedUser,
  debounce,
  getCloudTypeListByBundleType,
  isCloudTypeSupportedBundleType,
  getDisplayRankForOffering,
  getObjectDifference,
  isChangePasswordAllowed,
  getDomainIfUrlIsAbsolute,
  truncateAccountDomain,
  featureGate,
  getOrgDomainFromAppStateContext,
  getUrlPrefix,
  OAUTH_URL_PATH,
  featureFlagForCustomDomain,
  queryParamsToObject,
  featureFlagForSessionManagement
}
