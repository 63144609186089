import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

import { useIntl } from '../IntlContext'
import CONST from '../../utils/constants'
import { getTitleRoute, getPageTitleTranslationPath } from '../../utils'
import { AppStateContext } from '../AppDataProvider'
import { getDataFromAppStateContext } from '../AppDataProvider/AppStateContext'

const WindowTitle = () => {
  const { pathname } = useLocation()
  const route = getTitleRoute(pathname)
  const { toIntlString } = useIntl()
  const appState = useContext(AppStateContext)
  const getOrgSummary = getDataFromAppStateContext(appState, 'getOrgSummary', {})
  const { organisation = '' } = getOrgSummary
  return (
    <Helmet>
      <title>
        {CONST.FRESHWORKS}
        {organisation.name ? ` - ${organisation.name}` : ''}
        {route ? ` - ${toIntlString(getPageTitleTranslationPath(route, getOrgSummary))}` : ''}
      </title>
    </Helmet>
  )
}

export default WindowTitle
