import CONST from '../../../utils/constants'
import { SECURITY_AGENTS_STR } from '../utils'
import SECURITY_FIELDS_SERVER_ERRORS from './SecurityServerErrors'
const { SSO_SUPPORTED_INPUT_TYPES, UNSPECIFIED_IDP_TYPE } = CONST

const OIDC_BASIC_FIELDS = [
  {
    name: 'redirect_uri',
    path: 'config',
    disabled: true,
    type: SSO_SUPPORTED_INPUT_TYPES.TEXT,
    canSendToServerOnCreateModule: false,
    canShowInUI: true,
    defaultValue: '',
    isCopyOnly: true //shown to the customer for copying
  },
  {
    name: 'client_id',
    path: 'config',
    disabled: false,
    type: SSO_SUPPORTED_INPUT_TYPES.TEXT,
    canSendToServerOnCreateModule: true,
    canShowInUI: true,
    defaultValue: '',
    required: true,
    isCopyOnly: false,
    supportedErrors: [SECURITY_FIELDS_SERVER_ERRORS.EMPTY_CLIENT_ID]
  },
  {
    name: 'client_secret',
    path: 'config',
    disabled: false,
    type: SSO_SUPPORTED_INPUT_TYPES.PASSWORD,
    canSendToServerOnCreateModule: true,
    canShowInUI: true,
    defaultValue: '',
    required: true,
    isCopyOnly: false
  },
  {
    name: 'scopes',
    path: 'config',
    disabled: false,
    type: SSO_SUPPORTED_INPUT_TYPES.TEXT,
    canSendToServerOnCreateModule: false,
    canShowInUI: true,
    defaultValue: '',
    required: false,
    isCopyOnly: false,
    supportedErrors: [
      SECURITY_FIELDS_SERVER_ERRORS.EMPTY_AUTHENTICATION_SCOPES,
      SECURITY_FIELDS_SERVER_ERRORS.INVALID_SCOPES_OIDC
    ]
  },
  {
    name: 'logout_url',
    path: 'config',
    disabled: false,
    type: SSO_SUPPORTED_INPUT_TYPES.TEXT,
    canSendToServerOnCreateModule: true,
    canShowInUI: true,
    defaultValue: '',
    required: false,
    isCopyOnly: false
  },
  {
    name: 'authorization_uri',
    path: 'config',
    disabled: false,
    type: SSO_SUPPORTED_INPUT_TYPES.TEXT,
    canSendToServerOnCreateModule: true,
    canShowInUI: true,
    defaultValue: '',
    required: true,
    isCopyOnly: false,
    supportedErrors: [
      SECURITY_FIELDS_SERVER_ERRORS.EMPTY_AUTHORIZAION_URI,
      SECURITY_FIELDS_SERVER_ERRORS.INVALID_AUTHORIZAION_URI
    ]
  },
  {
    name: 'token_uri',
    path: 'config',
    disabled: false,
    type: SSO_SUPPORTED_INPUT_TYPES.TEXT,
    canSendToServerOnCreateModule: true,
    canShowInUI: true,
    defaultValue: '',
    required: true,
    isCopyOnly: false,
    supportedErrors: [
      SECURITY_FIELDS_SERVER_ERRORS.INVALID_OAUTH_TOKEN_URI,
      SECURITY_FIELDS_SERVER_ERRORS.EMPTY_OAUTH_TOKEN_URI
    ]
  },
  {
    name: 'claims',
    path: 'config',
    disabled: true,
    type: SSO_SUPPORTED_INPUT_TYPES.CUSTOM,
    canSendToServerOnCreateModule: false,
    canShowInUI: false,
    defaultValue: {},
    required: true,
    isCopyOnly: false
  },
  {
    name: 'authentication_method',
    path: 'config',
    disabled: true,
    type: SSO_SUPPORTED_INPUT_TYPES.TEXT,
    canSendToServerOnCreateModule: false,
    canShowInUI: false,
    defaultValue: '',
    required: true,
    isCopyOnly: false
  },
  {
    name: 'additional_params',
    path: 'config',
    disabled: true,
    type: SSO_SUPPORTED_INPUT_TYPES.CUSTOM,
    canSendToServerOnCreateModule: false,
    canShowInUI: false,
    defaultValue: null,
    required: false,
    isCopyOnly: false
  },
  {
    name: 'pkce_enabled',
    path: 'config',
    type: SSO_SUPPORTED_INPUT_TYPES.SWITCH,
    canSendToServerOnCreateModule: true,
    canShowInUI: true,
    defaultValue: false
  }
]

const OIDC_ADVANCED_FIELDS = [
  {
    name: 'data_sync_enabled',
    path: '',
    type: SSO_SUPPORTED_INPUT_TYPES.SWITCH,
    canSendToServerOnCreateModule: false,
    canShowInUI: true,
    defaultValue: true,
    displayValidator: (ssoModule, userType) => userType === SECURITY_AGENTS_STR //currently displayValidator works for advanced fields only
  },
  {
    name: 'display_name',
    path: 'config',
    disabled: false,
    type: SSO_SUPPORTED_INPUT_TYPES.TEXT,
    defaultValue: '',
    canShowInUI: true,
    required: false
  },
  {
    name: 'sso_title',
    path: 'config',
    disabled: false,
    type: SSO_SUPPORTED_INPUT_TYPES.TEXT,
    defaultValue: '',
    canShowInUI: false,
    required: false
  },
  {
    name: 'idp_type',
    path: 'config',
    disabled: false,
    type: SSO_SUPPORTED_INPUT_TYPES.TEXT,
    defaultValue: UNSPECIFIED_IDP_TYPE,
    canShowInUI: false,
    required: false
  }
]

export default {
  BASIC: OIDC_BASIC_FIELDS,
  ADVANCED: OIDC_ADVANCED_FIELDS
}
