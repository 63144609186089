import { useEffect, useState } from 'react'
import { CONST } from '.'

const getWindowDimensions = () => {
  const { innerWidth: width } = window
  return {
    width
  }
}

export const isDeviceMobile = ({ width }) => {
  return width < CONST.SCREEN_SIZES.md
}

const isDeviceDesktop = ({ width }) => {
  return width >= CONST.SCREEN_SIZES.lg
}

export const useDeviceType = () => {
  const [isMobile, setIsMobile] = useState(isDeviceMobile(getWindowDimensions()))
  const [isDesktop, setIsDesktop] = useState(isDeviceDesktop(getWindowDimensions()))

  useEffect(() => {
    function handleResize() {
      setIsDesktop(isDeviceDesktop(getWindowDimensions()))
      setIsMobile(isDeviceMobile(getWindowDimensions()))
    }

    window.addEventListener('resize', handleResize)
    window.addEventListener('load', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('load', handleResize)
    }
  }, [])

  return { isMobile, isDesktop }
}
