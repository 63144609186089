import { createContext } from 'react'
import cleanSet from 'clean-set'
import delve from 'dlv'
import { isUndefined } from '../../utils'

const AppStateContext = createContext({})

const AppStateUpdateContext = createContext()

export default AppStateContext

/**
 * @Author Sumeet Sood
 * @param {Object} dispatch - setState function of AppDataProvider. THis is grabbed by components through context API
 * @param {string} pattern - Name of activity
 * @param {any} data - API response for provided activity
 *
 * @Desc called from DataMnager to cache the response of a activity
 */
const writeToAppStateContext = (dispatch, pattern, data) => {
  dispatch((state) => cleanSet(state, pattern, data))
}

const getDataFromAppStateContext = (appState, patterns, defaultValues) => {
  if (typeof patterns === 'string') {
    return delve(appState, patterns, defaultValues)
  } else if (Array.isArray(patterns)) {
    defaultValues = isUndefined(defaultValues) || !Array.isArray(defaultValues) ? [] : defaultValues
    return patterns.map((pattern, index) =>
      getDataFromAppStateContext(appState, pattern, delve(defaultValues, `${index}`))
    )
  }
}

export { AppStateUpdateContext, writeToAppStateContext, getDataFromAppStateContext }
