import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'
import useToast, { TOAST_LEVELS, ERROR_TOAST_TIMEOUT } from '../../components/Toast'
import UserSession from '../../components/UserSession'
import { CONST } from '../../utils'
import { Box, Flex } from '../Grid'
import Image from '../Image'
import { useIntl } from '../IntlContext'
import { OneColumnContentBox } from '../Layout'
import { H1, PrimaryText } from '../Typography'
import APP_CONSTANTS from '../../utils/constants'

const TEXT_ALIGN_CENTER = { textAlign: 'center' }

export const NotFoundContent = React.memo(() => {
  const { toIntlString } = useIntl()
  const homeUrl = `${APP_CONSTANTS.DEFAULT_REDIRECT_PATH}${APP_CONSTANTS.LOGIN_PATHNAME}`
  return (
    <OneColumnContentBox>
      <OneColumnContentBox.OneColumnContentSection disableFormFocus>
        <Flex flexDirection="column" css={TEXT_ALIGN_CENTER}>
          <Box mb={2}>
            <H1>404!</H1>
          </Box>
          <Box mb={3}>
            <Image width={3 / 4} m="auto" src="404.png" alt={toIntlString('error.error')} />
          </Box>
          <Box mt={3} mb={4}>
            <PrimaryText>
              <FormattedMessage
                id="error.page_not_found"
                defaultMessage={toIntlString('error.page_not_found')}
                values={{
                  home: <a href={homeUrl}>{toIntlString('error.home')}</a>
                }}
              />
            </PrimaryText>
          </Box>
        </Flex>
      </OneColumnContentBox.OneColumnContentSection>
    </OneColumnContentBox>
  )
})

const PageNotFound = React.memo(() => {
  const history = useHistory()
  const toast = useToast()
  const { toIntlString } = useIntl()
  return (
    <Fragment>
      <UserSession.IfUserSessionExists>
        {() => {
          history.push(CONST.DEFAULT_REDIRECT_PAGE)
          toast(toIntlString(`page_not_found.toast_message`), {
            level: TOAST_LEVELS.ERROR,
            timeout: ERROR_TOAST_TIMEOUT
          })
          return null
        }}
      </UserSession.IfUserSessionExists>
      <UserSession.IfUserSessionAbsent>
        <NotFoundContent />
      </UserSession.IfUserSessionAbsent>
    </Fragment>
  )
})

export default PageNotFound
