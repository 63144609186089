import moment from 'moment'
import delve from 'dlv'
import { TOAST_LEVELS } from '../../../components/Toast'
import { getServerErrors } from '../../../utils'
import * as Yup from 'yup'

const CUSTOM_ROLES_BASE_PATH = '/users-groups/customroles'
const TRANSLATION_KEY = 'user_management.admin_center_roles'
const CUSTOM_ROLES_TRANSLATION_KEY = `${TRANSLATION_KEY}.custom_roles`
const CUSTOM_ROLES_FORM_TRANSLATION_KEY = `${CUSTOM_ROLES_TRANSLATION_KEY}.form_fields`
const USER_PATH = 'user'
const CUSTOM_ROLES_TYPE = 'CUSTOM'
const PERMISSIONS = {
  EDIT: 'edit',
  VIEW: 'view'
}

const getDisplayNameValidationSchema = (toIntlString) =>
  Yup.object().shape({
    displayName: Yup.string()
      .matches(/[a-z0-9]+$/i, toIntlString(`${TRANSLATION_KEY}.unsupported_characters`))
      .trim()
      .required(toIntlString('validation.not_blank'))
      .min(1)
      .max(32, toIntlString('validation.too_long'))
  })

const ICONS = {
  user_management: 'users-groups-dark',
  user_profile: 'users-groups-dark',
  audit_logs: 'audit-logs-dark',
  org_account: 'organization',
  user_security: 'security-dark',
  org_security: 'security-dark'
}

const PRIVILEGES_LIST = [
  {
    name: 'user_management',
    privilegeActionMappings: ['user_management_read', 'user_management_write']
  },
  {
    name: 'user_security',
    privilegeActionMappings: ['user_security_read', 'user_security_write']
  },
  {
    name: 'org_security',
    privilegeActionMappings: ['org_security_read', 'org_security_write']
  },
  {
    name: 'audit_logs',
    privilegeActionMappings: ['audit_logs_read']
  }
]

const USER_PRIVILEGES_LIST = [
  {
    name: 'user_profile',
    privilegeActionMappings: ['user_profile_read', 'user_profile_write']
  }
]

const privilegeActionMappingsList = PRIVILEGES_LIST.reduce(
  (a, c) => [...a, ...c.privilegeActionMappings],
  []
)

const DEFAULT_PERMISSIONS_LIST = {
  organization_admin: privilegeActionMappingsList,
  organization_user: []
}

const getPaths = {
  defaultLanding: () => `${CUSTOM_ROLES_BASE_PATH}`,
  customRoleView: (roleId) => `${CUSTOM_ROLES_BASE_PATH}/${roleId}`,
  orgUserView: () => `${CUSTOM_ROLES_BASE_PATH}/${USER_PATH}`
}

const getFormattedDate = (date) => {
  return moment(date).locale('en-us').format('MMM Do YYYY, h:mm A')
}

const handleError = (error, toast, toIntlString) => {
  let serverErrors = getServerErrors(error, TRANSLATION_KEY)
  toast(toIntlString(serverErrors[0]), {
    level: TOAST_LEVELS.ERROR
  })
}

const getRoleDisplayName = (isCustomRole, roleName, toIntlString) =>
  isCustomRole ? roleName : `${toIntlString(`${TRANSLATION_KEY}.${roleName}`)}`

const isOrgUserRole = (roleName) => roleName === 'user'

const getUserMappingRequest = (data) => {
  const userMappings = delve(data, 'users', [])
  return {
    userIds: userMappings.map((user) => user.id),
    unAssignAll: false
  }
}

const isAnyFieldTruthy = (data) => {
  for (let privilege of privilegeActionMappingsList) {
    if (delve(data, privilege, false)) return true
  }
  return false
}

const constructCreateRoleRequest = (data, oldRoleName, privilegesEditData = {}) => {
  let roleIds = []
  for (let privilege of privilegeActionMappingsList) {
    if (delve(data, privilege, false)) {
      roleIds.push(privilege)
    }
  }
  const { addPrivileges, removePrivileges } = processPrivileges(data, privilegesEditData)

  let request = {
    ...(data.displayName !== oldRoleName && { displayName: data.displayName }),
    type: CUSTOM_ROLES_TYPE
  }

  if (!!oldRoleName && !!privilegesEditData) {
    return {
      role: {
        ...request,
        addPrivileges: addPrivileges,
        removePrivileges: removePrivileges
      }
    }
  }
  return {
    role: {
      ...request,
      privileges: roleIds
    }
  }
}

const processPrivileges = (data, privilegesEditData = []) => {
  let privileges = privilegesEditData.privileges
  let addPrivileges = []
  let removePrivileges = []

  for (let key in data) {
    if (
      !!privileges &&
      Object.prototype.hasOwnProperty.call(data, key) &&
      typeof data[key] === 'boolean'
    ) {
      if (data[key] === true && !privileges.includes(key)) {
        addPrivileges.push(key)
      } else {
        if (data[key] === false) {
          removePrivileges.push(key)
        }
      }
    }
  }

  return {
    addPrivileges,
    removePrivileges
  }
}

const constructDeleteRoleMappingRequest = ({ isUnassignAllUsersConfirmed, unAssignedUsers }) => {
  let unAssignedUserIds = []
  for (let id in unAssignedUsers) {
    if (unAssignedUsers[id]) unAssignedUserIds.push(id)
  }
  return {
    userIds: unAssignedUserIds,
    unAssignAll: !!isUnassignAllUsersConfirmed
  }
}

export {
  CUSTOM_ROLES_BASE_PATH,
  TRANSLATION_KEY,
  CUSTOM_ROLES_TRANSLATION_KEY,
  CUSTOM_ROLES_FORM_TRANSLATION_KEY,
  getDisplayNameValidationSchema,
  ICONS,
  PRIVILEGES_LIST,
  USER_PRIVILEGES_LIST,
  DEFAULT_PERMISSIONS_LIST,
  privilegeActionMappingsList,
  getPaths,
  PERMISSIONS,
  getFormattedDate,
  handleError,
  constructDeleteRoleMappingRequest,
  getRoleDisplayName,
  isOrgUserRole,
  isAnyFieldTruthy,
  constructCreateRoleRequest,
  getUserMappingRequest
}
