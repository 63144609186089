import React, { Component } from 'react'
import { Box } from './../Grid'
import { styledWithRTL } from '../../utils/styles'
import IntlContext from '../IntlContext'

const ConnectivityCheckerOverlay = styledWithRTL(Box, {
  label: 'ConnectivityCheckerOverlay'
})`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 101;
  background-color: ${({ theme: { colors } }) => colors.white};
  opacity: .65;
  overflow-y: hidden;
`

const ConnectivityMessageContainer = styledWithRTL(Box, {
  label: 'ConnectivityMessageContainer'
})`
  background-color: ${({ theme: { colors } }) => colors.black};;
  padding: 14px 24px;
  bottom: 24px;
  left: 24px;
  font-size: 14px;
  position: fixed;
  color: ${({ theme: { colors } }) => colors.white};
  min-width: 250px;
`

class ConnectivityChecker extends Component {
  state = {
    isOffline: false
  }

  componentDidMount() {
    window.addEventListener('online', this.updateOfflineStatus)
    window.addEventListener('offline', this.updateOfflineStatus)
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.updateOfflineStatus)
    window.removeEventListener('offline', this.updateOfflineStatus)
  }

  updateOfflineStatus = (event) => {
    this.setState({
      isOffline: event.type === 'offline'
    })
  }

  render() {
    const { isOffline } = this.state
    if (isOffline)
      return (
        <IntlContext.Consumer>
          {({ toIntlString }) => (
            <ConnectivityCheckerOverlay>
              <ConnectivityMessageContainer>
                {toIntlString('error.connectivity_lost')}
              </ConnectivityMessageContainer>
            </ConnectivityCheckerOverlay>
          )}
        </IntlContext.Consumer>
      )
    else return null
  }
}

export default ConnectivityChecker
