import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '../../components/IntlContext'
import { Anchor, PrimaryText, SecondaryText } from '../../components/Typography'

export const LINK_FONT_SIZE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary'
}

const LinkBetweenText = ({
  translationKey,
  placeHolderReplacementObject = {},
  linkFontSize = LINK_FONT_SIZE.SECONDARY,
  textStyle,
  ...rest
}) => {
  const { toIntlString } = useIntl()

  const placeHoldersArray = Object.keys(placeHolderReplacementObject)
  let placeHolderKeysObject = {}
  placeHoldersArray.forEach((placeHolder) => {
    placeHolderKeysObject[placeHolder] = placeHolder
  })

  let message = toIntlString(translationKey, placeHolderKeysObject)
  let messageParts = []
  let tempParts
  placeHoldersArray.forEach((placeHolder, index) => {
    tempParts = message.split(placeHolder)
    messageParts.push(tempParts[0])
    if (index + 1 === placeHoldersArray.length) {
      messageParts.push(tempParts[1])
    } else {
      message = tempParts[1]
    }
  })

  return (
    <>
      {messageParts[0]}
      {placeHoldersArray.map((placeHolder, index) => {
        const { textKey, link, target, onClick } = placeHolderReplacementObject[placeHolder]
        let linkAttr = { target, onClick: onClick || (() => {}) }
        if (link) linkAttr.href = link
        return (
          <>
            <Anchor {...linkAttr} {...rest}>
              {linkFontSize === LINK_FONT_SIZE.SECONDARY ? (
                <SecondaryText textType="link" textWeight="bold">
                  {toIntlString(textKey)}
                </SecondaryText>
              ) : (
                  <PrimaryText textType="link" textWeight="bold" inline className={textStyle}>
                  {toIntlString(textKey)}
                </PrimaryText>
              )}
            </Anchor>
            {messageParts[index + 1]}
          </>
        )
      })}
    </>
  )
}

LinkBetweenText.propTypes = {
  translationKey: PropTypes.string,
  linkFontSize: PropTypes.string,
  /*
   NOTE: make sure the number of keys in the placeHolderReplacementObject is equal to redirectTo array length
  */
  placeHolderReplacementObject: PropTypes.object,
  redirectTo: PropTypes.arrayOf(PropTypes.string)
}

export default LinkBetweenText
