import React from 'react'
import PropTypes from 'prop-types'
import { withDownshift, DROPDOWN_TYPES } from './Dropdown'
import styled, { css, cx } from 'react-emotion'
import Icon from '../Icon'
import Flex from '../Layout/AppContainer'
import matchSorter from 'match-sorter'
import { cssWithRTL, styledWithRTL } from '../../utils/styles'
import { useIntl } from '../IntlContext'
import { logger } from '../../utils'

const StyledMenuContainer = styledWithRTL('ul')`
  position: relative;
  top: 5px;

  min-width: 160px;
  max-height: 200px;
  overflow: auto;

  margin: 0 0 10px 0;
  padding: 4px 0 0 0;
  list-style-type: none;

  box-shadow: 0 0 0 1px rgba(24, 50, 71, 0.1),
    0 1px 4px 0 rgba(24, 50, 71, 0.15),
    0 6px 14px 0 rgba(24, 50, 71, 0.05);
  border-radius: 2px;

  z-index: 1;

  ${({ theme, overrideStylesForContainer }) => {
    const { palette } = theme
    return cx(
      css`
        background-color: ${palette.white};
      `,
      cssWithRTL(overrideStylesForContainer)
    )
  }};
`

const StyledMenuItem = styled('li')`
  ${({ theme, isSelectedIndex, canHighlight, disabled = false }) => {
    const { typography, colors } = theme
    return cx(
      css({
        color: colors.text.primary
      }),
      css(typography.body.secondary),
      canHighlight
        ? css({
            backgroundColor: colors.background.hover
          })
        : css({
            backgroundColor: 'none'
          }),
      !disabled
        ? isSelectedIndex
          ? css({
              fontWeight: 600,
              color: colors.link,
              backgroundColor: colors.background.selected
            })
          : css({
              '&:hover': {
                color: colors.text.primary,
                backgroundColor: colors.background.hover
              }
            })
        : css({
            color: colors.text.secondary,
            ' div': {
              cursor: 'default'
            }
          })
    )
  }};

  margin: 8px;
  border-radius: 2px;
  padding: 10px 8px;
  cursor: pointer;
`

const containerStyle = {
  position: 'absolute',
  height: '0px',
  width: '100%'
}

const conditionalValues = (type) =>
  DROPDOWN_TYPES.BUTTON === type
    ? { role: 'menuitem', tabIndex: '0', 'aria-selected': null }
    : { role: 'option' }

const DropdownMenu = withDownshift(
  ({
    items,
    downshift: { isOpen, getMenuProps, getItemProps, selectedItem, inputValue, highlightedIndex },
    overrideStylesForContainer = {},
    itemComponent,
    comparator,
    type,
    filterKey,
    optionLabel = ''
  }) => {
    let allItems = items
    const { toIntlString } = useIntl()

    if (type === DROPDOWN_TYPES.INPUT) {
      if (selectedItem !== inputValue) {
        if (process.env.NODE_ENV === 'development' && typeof items[0] !== 'string' && !filterKey) {
          logger.error(`The 'items' you provided is of type Object, provide
            'filterKey' prop to <Dropdown/> to specify which key has to be searched for.`)
        }

        if (filterKey && typeof items[0] === 'object') {
          allItems = matchSorter(items, inputValue, { keys: [filterKey] })
        }

        if (typeof items[0] === 'string') {
          allItems = matchSorter(items, inputValue)
        }
      }
    }

    if (!isOpen || allItems.length === 0) {
      return null
    }

    return (
      <div style={containerStyle}>
        <StyledMenuContainer
          {...getMenuProps({
            onClick: (event) => {
              event.stopPropagation()
            }
          })}
          role={DROPDOWN_TYPES.BUTTON === type ? 'menu' : 'listbox'}
          aria-label={toIntlString('my_profile.button.dropdown_list')}
          aria-labelledby={null}
          overrideStylesForContainer={overrideStylesForContainer}>
          <>
            {
              //optionLabel should not be display for search input actions
              optionLabel && selectedItem === inputValue && (
                <StyledMenuItem {...conditionalValues(type)} disabled={true}>
                  <Flex justifyContent="space-between" pr={2}>
                    {itemComponent ? itemComponent(optionLabel) : optionLabel}
                  </Flex>
                </StyledMenuItem>
              )
            }
            {allItems.map((item, index) => {
              const canHighlightSelectedIndex = comparator
                ? comparator(item, selectedItem)
                : allItems.indexOf(selectedItem) === index
              return (
                <StyledMenuItem
                  {...getItemProps({
                    item,
                    key: index
                  })}
                  {...conditionalValues(type)}
                  isSelectedIndex={canHighlightSelectedIndex}
                  canHighlight={highlightedIndex === index}>
                  <Flex justifyContent="space-between" pr={2}>
                    {itemComponent ? itemComponent(item) : item}
                    {canHighlightSelectedIndex ? (
                      <Icon name="blue-tick" shouldRotateOnRTL={false} width={12} />
                    ) : (
                      ''
                    )}
                  </Flex>
                </StyledMenuItem>
              )
            })}
          </>
        </StyledMenuContainer>
      </div>
    )
  }
)

DropdownMenu.displayName = 'Dropdown.DropdownMenu'

DropdownMenu.propTypes = {
  /**
   *   A object containing css styles
   */
  overrideStylesForContainer: PropTypes.object,
  /**
   * Component for dropdown items
   */
  itemComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /**
   *   Used to compare and highlight the selected item
   */
  comparator: PropTypes.func
}

export default DropdownMenu
