import CONST from './constants'
import delve from 'dlv'
import ENV from '../env.config.json'
import { addQueryParams } from './queryParams'

function getGoogleUiRedirectUrl(allowedAuthenticationMethods = []) {
  const googleConfig = allowedAuthenticationMethods.filter(
    (authenticationMethod) =>
      authenticationMethod.type === CONST.AUTHENTICATION_METHODS.GOOGLE &&
      authenticationMethod.enabled
  )

  if (googleConfig.length === 0) {
    return ''
  }

  return delve(googleConfig[0], 'config.uiRedirectUri', '')
}

function hasModules(data = {}) {
  return data.modules && data.modules.length > 0
}

// backend expects OIDC as sso `type` for JWT SSO
// hence before sending we change JWT_SSO as OIDC
function convertJwtToOidc(data) {
  const {
    SSO_LOGIN_METHODS: { JWT_SSO, OIDC }
  } = CONST
  if (data.type === JWT_SSO) {
    data.config.type = OIDC
    data.type = OIDC
  }
}

// one response backend sends sso type as OIDC for JWT_SSO sso
// we identify OIDC as JWT_SSO by `enableSignatureValidation` and `grantType` property
function convertOidcToJwt(data) {
  const {
    SSO_LOGIN_METHODS: { JWT_SSO }
  } = CONST

  const isSignatureValidationEnabled = delve(data, 'config.enableSignatureValidation', false)
  const isImplicit = (delve(data, 'config.grantType') || '').toUpperCase() === 'IMPLICIT'

  if (isSignatureValidationEnabled && isImplicit) {
    // it is JWT SOO
    data.config.type = JWT_SSO
    data.type = JWT_SSO

    //  for JWT SSO we have to set `clientID` with module id of the sso
    // this is to pre populate the clientID value in the UI for first time
    if (!data.config.clientId) {
      data.config.clientId = data.id
    }
  }
}
const __handleConvertJwtToOidc = (data) => {
  if (hasModules(data)) {
    data.modules = data.modules.map((sso) => {
      convertJwtToOidc(sso)
      return sso
    })
  } else {
    convertJwtToOidc(data)
  }
  return data
}

const __handleConvertOidcToJwt = (data) => {
  const {
    SSO_LOGIN_METHODS: { OIDC }
  } = CONST

  if (hasModules(data)) {
    data.modules = data.modules.map((sso) => {
      // these are SSO other than OIDC
      if (OIDC !== sso.type) {
        return sso
      }
      convertOidcToJwt(sso)
      return sso
    })
  } else {
    convertOidcToJwt(data)
  }
  return data
}

function requestDataInterceptorAuthentications(data) {
  return __handleConvertJwtToOidc(data)
}

function responseDataInterceptorAuthentications(data) {
  return __handleConvertOidcToJwt(data)
}

const requestDataInterceptorEntryPoints = (data) => {
  return __handleConvertJwtToOidc(data)
}

const responseDataInterceptorEntryPoints = (data) => {
  if (data.entrypoint) {
    data.entrypoint = __handleConvertOidcToJwt(data.entrypoint)
  }
  return data
}

const getEnabledAuthModulesConfig = (authModules = []) => {
  const { PASSWORD, GOOGLE, MAGICLINK, SSO, SHOPIFY } = CONST.AUTHENTICATION_METHODS
  let moduleConfig = {
    modules: {},
    showSeparator: false,
    showSeparatorForActivation: false,
    isCapchaEnabledForOrg: false
  }
  let isPasswordEnabled = false
  let isNonPasswordEnabled = false
  let isMagicLinkEnabled = false
  let isShopifyEnabled = false
  authModules.forEach((module) => {
    /*
     * Currently there is a chance for multiple shopify integrated in BE, but
     * FE support only one shopify module. We always should consider the first in the response
     */
    if (module.enabled || module.type === SHOPIFY) {
      if (module.customSso) {
        let moduleType = SSO
        let existingSSOModules = moduleConfig.modules[moduleType] || []
        isNonPasswordEnabled = true
        let ssoModules = [...existingSSOModules, module].sort(
          (ssoModule, anotherSsoModule) => parseInt(ssoModule.id) - parseInt(anotherSsoModule.id)
        )
        moduleConfig.modules[moduleType] = ssoModules
      } else {
        if (module.type === PASSWORD) {
          isPasswordEnabled = true
        } else if (module.type === GOOGLE) {
          isNonPasswordEnabled = true
        } else if (module.type === MAGICLINK) {
          isMagicLinkEnabled = true
        } else if (module.type === SHOPIFY) {
          isShopifyEnabled = true
        }
        if (!moduleConfig.modules[module.type]) {
          moduleConfig.modules[module.type] = module
        }
      }
    }

    if (module.type === PASSWORD) {
      moduleConfig.isCapchaEnabledForOrg = delve(module, 'config.captchaConfigured', false)
    }
  })
  moduleConfig.showSeparator =
    isPasswordEnabled && (isNonPasswordEnabled || isMagicLinkEnabled || isShopifyEnabled)
  moduleConfig.showSeparatorForActivation =
    (isPasswordEnabled || isMagicLinkEnabled) && isNonPasswordEnabled
  return moduleConfig
}

const getValidatedAuthMethods = (authResponse) => {
  const authModules = delve(authResponse, 'modules', [])
  // check for authModules.length because sometimes /authentications call return empty response
  if (authModules.length === 0) {
    throw new Error(AUTH_CONST.NO_AUTHENTICATION_METHODS)
  }

  const authModuleConfig = getEnabledAuthModulesConfig(authModules)
  // authModuleConfig.modules contains enabled modules and is checked
  // for the use case in entry points, where in an admin can simply
  // create an entry point but not enabled any authentication methods
  if (Object.keys(authModuleConfig.modules).length === 0) {
    throw new Error(AUTH_CONST.NO_AUTH_OPTIONS_CONFIGURED)
  }
  return authModuleConfig
}

const AUTH_CONST = {
  AUTHENTICATIONS_METHOD_FAILED: 'AUTHENTICATIONS_METHOD_FAILED',
  NO_AUTHENTICATION_METHODS: 'NO_AUTHENTICATION_METHODS',
  ORG_DOMAIN_BEING_SETUP: 'ORG_DOMAIN_BEING_SETUP',
  NO_AUTH_OPTIONS_CONFIGURED: 'NO_AUTH_OPTIONS_CONFIGURED'
}

/**
 * When loaded in portalCname, need to redirect to orgDomain to set session for orgDomain
 * @param {string} orgDomain
 * @param {string} sKey enryptedCookie sent by backend
 * @param {string} search query param string
 * @returns url to which user is redirected to set cookie
 */
const getOrgSetCookieUrl = (orgDomain, sKey, search) => {
  return `https://${orgDomain}${ENV.PREFIX}/${ENV.NAMESPACE}/${
    CONST.ORG_PATHS.SET_ORG_SESSION
  }?${addQueryParams(search, { s_key: sKey })}`
}
export {
  getGoogleUiRedirectUrl,
  requestDataInterceptorAuthentications,
  responseDataInterceptorAuthentications,
  requestDataInterceptorEntryPoints,
  responseDataInterceptorEntryPoints,
  AUTH_CONST,
  getValidatedAuthMethods,
  getEnabledAuthModulesConfig,
  getOrgSetCookieUrl
}
