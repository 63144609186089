import { getTranslationFileLocation, flattenMessages } from '../utility'
export const LOCALE_FETCH_ERRORS = {
  PREVIOUS_REQUEST_IN_PROGRESS: 'PREVIOUS_REQUEST_IN_PROGRESS',
  NETWORK_ERROR: 'NETWORK_ERROR'
}

const getErrorObj = (errorType, reason = '') => {
  return {
    errorType,
    reason
  }
}

// On load of the app for first time, AppDataProvider does setLocale twice.
// This is because even before the first XHR completes, the re-render happens in RDTSA and
// second XHR is fired, so having this feature of processing one request at a time

// flag for not firing multiple fetch calls from LanguageProvider
let isRequestProcessing = false

export default function localeFetch(locale) {
  const userTranslationFile = getTranslationFileLocation(locale)
  const userLocale = locale.split('-')[0]

  // reject the request if another request is in progress
  if (isRequestProcessing) {
    return new Promise((resolve, reject) => {
      reject(getErrorObj(LOCALE_FETCH_ERRORS.PREVIOUS_REQUEST_IN_PROGRESS, ''))
    })
  }

  return new Promise((resolve, reject) => {
    isRequestProcessing = true
    Promise.all([
      import(`react-intl/locale-data/${userLocale}`),
      fetch(userTranslationFile).then((resp) => resp.json())
    ])
      .then(([intlLocaleData, userLangTranslation]) => {
        // Depending on the configuration (webpack, babel, etc.),
        // when importing dynamically, the received module data
        // can be nested into a default property.
        intlLocaleData = Array.isArray(intlLocaleData) ? intlLocaleData : intlLocaleData.default
        resolve([intlLocaleData, flattenMessages(userLangTranslation)])
      })
      .catch((error) => {
        reject(
          getErrorObj(
            LOCALE_FETCH_ERRORS.NETWORK_ERROR,
            `localFetch.js cannot request language files. Reason -> ${error}`
          )
        )
      })
      .finally(() => {
        isRequestProcessing = false
      })
  })
}
