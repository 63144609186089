import React, { createContext, useContext, useEffect, useState } from 'react'
import { AppStateContext } from '../../components/AppDataProvider'
import delve from 'dlv'
import { CONST } from '../../utils'

export const initialStateOfProfileFieldsContext = {
  restrictions: {
    restrictionLevel: null,
    restrictedFields: null
  },
  updateRestrictionConfig: () => {}
}

const ProfileFieldsContext = createContext()

export const ProfileFieldsProvider = ({ children }) => {
  const profileFieldDispatcher = (restrictionLevel, restrictedFields = []) => {
    setProfileFieldsRestrictionsConfig((profileState) => ({
      ...profileState,
      restrictions: {
        restrictionLevel,
        restrictedFields
      }
    }))
  }

  const [profileFieldsRestrictionsConfig, setProfileFieldsRestrictionsConfig] = useState({
    restrictions: { ...initialStateOfProfileFieldsContext.restrictions },
    updateRestrictionConfig: profileFieldDispatcher
  })

  const {
    common: {
      getOrgConfig: { organisationConfig }
    }
  } = useContext(AppStateContext)

  useEffect(() => {
    const {
      restrictionLevel = CONST.USER_PROFILE_RESTRICTION_LEVELS.RESTRICTION_ALLOW_ALL,
      restrictedFields = []
    } = delve(organisationConfig, 'userProfileUpdateRestrictions', {})
    setProfileFieldsRestrictionsConfig((profileState) => ({
      ...profileState,
      restrictions: {
        restrictionLevel,
        restrictedFields
      }
    }))
  }, [organisationConfig])

  return (
    <ProfileFieldsContext.Provider value={profileFieldsRestrictionsConfig}>
      {children}
    </ProfileFieldsContext.Provider>
  )
}

export const useProfileFieldsContext = () => useContext(ProfileFieldsContext)
