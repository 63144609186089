import React from 'react'
import DataManager from './../components/DataManager'
import constants from './constants'
import { getOrgDomain } from './utility'
import { getDataFromAppStateContext } from '../components/AppDataProvider/AppStateContext'

class OrgUtils {
  IsDomainAvailable = ({ children, onSuccess, onError }) => {
    const orgDomain = getOrgDomain()
    return (
      <DataManager
        activity="checkIfOrgDomainExists"
        retryStrategy={[1000, 2000, 4000]}
        pathFragment={[orgDomain]}
        onSuccess={onSuccess}
        onError={(error) => {
          if (!!onError && typeof onError === 'function') {
            return onError(error)
          }
          window.location.assign(constants.FRESHWORKS_HOME)
        }}>
        {children}
      </DataManager>
    )
  }
}

export default new OrgUtils()

/**
 * @param {object} context State of AppDataFetcher
 * @returns {string} orgDomain
 * @desc hook is not used because some class components also use this function
 */
export const getOrgDomainFromContext = (context) =>
  getDataFromAppStateContext(context, 'common.getOrgConfig.organisationDomain', window.location.host)

  export const getIsLoadedInOrg = (context) =>
  getDataFromAppStateContext(context, 'common.getOrgConfig.loadedInOrgDomain', true)

export const orgConfigInterceptor = (orgConfig) => {
  /**
   * @Desc Until backend is no sending organisationDomain, default behaviour is to assume this is orgUrl
   */
  orgConfig.loadedInOrgDomain = orgConfig.organisationDomain ? (orgConfig.organisationDomain === window.location.host) : true;
  return orgConfig;
}
