import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { logoutUser } from './../../../apis'
import { useLocaleState } from '../../../utils/LanguageProvider/LanguageProvider'
import { getHostFromUrl, getOrgDomain } from '../../../utils/utility'
import CONST from '../../../utils/constants'

const OMNIBAR_CSS_EXTENSIONS = {
  LTR: '.css',
  RTL: '.rtl.css'
}

const OmnibarResources = React.memo(({ build, isRTL }) => (
  <Helmet>
    <link
      rel="stylesheet"
      href={`https://accounts.freshworks.com/omnibar/omnibar-${build}${
        isRTL ? OMNIBAR_CSS_EXTENSIONS.RTL : OMNIBAR_CSS_EXTENSIONS.LTR
      }`}
    />
    <script
      type="text/javascript"
      src={`https://accounts.freshworks.com/omnibar/omnibar-${build}.js`}
    />
  </Helmet>
))

function Omnibar({ build }) {
  window.freshworksOmnibar = {
    onSessionStateChanged: logoutUser
  }

  const url = window.location.href
  const organisationDomain = getOrgDomain() || getHostFromUrl(url),
    [localeConfig] = useLocaleState(),
    selectedLang = localeConfig.locale,
    HOST_PRODUCT = 'freshworks'

  return (
    <div>
      <freshworks-omnibar
        data-omnibar-organisation-domain={organisationDomain}
        data-omnibar-product={HOST_PRODUCT}
        data-omnibar-user-locale={selectedLang}
        data-omnibar-product-logout-url={CONST.DEFAULT_REDIRECT_PATH}
      />
      <OmnibarResources build={build} isRTL={localeConfig.isRTLLanguage} />
    </div>
  )
}

Omnibar.displayName = 'FreshworksSideNavigation.Omnibar'
Omnibar.propTypes = {
  apiHost: PropTypes.string,
  build: PropTypes.oneOf(['latest', 'beta', 'stable']).isRequired,
  environment: PropTypes.oneOf(['development', 'qa', 'staging', 'production']).isRequired
}

Omnibar.defaultProps = {
  build: 'stable',
  environment: 'production'
}

export default memo(Omnibar)
