import React from 'react'
import PropTypes from 'prop-types'
import { Box } from './../Grid'
import { IMAGE_CDN_URL } from '../../config'

export default function Image(props) {
  const { src, alt, ...rest } = props

  return (
    <Box {...rest}>
      <img alt={alt} src={IMAGE_CDN_URL`${src}`} style={{ width: '100%' }} />
    </Box>
  )
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
}

Image.defaultProps = {}
