import React, { Component, useContext } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'react-emotion'

import { RELEASE_SCOPE } from '../../config'

import FreshworksLogo from './FreshworksLogo'
import Item from './Item'
import NavItem from './NavItem'
import Omnibar from './Omnibar'
import NavIcon from './NavIcon'
import { useIntl } from '../IntlContext'

import { CONST, getPageTitleTranslationPath } from '../../utils'
import { AppStateContext } from '../AppDataProvider'
import { getDataFromAppStateContext } from '../AppDataProvider/AppStateContext'
import { useDeviceType } from '../../utils/customHooks'
import { Flex } from '../Grid'

let SIDEBAR_WIDTH = 64

const StickyNavBar = styled(Flex)`
  ${({
    theme: {
      colors: {
        components: {
          railNavigation: { backgroundColor }
        }
      }
    }
  }) => {
    return {
      width: `100%`,
      display: 'flex',
      position: 'fixed',
      bottom: 0,
      zIndex: 4,
      backgroundColor
    }
  }}
`

const NavigationContainer = styled('nav')`
  ${({
    theme: {
      colors: {
        components: {
          railNavigation: { backgroundColor }
        }
      }
    }
  }) => {
    return {
      width: `${SIDEBAR_WIDTH}px`,
      height: '100vh',
      position: 'relative',
      backgroundColor
    }
  }}
`

const TopSectionContainer = styled('ul')`
  position: relative;
  width: 100%;
  height: 60%;
  overflow: visible;
  padding: 0;
  z-index: 4;
  li {
    list-style-type: none;
  }
  display: ${({ isMobile = false }) => (isMobile ? 'flex' : 'block')};
`
TopSectionContainer.displayName = 'FreshworksSideNavigation.Top'

const BottomSectionContainer = styled('ul')`
  position: absolute;
  width: 100%;
  bottom: 0;
  max-height: 30%;
  padding: 0;

  li {
    list-style-type: none;
  }

  #omnibar-trigger-button {
    ::before {
      top: 70%;
    }
    ::after {
      top: 70%;
    }
  }
`

const StickyOmniBarStyles = css`
  position: relative;
  margin-left: auto;
  padding: 14px;
  text-align: center;
  list-style-type: none;
  width: 30px;
  height: 30px;
`

BottomSectionContainer.displayName = 'FreshworksSideNavigation.Bottom'

class SideNavigationContainer extends Component {
  static Logo = FreshworksLogo
  static TopSection = TopSectionContainer
  static NavItem = NavItem
  static Item = Item
  static BottomSection = BottomSectionContainer
  static Omnibar = Omnibar

  static propTypes = {
    children: PropTypes.node.isRequired
  }

  render() {
    const { children } = this.props
    return <NavigationContainer>{children}</NavigationContainer>
  }
}

const getNavItemsList = (getOrgSummary) => [
  {
    name: CONST.NAV_ITEMS.DASHBOARD,
    routeTo: '/',
    tooltipText: getPageTitleTranslationPath('dashboard'),
    navIcon: 'dashboard'
  },
  {
    name: CONST.NAV_ITEMS.USERS_AND_GROUPS,
    routeTo: '/users-groups/users',
    tooltipText: getPageTitleTranslationPath('user_management', getOrgSummary),
    navIcon: 'users-groups'
  },
  {
    name: CONST.NAV_ITEMS.SUBSCRIPTION,
    routeTo: '/subscriptions',
    tooltipText: 'bills.page_title',
    navIcon: 'subscriptions'
  },
  {
    name: CONST.NAV_ITEMS.SECURITY,
    routeTo: CONST.SECURITY_AGENTS_PATHNAME,
    tooltipText: getPageTitleTranslationPath('security'),
    navIcon: 'security'
  },
  {
    name: CONST.NAV_ITEMS.ORGANIZATION,
    routeTo: '/organization',
    tooltipText: getPageTitleTranslationPath('organisation'),
    navIcon: 'organization'
  },
  {
    name: CONST.NAV_ITEMS.AUDIT_LOGS,
    routeTo: '/audit-logs',
    tooltipText: getPageTitleTranslationPath('audit_logs'),
    navIcon: 'audit-logs'
  }
]

function FreshworksSideNavigation() {
  const appState = useContext(AppStateContext)
  const { navigationState } = appState
  const getOrgSummary = getDataFromAppStateContext(appState, 'getOrgSummary', {})
  const { toIntlString } = useIntl()
  const navigationItemsToBeDisplayed = getNavItemsList(getOrgSummary)
  const { isMobile } = useDeviceType()
  SIDEBAR_WIDTH = isMobile ? 0 : 64

  const NavigationItems = () => (
    <>
      {navigationItemsToBeDisplayed.map(
        ({ routeTo, tooltipText, name, navIcon }) =>
          navigationState[name] && (
            <SideNavigationContainer.NavItem
              to={routeTo}
              tooltipText={toIntlString(tooltipText)}
              key={name}
              isMobile={isMobile}>
              <NavIcon to={navIcon} />
            </SideNavigationContainer.NavItem>
          )
      )}
    </>
  )

  return (
    <>
      {isMobile ? (
        <StickyNavBar>
          <TopSectionContainer isMobile={isMobile}>
            <NavigationItems />
            <li className={StickyOmniBarStyles}>
              <Omnibar build={RELEASE_SCOPE} />
            </li>
          </TopSectionContainer>
        </StickyNavBar>
      ) : (
        <SideNavigationContainer>
          <SideNavigationContainer.Logo homeRoute={CONST.DEFAULT_REDIRECT_PAGE} />
          <SideNavigationContainer.TopSection>
            <NavigationItems />
          </SideNavigationContainer.TopSection>
          <SideNavigationContainer.BottomSection>
            <SideNavigationContainer.Item>
              <Omnibar build={RELEASE_SCOPE} />
            </SideNavigationContainer.Item>
          </SideNavigationContainer.BottomSection>
        </SideNavigationContainer>
      )}
    </>
  )
}

export default FreshworksSideNavigation

export { SideNavigationContainer, SIDEBAR_WIDTH }
