import React, { Component } from 'react'
import { Route } from 'react-router-dom'
import Icon from '../Icon'
import styled from 'react-emotion'

const NavIconContainer = styled('span')`
  width: 100%;
  height: 100%;
  display: inline-block;
  vertical-align: sub;
`

class NavIcon extends Component {
  state = {
    icon: this.props.to
  }

  setIconState = (iconState) => {
    this.setState({
      icon: iconState
    })
  }

  render() {
    const { to } = this.props
    const { icon } = this.state
    return (
      <Route>
        {({ location: { pathname } }) => {
          const DASHBOARD_PATH = 'dashboard'

          const routePathFragments = pathname.split('/').filter((path) => !!path)

          /** Modifying pathname to `dashboard` when route pathname
            is `/` to match `to` from props, so it will
            map to the respective dashboard railnav icon */
          if (!routePathFragments.length) {
            routePathFragments.push(DASHBOARD_PATH)
          }

          // sufficient to check top-level route to identify if current route
          const isCurrentRoute = routePathFragments[0] === to
          if (isCurrentRoute) {
            return (
              <NavIconContainer
                onMouseLeave={() => {
                  this.setIconState(to)
                }}>
                <Icon name={`${to}-active`} width={20} />
              </NavIconContainer>
            )
          }

          return (
            <NavIconContainer
              onMouseEnter={() => {
                this.setIconState(`${to}-active`)
              }}
              onMouseLeave={() => {
                this.setIconState(to)
              }}>
              <Icon name={icon} width={20} />
            </NavIconContainer>
          )
        }}
      </Route>
    )
  }
}

export default NavIcon
