import React from 'react'
import { createContext, useContext } from 'react'
import delve from 'dlv'

import AppStateContext, {
  getDataFromAppStateContext
} from '../components/AppDataProvider/AppStateContext'
import { DEFAULT_PERMISSIONS_LIST } from '../pages/Users/AdminCenterRoles/utils'

export const RBACContext = createContext()

export const RBACProvider = ({ children, permissions, overridePermissions = false }) => {
  const readPrivileges = delve(permissions, 'READ', [])
  const writePrivileges = delve(permissions, 'UPDATE', [])
  return (
    <RBACContext.Provider
      value={{
        isRBACEnabledPage: true,
        canUserRead: overridePermissions || useUserPrivileges(readPrivileges),
        canUserWrite: overridePermissions || useUserPrivileges(writePrivileges)
      }}>
      {children}
    </RBACContext.Provider>
  )
}

export const useUserPrivileges = (privileges = []) => {
  const getCurrentUserDetails = getDataFromAppStateContext(
    useContext(AppStateContext),
    'getCurrentUserDetails',
    {}
  )
  let permissions = delve(getCurrentUserDetails, `data.user.roleInfo.orgPrivileges`, [])
  const firstPrivilege = delve(permissions, `0`, '')
  if (firstPrivilege in DEFAULT_PERMISSIONS_LIST) {
    permissions = DEFAULT_PERMISSIONS_LIST[firstPrivilege]
  }
  let permissionsSet = new Set(permissions)

  let isActionAllowed = false
  for (let privilege of privileges) {
    if (permissionsSet.has(privilege)) {
      isActionAllowed = true
      break
    }
  }
  return isActionAllowed
}

const IfPrivilegesExist = ({ privileges = {}, children }) => {
  if (useUserPrivileges(privileges.READ)) {
    return <RBACProvider permissions={privileges}>{children}</RBACProvider>
  } else {
    return null
  }
}

const IfWritePrivilegesExist = ({ privileges = {}, children }) => {
  if (useUserPrivileges(privileges.UPDATE)) {
    return children
  } else {
    return null
  }
}

const IfPrivilegesAreAbsent = ({ privileges = {}, children }) => {
  if (!useUserPrivileges(privileges.READ)) {
    return children
  } else {
    return null
  }
}

const CanUserWrite = ({ children }) => {
  const { canUserWrite = true } = useContext(RBACContext) || {}
  if (canUserWrite) {
    return children
  } else {
    return null
  }
}

export const withRBAC = (Ele) => ({ children, ...rest }) => {
  const { canUserWrite = true } = useContext(RBACContext) || {}
  return (
    <Ele canUserWrite={canUserWrite} {...rest}>
      {children}
    </Ele>
  )
}

export const usePrivilegeValidation = ({ targetUser, permissions = [] }) => {
  const currentUserDetails = getDataFromAppStateContext(
    useContext(AppStateContext),
    'getCurrentUserDetails',
    {}
  )
  const isOrgAdmin = delve(currentUserDetails, 'data.user.admin', false)
  const isSameUser = delve(targetUser, 'id', null) === delve(currentUserDetails, 'data.user.id')
  const userHasPermission = useUserPrivileges(permissions)
  const selectedUserIsNotOrgAdmin = !delve(targetUser, 'admin', false)
  return {
    canUserUpdate: isOrgAdmin || (userHasPermission && (isSameUser || selectedUserIsNotOrgAdmin))
  }
}

RBACProvider.IfPrivilegesExist = IfPrivilegesExist
RBACProvider.IfPrivilegesAreAbsent = IfPrivilegesAreAbsent
RBACProvider.IfWritePrivilegesExist = IfWritePrivilegesExist
RBACProvider.CanUserWrite = CanUserWrite

export default RBACProvider
