import delve from 'dlv'
import Yup from 'yup'
import humps from 'humps'

import {
  ArrayUtils,
  CONST,
  getErrorList,
  sanitizePEM,
  getNormalizedAccountDomain,
  getInfoToDisplayAccount
} from '../../utils'
import SSOFields from './SSOFields'
import { LOGIN_PAGE_PARAM_KEYS } from '../Login/config'
import { FRESHID_CLIENT_ID } from '../../config'
import SECURITY_TEMPLATES from './Templates/SecurityTemplates'

const SECURITY_BASE_PATH = '/security'
const SECURITY_TRANSLATION_BASE_PATH = 'security.settings'
const SECURITY_AGENTS_STR = 'admins_agents'
const SECURITY_CONTACTS_STR = 'contacts'
const SECURITY_AGENTS_ROUTE_PATH = 'agents'
const SECURITY_CONTACTS_ROUTE_PATH = 'contacts'
const DEFAULT_LOGIN_METHOD_ROUTE_PATH = 'default'
const EDIT_PERMISSIONS_PATH = 'edit-field-permissions'
const SECURITY_SESSION_MANAGEMENT_PATH = 'session-management'
const ENTRY_POINT_COUNT_LIMIT = 99
const MAX_PORTALS_SIZE = 100

const AUTHENTICATION_METHOD_NAMES = {
  PASSWORD: 'PASSWORD',
  GOOGLE: 'GOOGLE',
  SSO: 'SSO',
  MAGICLINK: 'MAGICLINK',
  SHOPIFY: 'SHOPIFY'
}
const AUTHENTICATION_METHOD_TYPES = {
  PASSWORD: AUTHENTICATION_METHOD_NAMES.PASSWORD,
  GOOGLE: AUTHENTICATION_METHOD_NAMES.GOOGLE,
  MAGICLINK: AUTHENTICATION_METHOD_NAMES.MAGICLINK,
  SHOPIFY: AUTHENTICATION_METHOD_NAMES.SHOPIFY,
  SSO: {
    SAML: {
      type: 'SAML',
      displayName: 'SAML',
      configHelpArticleTextLink: 'https://support.freshworks.com/support/solutions/articles/237923',
      fields: SSOFields.SAML,
      hasMultipleIdpSupport: true,
      isMetadataDownloadEnabled: true
    },
    OAUTH: {
      type: 'OAUTH',
      displayName: 'OAuth 2.0',
      configHelpArticleTextLink: 'https://support.freshworks.com/support/solutions/articles/238672',
      fields: SSOFields.OAUTH,
      hasMultipleIdpSupport: false,
      isMetadataDownloadEnabled: false
    },
    OIDC: {
      type: 'OIDC',
      displayName: 'OIDC',
      configHelpArticleTextLink: 'https://support.freshworks.com/support/solutions/articles/238307',
      fields: SSOFields.OIDC,
      hasMultipleIdpSupport: false,
      isMetadataDownloadEnabled: false
    },
    JWT_SSO: {
      type: 'JWT_SSO',
      displayName: 'JWT',
      configHelpArticleTextLink:
        'https://support.freshworks.com/support/solutions/articles/50000000670',
      fields: SSOFields.JWT_SSO,
      hasMultipleIdpSupport: false,
      isMetadataDownloadEnabled: false
    }
  }
}
const SSO_IDP_TYPES = {
  ONELOGIN: {
    displayName: 'OneLogin',
    configHelpArticleTextLink: 'https://support.freshworks.com/support/solutions/articles/240106',
    SAML: {
      configHelpPointLength: 3
    }
  },
  OKTA: {
    displayName: 'Okta',
    configHelpArticleTextLink:
      'https://support.freshworks.com/support/solutions/articles/50000002353',
    SAML: {
      configHelpPointLength: 5
    }
  },
  AZURE_AD: {
    displayName: 'Azure AD',
    configHelpArticleTextLink:
      'https://support.freshworks.com/support/solutions/articles/50000002354',
    SAML: {
      configHelpPointLength: 4
    }
  },
  ADFS: {
    displayName: 'ADFS',
    configHelpArticleTextLink:
      'https://support.freshworks.com/support/solutions/articles/50000002355',
    SAML: {
      configHelpPointLength: 0
    }
  },
  SHIBBOLETH: {
    displayName: 'Shibboleth',
    configHelpArticleTextLink:
      'https://support.freshworks.com/support/solutions/articles/50000003375',
    SAML: {
      cannotAddFreshWorkAsAppInIdp: true,
      configHelpPointLength: 3
    }
  }
}
const { PRODUCT_NAMES, SSO_SUPPORTED_INPUT_TYPES, MFA } = CONST
const CUSTOM_POLICIES_ROUTE = 'custom-policies'
const CUSTOM_POLICIES_CREATE_OR_EDIT_ROUTE = 'edit'
const {
  AVAILABLE_CUSTOMISATION: { ALL_USERS_AND_GROUPS, ALL_ADMINS, SOME_USERS_AND_GROUPS },
  NO_CUSTOMISATION,
  EXCLUDING,
  INCLUDING,
  TYPES
} = SECURITY_TEMPLATES.MFA
const NO_OF_PARALLEL_SESSION = {
  MIN_ALLOWED_SESSIONS_PER_USER: 1,
  MAX_ALLOWED_SESSIONS_PER_USER: 30
}
const sessionManagementAvailableEnforcementLevel = {
  IMMEDIATE: 'IMMEDIATE',
  NEXT_LOGIN: 'NEXT_LOGIN'
}

const SHORT = 'short'
const NUMERIC = 'numeric'

function SecurityTab(routePath, displayKey, component) {
  this.routePath = routePath
  this.displayKey = displayKey
  this.component = component
  this.selected = false
  this.isAllowed = (hasFreshdeskAccount, isEntryPointEnabled) =>
    this.displayKey !== SECURITY_CONTACTS_STR || (hasFreshdeskAccount && isEntryPointEnabled)
}

function OrgAccount({ ...rest }) {
  Object.assign(this, rest)
  this.product = {}
  this.portals = []
  this.isEntryPointEnabled = false
}

const SECURITY_TABS = [
  new SecurityTab(SECURITY_AGENTS_ROUTE_PATH, SECURITY_AGENTS_STR, 'Agents'),
  new SecurityTab(SECURITY_CONTACTS_ROUTE_PATH, SECURITY_CONTACTS_STR, 'Contacts')
]

const matchAndSetSelectedTab = (pathname = '', tabs, setUserType) => {
  const tabName = pathname.replace(`${SECURITY_BASE_PATH}/`, '').split('/')[0]
  return tabs.some((tab) => {
    const { displayKey, routePath } = tab
    if (routePath === tabName) {
      tab.selected = true
      setUserType(displayKey)
      return true
    }
    return false
  })
}

const validatePathAndRedirect = (tabs, pathname, history, setUserType) => {
  if (!matchAndSetSelectedTab(pathname, tabs, setUserType)) {
    // invalid path param tabName
    // Redirect to agents screen
    history.replace(getPaths.agentsLanding())
  }
}
const isFreshdeskAccount = ({ name } = {}) => name && name.toLowerCase() === PRODUCT_NAMES.FRESHDESK

const shouldShowPortals = (portals, product, userType) =>
  portals.length > 0 && !(isFreshdeskAccount(product) && userType == SECURITY_AGENTS_STR)

const isFreshdeskAccountPresent = (accountsMap) =>
  Object.values(accountsMap).some((account) => isFreshdeskAccount(account.product))

const getAllowedSecurityTabs = (accountsMap, securityTabs) => {
  const hasFreshdeskAccount = isFreshdeskAccountPresent(accountsMap)
  const isEntryPointEnabled = getIsEntryPointsEnabled(accountsMap)
  return securityTabs.filter((tab) => tab.isAllowed(hasFreshdeskAccount, isEntryPointEnabled))
}

const getAnchorAccountsMappedByBundleIdentifier = (accounts = []) => {
  const anchorAccounts = accounts.filter((account) => account.anchor)
  return ArrayUtils.mapByKey(anchorAccounts, 'bundleIdentifier')
}

const getOrgAccountsMap = (
  products = [],
  accounts = [],
  portals = [],
  bundleData,
  cloudTypesMapById,
  allAccountsMap = {}
) => {
  const productMapById = ArrayUtils.mapByKey(products, 'id')
  const orgAccountsMap = {}
  // If the lookup is already available use it else use the accounts
  const allAccountsLookUp =
    Object.values(allAccountsMap).length > 0 ? Object.values(allAccountsMap) : accounts
  const anchorAccountsInOrgMap = getAnchorAccountsMappedByBundleIdentifier(allAccountsLookUp)
  // Append required info into the account for further reference
  // 1. isEntryPointEnabled
  // 2. Display related info - iconSrc, accountName and accountDomain
  // 3. Portals info
  accounts.forEach((account) => {
    const product = productMapById.get(account.productId)
    if (product) {
      const orgAccount = new OrgAccount(account)
      orgAccount.product = product
      orgAccount.isEntryPointEnabled = product.entrypointEnabled

      // For easier look up among all the security page related icon displays
      // resolving the iconSrc, accountName and accountDomain at the top level itself
      let accountToDeriveInfoFrom = account
      if (!account.anchor && account.bundleIdentifier) {
        // If it is a bundle account then derive the info for the seeder account from the anchor account
        // This use case arises where a account being a seeder should be displayed in the bundle's representation
        // We are lookup to the corresponding anchor account details to getInfoToDisplayAccount
        accountToDeriveInfoFrom = anchorAccountsInOrgMap.has(account.bundleIdentifier)
          ? anchorAccountsInOrgMap.get(account.bundleIdentifier)
          : accountToDeriveInfoFrom
      }
      if (accountToDeriveInfoFrom.iconSrc && accountToDeriveInfoFrom.accountNameToDisplay) {
        //If these info are already available in the lookup then use the same
        orgAccount.iconSrc = accountToDeriveInfoFrom.iconSrc
        orgAccount.accountNameToDisplay = accountToDeriveInfoFrom.accountNameToDisplay
      } else {
        //Else use the getInfoToDisplayAccount to fetch those details and append to the account object
        const { src: iconSrc, accountName } = getInfoToDisplayAccount(
          productMapById.get.bind(productMapById),
          accountToDeriveInfoFrom,
          bundleData,
          cloudTypesMapById
        )
        orgAccount.iconSrc = iconSrc
        orgAccount.accountNameToDisplay = accountName
      }
      orgAccount.accountDomainToDisplay = getNormalizedAccountDomain(accountToDeriveInfoFrom)
      orgAccountsMap[account.id] = orgAccount
    }
  })
  // Associate portals to corresponding accounts
  portals.forEach((portal) => {
    const account = orgAccountsMap[portal.accountId]
    if (account) {
      account.portals.push(portal)
    } else {
      const newAccount = JSON.parse(
        JSON.stringify({
          ...allAccountsMap[portal.accountId]
        })
      )
      newAccount.portalsOnlySelected = true
      newAccount.portals = []
      newAccount.portals.push(portal)
      orgAccountsMap[newAccount.id] = newAccount
    }
  })
  return orgAccountsMap
}

const getIsEntryPointsEnabled = (accountMap) =>
  Object.values(accountMap).some(({ isEntryPointEnabled }) => isEntryPointEnabled)

const setHeader = ({
  contextMethods,
  title = '',
  desc = '',
  shouldIncludeHelp = true,
  shouldShowCreateEP = false,
  shouldDisableCreateEP = false,
  isAbsoluteTitle = false,
  helpSectionTopic = CONST.AVAILABLE_HELP_TOPICS.SECURITY_HELP,
  isManageActiveSessions = false
}) => {
  contextMethods.setTitle(title, isAbsoluteTitle)
  contextMethods.setDescription(desc)
  contextMethods.setShouldIncludeHelp(shouldIncludeHelp)
  contextMethods.setShouldShowCreateNewEP(shouldShowCreateEP)
  contextMethods.setShouldDisableCreateNewEP(shouldDisableCreateEP)
  contextMethods.setHelpSectionTopic(helpSectionTopic)
  contextMethods.setIsManageActiveSessions(isManageActiveSessions)
}

const getEntryPointUrl = (entryPoint) =>
  entryPoint ? `https://${window.location.host}/login/auth/${entryPoint.slug}` : null

const getAuthenticationMethodConfig = (authenticationMethods) => {
  const config = {
    SSO: {
      enabled: false
    }
  }
  const showAuthMethods = {
    [AUTHENTICATION_METHOD_TYPES.PASSWORD]: true,
    [AUTHENTICATION_METHOD_TYPES.GOOGLE]: true,
    [AUTHENTICATION_METHOD_TYPES.MAGICLINK]: true,
    [AUTHENTICATION_METHOD_TYPES.SHOPIFY]: true
  }
  authenticationMethods.forEach((authMethod) => {
    // Allow only first SHOPIFY module even BE responds with multiple
    if (config[authMethod.type]) {
      return
    }
    if (showAuthMethods[authMethod.type]) {
      config[authMethod.type] = authMethod
    } else if (AUTHENTICATION_METHOD_TYPES.SSO[authMethod.type]) {
      if (authMethod.enabled && !config.SSO.enabled) {
        config.SSO.enabled = true
      }
      config.SSO[authMethod.id] = authMethod
    }
  })
  return config
}

const getAuthenticationMethodList = (authMethodConfig) => {
  const authModules = []
  if (authMethodConfig[AUTHENTICATION_METHOD_NAMES.PASSWORD]) {
    authModules.push(authMethodConfig[AUTHENTICATION_METHOD_NAMES.PASSWORD])
  }
  if (authMethodConfig[AUTHENTICATION_METHOD_NAMES.GOOGLE]) {
    authModules.push(authMethodConfig[AUTHENTICATION_METHOD_NAMES.GOOGLE])
  }
  if (authMethodConfig[AUTHENTICATION_METHOD_NAMES.MAGICLINK]) {
    authModules.push(authMethodConfig[AUTHENTICATION_METHOD_NAMES.MAGICLINK])
  }
  if (authMethodConfig[AUTHENTICATION_METHOD_NAMES.SHOPIFY]) {
    authModules.push(authMethodConfig[AUTHENTICATION_METHOD_NAMES.SHOPIFY])
  }
  let ssoModules = []
  if (authMethodConfig[AUTHENTICATION_METHOD_NAMES.SSO]) {
    ssoModules = getSSOModules(authMethodConfig)
  }
  return [...authModules, ...ssoModules]
}

const getOverrideAuthMethodMap = (authMethodMap = {}, overrideMap = {}) => {
  const updatedAuthMethodMap = {
    [AUTHENTICATION_METHOD_NAMES.PASSWORD]: {
      ...authMethodMap[AUTHENTICATION_METHOD_NAMES.PASSWORD],
      ...overrideMap[AUTHENTICATION_METHOD_NAMES.PASSWORD]
    },
    [AUTHENTICATION_METHOD_NAMES.GOOGLE]: {
      ...authMethodMap[AUTHENTICATION_METHOD_NAMES.GOOGLE],
      ...overrideMap[AUTHENTICATION_METHOD_NAMES.GOOGLE]
    },
    [AUTHENTICATION_METHOD_NAMES.MAGICLINK]: {
      ...authMethodMap[AUTHENTICATION_METHOD_NAMES.MAGICLINK],
      ...overrideMap[AUTHENTICATION_METHOD_NAMES.MAGICLINK]
    },
    [AUTHENTICATION_METHOD_NAMES.SSO]: {
      ...authMethodMap[AUTHENTICATION_METHOD_NAMES.SSO],
      ...overrideMap[AUTHENTICATION_METHOD_NAMES.SSO]
    }
  }
  const overrideConfigMap = {
    ...authMethodMap,
    ...overrideMap,
    ...updatedAuthMethodMap
  }
  overrideConfigMap[AUTHENTICATION_METHOD_NAMES.SSO].enabled = false
  for (let moduleId in overrideConfigMap[AUTHENTICATION_METHOD_NAMES.SSO]) {
    let ssoModule = overrideConfigMap[AUTHENTICATION_METHOD_NAMES.SSO][moduleId]
    if (ssoModule instanceof Object) {
      if (!overrideConfigMap[AUTHENTICATION_METHOD_NAMES.SSO].enabled && ssoModule.enabled) {
        overrideConfigMap[AUTHENTICATION_METHOD_NAMES.SSO].enabled = true
      }
      overrideConfigMap[AUTHENTICATION_METHOD_NAMES.SSO][moduleId] = getSSOUpdateValues(ssoModule)
    }
  }
  return overrideConfigMap
}

const getPasswordModule = (authMethods = []) => {
  let authMethod = null
  authMethods.some((method) => {
    if (method.type === AUTHENTICATION_METHOD_NAMES.PASSWORD) {
      authMethod = method
      return true
    }
    return false
  })
  return authMethod
}

const getSSOModules = (authModuleMap = {}) => {
  const ssoConfig = authModuleMap[AUTHENTICATION_METHOD_NAMES.SSO]
  return Object.values(ssoConfig).filter(
    (module) => module.type && AUTHENTICATION_METHOD_TYPES.SSO[module.type]
  )
}

const getAllAuthMethodWithSSODisabled = (authMethodMap) => {
  authMethodMap = {
    [AUTHENTICATION_METHOD_NAMES.SSO]: {
      ...authMethodMap[AUTHENTICATION_METHOD_NAMES.SSO],
      enabled: false
    }
  }
  for (let id in authMethodMap[AUTHENTICATION_METHOD_NAMES.SSO]) {
    const sso = authMethodMap[AUTHENTICATION_METHOD_NAMES.SSO][id]
    if (
      sso instanceof Object &&
      !(
        sso.type === AUTHENTICATION_METHOD_NAMES.PASSWORD ||
        sso.type === AUTHENTICATION_METHOD_NAMES.GOOGLE ||
        sso.type === AUTHENTICATION_METHOD_NAMES.MAGICLINK
      )
    ) {
      sso.enabled = false
    }
  }
  return authMethodMap
}

const isAnyLoginMethodEnabled = (authMethodMap) => {
  return !!Object.values(AUTHENTICATION_METHOD_NAMES).filter(
    (loginMethod) => authMethodMap[loginMethod] && authMethodMap[loginMethod].enabled
  ).length
}

const isOnlyLoginMethodEnabled = (authType, authModuleConfig) => {
  const allAuthMethods = Object.keys(authModuleConfig.modules)

  return allAuthMethods.length === 1 && allAuthMethods[0] === authType
}

const isAnySSOEnabled = (ssoConfig = {}) =>
  !!Object.values(ssoConfig).filter(
    (config) =>
      AUTHENTICATION_METHOD_TYPES[AUTHENTICATION_METHOD_NAMES.SSO][config.type] && config.enabled
  ).length

const isThisTheOnlyEnabledSSO = (ssoConfig = {}, ssoModule = {}) => {
  let enabledSSO = null
  const enabledSSOCount = Object.values(ssoConfig).filter((config) => {
    if (
      AUTHENTICATION_METHOD_TYPES[AUTHENTICATION_METHOD_NAMES.SSO][config.type] &&
      config.enabled
    ) {
      enabledSSO = config
      return true
    }
    return false
  }).length
  return enabledSSOCount === 1 && enabledSSO.id === ssoModule.id
}

const isThisSSOTheOnlyEnabledAuthMethod = (authMethodConfig, ssoModule) => {
  return (
    !authMethodConfig[AUTHENTICATION_METHOD_NAMES.PASSWORD].enabled &&
    !authMethodConfig[AUTHENTICATION_METHOD_NAMES.GOOGLE].enabled &&
    !authMethodConfig[AUTHENTICATION_METHOD_NAMES.MAGICLINK].enabled &&
    !!authMethodConfig[AUTHENTICATION_METHOD_NAMES.SSO] &&
    !!authMethodConfig[AUTHENTICATION_METHOD_NAMES.SSO].enabled &&
    isThisTheOnlyEnabledSSO(authMethodConfig[AUTHENTICATION_METHOD_NAMES.SSO], ssoModule)
  )
}

const getEntryPointsPath = (userType) => {
  const userTypePath =
    userType === SECURITY_AGENTS_STR ? SECURITY_AGENTS_ROUTE_PATH : SECURITY_CONTACTS_ROUTE_PATH
  return `${SECURITY_BASE_PATH}/${userTypePath}/${CUSTOM_POLICIES_ROUTE}`
}
const getPaths = {
  agentsLanding: () => `${SECURITY_BASE_PATH}/${SECURITY_AGENTS_ROUTE_PATH}`,
  contactsLanding: () => `${SECURITY_BASE_PATH}/${SECURITY_CONTACTS_ROUTE_PATH}`,
  defaultLogin: () =>
    `${SECURITY_BASE_PATH}/${SECURITY_AGENTS_ROUTE_PATH}/${DEFAULT_LOGIN_METHOD_ROUTE_PATH}`,
  editPermissions: () =>
    `${SECURITY_BASE_PATH}/${SECURITY_AGENTS_ROUTE_PATH}/${EDIT_PERMISSIONS_PATH}`,
  entryPoints: getEntryPointsPath,
  entryPointEdit: (userType, entryPointId = null) =>
    `${getEntryPointsPath(userType)}/${
      entryPointId ? entryPointId : ':id'
    }/${CUSTOM_POLICIES_CREATE_OR_EDIT_ROUTE}`,
  sessionManagementLanding: () =>
    `${SECURITY_BASE_PATH}/${SECURITY_AGENTS_ROUTE_PATH}/${SECURITY_SESSION_MANAGEMENT_PATH}`
}

const getServerErrorTranslations = ({ data }, toIntlString) => {
  const { errorList } = getErrorList(data)
  if (errorList.length === 0) {
    return [toIntlString('error.generic_server_error')]
  }
  return errorList.map((errorItem) => toIntlString(`security.server_errors.${errorItem}`))
}

const getSSODisplayNameForCreation = (toIntlString, ssoType, idpType = null) => {
  let translationKey = `${SECURITY_TRANSLATION_BASE_PATH}.authentication_methods.sso_configuration.default_sso_title.`
  const translationObj = {
    ssoName:
      ssoType && AUTHENTICATION_METHOD_TYPES[AUTHENTICATION_METHOD_NAMES.SSO][ssoType]
        ? AUTHENTICATION_METHOD_TYPES[AUTHENTICATION_METHOD_NAMES.SSO][ssoType].displayName
        : AUTHENTICATION_METHOD_NAMES.SSO
  }
  if (idpType && SSO_IDP_TYPES[idpType]) {
    translationKey += 'specific_idp'
    translationObj.idpName = SSO_IDP_TYPES[idpType].displayName
  } else {
    translationKey += 'any_idp'
  }
  return toIntlString(translationKey, translationObj)
}

const getAccountUrl = (urls = []) => {
  let url = null
  try {
    url = new URL(urls[0]).pathname
  } catch (e) {
    url = ''
  }
  return url
}

const sanitizeData = ({ modules, ...rest }) => {
  modules.forEach((authModule) => {
    if (
      authModule.type === AUTHENTICATION_METHOD_TYPES[AUTHENTICATION_METHOD_NAMES.SSO].SAML.type &&
      authModule.config.idpCertificate
    ) {
      authModule.config.idpCertificate = sanitizePEM(authModule.config.idpCertificate)
    } else if (
      authModule.type ===
        AUTHENTICATION_METHOD_TYPES[AUTHENTICATION_METHOD_NAMES.SSO].JWT_SSO.type &&
      authModule.config.verificationKey
    ) {
      authModule.config.verificationKey = sanitizePEM(authModule.config.verificationKey)
    }
  })
  return { modules, ...rest }
}

const getSSOFieldDefaultValue = (ssoModule, { defaultValue }) =>
  typeof defaultValue === 'function' ? defaultValue(ssoModule) : defaultValue

const getNewAuthModulePayload = (ssoType, entryPointId = null, toIntlString, idpType = null) => {
  const ssoTemplate = [
    ...getIDPSpecificFields(ssoType, idpType, 'BASIC'),
    ...getIDPSpecificFields(ssoType, idpType, 'ADVANCED')
  ]
  let config = ssoTemplate
    .filter((ssoFields) => ssoFields.canSendToServerOnCreateModule)
    .reduce((accumulator, currentItem) => {
      return {
        ...accumulator,
        [currentItem.name]: getSSOFieldDefaultValue({ config: { ...accumulator } }, currentItem)
      }
    }, {})
  if (idpType) {
    config.idp_type = idpType
  }
  config.type = ssoType
  let payload = {
    type: ssoType,
    config: {
      ...config,
      sso_title: getSSODisplayNameForCreation(toIntlString, ssoType, idpType)
    },
    enabled: false
  }
  return entryPointId ? { ...payload, entrypoint_id: entryPointId } : payload
}

const getIDPSpecificFields = (ssoType, idpType = null, fieldType = 'BASIC') => {
  let fields =
    AUTHENTICATION_METHOD_TYPES[AUTHENTICATION_METHOD_NAMES.SSO][ssoType].fields[fieldType]
  return fields.map((field) => {
    let idpField = {
      ...field,
      headingKey: `${field.name}.heading`
    }
    if (idpType && field.idpOverrides && field.idpOverrides[idpType]) {
      let overrideObj = field.idpOverrides[idpType]
      if (overrideObj.overrideLabel) {
        idpField.headingKey = `${field.name}.${idpType}.heading`
      }
      if (overrideObj.canShowInUI !== undefined) {
        idpField.canShowInUI = overrideObj.canShowInUI
      }
      if (overrideObj.defaultValue !== undefined) {
        idpField.defaultValue = overrideObj.defaultValue
      }
      if (overrideObj.hasAdditionalDesc !== undefined) {
        idpField.hasAdditionalDesc = overrideObj.hasAdditionalDesc
      }
      if (field.type === SSO_SUPPORTED_INPUT_TYPES.RADIO && overrideObj.radioValues !== undefined) {
        idpField.radioValues = overrideObj.radioValues
      }
    }
    return idpField
  })
}

const getSSOUpdateValues = (ssoModule = {}, idpType = null) => {
  const ssoType = ssoModule.type
  let config = {}
  const allSSOFields = [
    ...getIDPSpecificFields(ssoType, idpType, 'BASIC'),
    ...getIDPSpecificFields(ssoType, idpType, 'ADVANCED')
  ]
  const configValues = ssoModule.config
  // if server sends config values, the SSO is already configured
  // and UI need not do a create API call
  if (configValues) {
    // set id and organisationId too, so when sending values to the server
    // all items will be taken from this form fields
    config.shouldCallCreateAPI = false
    config.id = ssoModule.id
    config.organisationId = ssoModule.organisationId
  } else {
    config.shouldCallCreateAPI = true
  }
  for (let i = 0; i < allSSOFields.length; i++) {
    const ssoField = allSSOFields[i]
    const fieldName = humps.camelize(ssoField['name'])
    config[fieldName] = ''

    const defaultValue = getSSOFieldDefaultValue(ssoModule, ssoField)

    if (defaultValue || ssoField.name == 'pkce_enabled') {
      // initialize the field with default value
      config[fieldName] = defaultValue
    }
    // if there is value for this field from server set it
    if (configValues && configValues[fieldName]) {
      config[fieldName] = configValues[fieldName]
    }
  }
  return {
    ...ssoModule,
    config
  }
}

const getAllSsoFields = (
  ssoType,
  formikRef,
  includeAdvancedFields = true,
  filterParentFields = true
) => {
  let fields = AUTHENTICATION_METHOD_TYPES[AUTHENTICATION_METHOD_NAMES.SSO][
    ssoType
  ].fields.BASIC.filter((customSso) => !customSso.disabled)
  if (includeAdvancedFields) {
    const advancedFields =
      AUTHENTICATION_METHOD_TYPES[AUTHENTICATION_METHOD_NAMES.SSO][ssoType].fields.ADVANCED
    const advancedFieldsMap = ArrayUtils.mapByKey(advancedFields, 'name')
    fields = [
      ...fields,
      ...advancedFields.filter((customSso) => {
        let isEnabled = !customSso.disabled
        const hasParent = !!customSso.parentField && !!customSso.parentField.field
        if (hasParent && formikRef && filterParentFields) {
          const parentField = advancedFieldsMap.get(customSso.parentField.field)
          const parentValue = delve(
            formikRef.current.state.values,
            `${parentField.path}.${humps.camelize(parentField.name)}`
          )
          if (typeof customSso.parentField.displayValidator === 'function') {
            isEnabled = customSso.parentField.displayValidator(parentValue)
          }
        }
        return isEnabled
      })
    ]
  }
  return fields
}

const getSSOValidationSchema = (ssoType, invalidMessage = '', formikRef) => {
  const fields = getAllSsoFields(ssoType, formikRef, !!formikRef)
  return Yup.object().shape({
    config: Yup.object({
      ...fields.reduce((accumulator, currentValue) => {
        const { name, required, error: fieldErrorMessage = '' } = currentValue
        return {
          ...accumulator,
          [humps.camelize(name)]: required
            ? Yup.string().required(fieldErrorMessage || invalidMessage)
            : Yup.string(fieldErrorMessage || '')
        }
      }, {})
    })
  })
}

const getValidationSchema = (toIntlString, formikRef) => {
  return Yup.lazy((values) =>
    getSSOValidationSchema(values.type, toIntlString('security.this_field_required'), formikRef)
  )
}

const isSSOConfiguredProperly = (ssoModuleConfig) => {
  return getSSOValidationSchema(ssoModuleConfig.type).isValidSync(ssoModuleConfig)
}

const getErrorMessageString = (toIntlString, error) => {
  const errorMessage = toIntlString(error)
  if (errorMessage.indexOf(error) > -1) {
    return toIntlString('error.generic_server_error')
  }
  return errorMessage
}

const getErrorMessage = ({ status, errorList } = {}, toIntlString) => {
  let errorCode = ''
  switch (status) {
    case 0:
      errorCode = ['error.connection_failure']
      break
    case 401:
      errorCode = ['error.unauthorized']
      break
    case 403:
      errorCode = ['error.forbidden_admin_access']
      break
    case 500:
    case 503:
      errorCode = ['error.generic_server_error']
      break
    default:
      if (errorList.length > 0) {
        errorCode = errorList.map((error) => `security.server_errors.${error}`)
      } else {
        errorCode = ['error.generic_server_error']
      }
  }
  return errorCode.map((error) => getErrorMessageString(toIntlString, error))
}

const getAuthUpdateErrorObject = (fields, ...args) => {
  const translationBasePath = `${SECURITY_TRANSLATION_BASE_PATH}.authentication_methods.sso_configuration.messages.error.`
  const [{ status, errorList } = {}, toIntlString] = args
  let error = null
  const errorFields = []
  switch (status) {
    case 400:
      fields.forEach((field) => {
        if (field.supportedErrors && field.supportedErrors.length) {
          field.error = null
          field.supportedErrors.forEach((errorCode) => {
            if (errorList.some((fieldError) => fieldError === errorCode)) {
              errorFields.push(field.name)
            }
          })
        }
      })
      if (errorFields.length) {
        error = toIntlString(`${translationBasePath}invalid_field_input_header`)
        break
      }
    default:
      let serverErrors = getErrorMessage(...args)
      if (serverErrors && serverErrors.length) {
        error = serverErrors[0]
      }
  }
  return {
    message: error,
    fields: errorFields
  }
}

const getCompleteEntryPointLink = (link) =>
  `${link}?${LOGIN_PAGE_PARAM_KEYS.CLIENT_ID}=${FRESHID_CLIENT_ID}&${LOGIN_PAGE_PARAM_KEYS.REDIRECT_URI}=${window.location.origin}`

const getUpdateSuccessMessage = (toIntlString, isEnabled, loginMethodTitle) =>
  toIntlString(
    `${SECURITY_TRANSLATION_BASE_PATH}.authentication_methods.sso_configuration.messages.success.login_method_${
      isEnabled ? 'enabled' : 'disabled'
    }_successfully`,
    {
      loginMethod: loginMethodTitle
    }
  )

const getPredefinedIdpList = () => {
  const idpList = []
  for (let ssoType in AUTHENTICATION_METHOD_TYPES[AUTHENTICATION_METHOD_NAMES.SSO]) {
    const ssoTypeObj = AUTHENTICATION_METHOD_TYPES[AUTHENTICATION_METHOD_NAMES.SSO][ssoType]
    if (ssoTypeObj.hasMultipleIdpSupport) {
      for (let idpType in SSO_IDP_TYPES) {
        idpList.push({
          idpType,
          ...SSO_IDP_TYPES[idpType],
          ssoType
        })
      }
    }
  }
  return idpList
}

const getIdpTypeFromEnum = (idpTypeEnum) => (SSO_IDP_TYPES[idpTypeEnum] ? idpTypeEnum : null)

const getEditableFieldsList = (profileFields) =>
  profileFields
    .map(({ fields }) => [...fields.filter((f) => !!f.isEditable)])
    .reduce((accumulator, currentItem) => [...accumulator, ...currentItem], [])

const constructCustomizationFieldObject = (fieldsList, restrictionLevel) => {
  if (restrictionLevel) {
    return fieldsList.reduce((prev, curr) => ({ ...prev, [curr.name]: curr.isEditable }), {})
  }
  return fieldsList.reduce(
    (prev, curr) => ({ ...prev, [curr.name]: true && curr.shouldSendToServer }),
    {}
  )
}

const getEditResult = (values, fieldListToBeSentToServer) => {
  const restriction_level = values.restriction_level

  if (!!values.restricted_fields_update) {
    const restricted_fields = Object.entries(values.restricted_fields).reduce(
      (acc, [key, val]) => (fieldListToBeSentToServer.includes(key) && !val && acc.push(key), acc),
      []
    )

    return { restriction_level: 'RESTRICTION_ALLOW_SOME', restricted_fields }
  }

  return { restriction_level: 'RESTRICTION_ALLOW_NONE' }
}

const __getIdsOnly = (items = []) =>
  items.map(({ id }) => {
    return {
      id
    }
  })

const __idsAsString = (array) => {
  return array
    .sort((a, b) => {
      return a.id > b.id ? 1 : a.id < b.id ? -1 : 0
    })
    .map((item) => item.id)
    .toString()
}

const __hasMFAUsersOrGroupsChanged = (mfaOldConfig, mfaNewConfig) => {
  const { oldUsers, oldUserGroups } = mfaOldConfig,
    { newUsers, newUserGroups } = mfaNewConfig

  // if length differs something has changed
  if (oldUsers.length !== newUsers.length || oldUserGroups.length !== newUserGroups.length) {
    return true
  }

  const oldUserIdsString = __idsAsString(oldUsers),
    newUserIdsString = __idsAsString(newUsers)
  let hasUsersOrGroupsChanged = true

  // check if length is equal but the contents has changed
  if (
    (oldUsers.length > 0 || newUsers.length > 0) &&
    oldUsers.length === newUsers.length &&
    oldUserIdsString === newUserIdsString
  ) {
    hasUsersOrGroupsChanged = false
  }

  const oldGroupsIdsString = __idsAsString(oldUserGroups),
    newGroupsIdsString = __idsAsString(newUserGroups)

  // check if length is equal but the contents has changed
  if (
    (oldUserGroups.length > 0 || newUserGroups.length > 0) &&
    oldUserGroups.length === newUserGroups.length &&
    oldGroupsIdsString === newGroupsIdsString
  ) {
    hasUsersOrGroupsChanged = false
  }

  return hasUsersOrGroupsChanged
}

const __getMfaCustomisation = (mfaFormValues) => {
  const { policy, type = TYPES.TOTP } = mfaFormValues
  let mfaCustomisation = {}
  const users = delve(mfaFormValues, `${policy}.users`, []),
    userGroups = delve(mfaFormValues, `${policy}.userGroups`, [])

  if (policy === NO_CUSTOMISATION || policy === ALL_USERS_AND_GROUPS || policy === ALL_ADMINS) {
    mfaCustomisation = {
      policy
    }
  }

  if (policy === ALL_USERS_AND_GROUPS && (users.length > 0 || userGroups.length > 0)) {
    mfaCustomisation = {
      policy: EXCLUDING,
      users: __getIdsOnly(users),
      userGroups: __getIdsOnly(userGroups)
    }
  }

  if (policy === SOME_USERS_AND_GROUPS) {
    mfaCustomisation = {
      policy: INCLUDING,
      users: __getIdsOnly(users),
      userGroups: __getIdsOnly(userGroups)
    }
  }

  return { ...mfaCustomisation, type }
}

const getMfaCustomisationConfigForServer = (initialValues, newValues) => {
  const fieldName = 'mfaCustomisation',
    {
      users: oldUsers = [],
      userGroups: oldUserGroups = [],
      policy: mfaOldPolicy,
      type: mfaOldType
    } = __getMfaCustomisation(initialValues[fieldName]),
    {
      users: newUsers = [],
      userGroups: newUserGroups = [],
      policy: mfaNewPolicy,
      type: mfaNewType
    } = __getMfaCustomisation(newValues[fieldName]),
    updatedMFACustomisation = __getMfaCustomisation(newValues[fieldName])

  if (mfaNewPolicy === INCLUDING || mfaNewPolicy === EXCLUDING) {
    // in mfaCustomisation object we have users and groups as array
    // so we check the array equality ourself
    if (__hasMFAUsersOrGroupsChanged({ oldUsers, oldUserGroups }, { newUsers, newUserGroups })) {
      return {
        hasMfaCustomisationChanged: true,
        mfaCustomisation: updatedMFACustomisation
      }
    }
  }

  if (mfaOldPolicy !== mfaNewPolicy || mfaOldType !== mfaNewType) {
    return {
      hasMfaCustomisationChanged: true,
      mfaCustomisation: updatedMFACustomisation
    }
  }

  return {
    hasMfaCustomisationChanged: false
  }
}

const getMfaCustomisationConfigForUI = (mfaConfig) => {
  const { policy, users = [], userGroups = [], type = MFA.DEFAULT_MFA_TYPE } = mfaConfig

  const updatedMFAConfig = {
    policy,
    type
  }

  if (policy === EXCLUDING && (users.length > 0 || userGroups.length > 0)) {
    updatedMFAConfig.policy = ALL_USERS_AND_GROUPS
  } else if (policy === INCLUDING) {
    updatedMFAConfig.policy = SOME_USERS_AND_GROUPS
  }

  updatedMFAConfig[updatedMFAConfig.policy] = {
    users,
    userGroups
  }

  return updatedMFAConfig
}

const getItemLabel = (toIntlString, item, isCustom) => {
  const TRANSLATION_PATH = 'security.freshworks_login.custom_password_policy'
  const customConfig = {
    min_length: '',
    exp_days: '',
    prev_password_count: '',
    character_string: '',
    day_string: '',
    password_string: ''
  }

  const normalConfig = {
    min_length: item.defaultValue || '',
    exp_days: item.defaultValue || '',
    prev_password_count: item.defaultValue || '',
    character_string: toIntlString(`${TRANSLATION_PATH}.character_string`),
    day_string: toIntlString(`${TRANSLATION_PATH}.day_string`),
    password_string: toIntlString(`${TRANSLATION_PATH}.password_string`)
  }

  return toIntlString(
    `${TRANSLATION_PATH}.items.${item.name}`,
    isCustom ? customConfig : normalConfig
  )
}

const getMoreAuthModules = (authMethodConfig = {}) => {
  const res = {
    showMore: false,
    moreAuthModuleCount: 0
  }
  const { [AUTHENTICATION_METHOD_NAMES.SHOPIFY]: shopifyModule = {} } = authMethodConfig
  if (shopifyModule.type) {
    /*
     * increasing count by 2 since we show only 3 auth modules
     * information + additional module counts
     */
    res.showMore = true
    res.moreAuthModuleCount += 2
  }
  return res
}
const getActiveSessionLocation = (location) => {
  let sessionLocation = []
  if (location.city) sessionLocation.push(location.city)
  if (location.state) sessionLocation.push(location.state)
  if (location.country) sessionLocation.push(location.country)
  return sessionLocation.length > 0 ? sessionLocation.join(', ') : '-'
}

const formatEpochTimestamp = (epochTimeStamp) => {
  const date = new Date(epochTimeStamp)
  const options = {
    month: SHORT,
    day: NUMERIC,
    year: NUMERIC,
    hour: NUMERIC,
    minute: NUMERIC,
    hour12: true
  }
  return date.toLocaleString('en-US', options)
}

export {
  SECURITY_BASE_PATH,
  SECURITY_TRANSLATION_BASE_PATH,
  SECURITY_TABS,
  SECURITY_AGENTS_STR,
  SECURITY_CONTACTS_STR,
  SECURITY_AGENTS_ROUTE_PATH,
  SECURITY_CONTACTS_ROUTE_PATH,
  ENTRY_POINT_COUNT_LIMIT,
  AUTHENTICATION_METHOD_NAMES,
  AUTHENTICATION_METHOD_TYPES,
  SSO_IDP_TYPES,
  validatePathAndRedirect,
  CUSTOM_POLICIES_ROUTE,
  CUSTOM_POLICIES_CREATE_OR_EDIT_ROUTE,
  NO_OF_PARALLEL_SESSION,
  sessionManagementAvailableEnforcementLevel,
  matchAndSetSelectedTab,
  getOrgAccountsMap,
  setHeader,
  getAllowedSecurityTabs,
  getIsEntryPointsEnabled,
  getEntryPointUrl,
  getAuthenticationMethodConfig,
  getAuthenticationMethodList,
  getServerErrorTranslations,
  getPasswordModule,
  getSSOModules,
  getPaths,
  sanitizeData,
  getSSOFieldDefaultValue,
  getNewAuthModulePayload,
  getSSOUpdateValues,
  getAllSsoFields,
  getValidationSchema,
  getAllAuthMethodWithSSODisabled,
  isAnyLoginMethodEnabled,
  isOnlyLoginMethodEnabled,
  isAnySSOEnabled,
  isThisSSOTheOnlyEnabledAuthMethod,
  isSSOConfiguredProperly,
  getErrorMessage,
  getAuthUpdateErrorObject,
  getSSODisplayNameForCreation,
  getAccountUrl,
  getOverrideAuthMethodMap,
  getCompleteEntryPointLink,
  getUpdateSuccessMessage,
  getPredefinedIdpList,
  getIDPSpecificFields,
  getIdpTypeFromEnum,
  isFreshdeskAccount,
  shouldShowPortals,
  getEditableFieldsList,
  constructCustomizationFieldObject,
  getEditResult,
  getMfaCustomisationConfigForServer,
  getMfaCustomisationConfigForUI,
  getItemLabel,
  getMoreAuthModules,
  MAX_PORTALS_SIZE,
  getActiveSessionLocation,
  formatEpochTimestamp
}
