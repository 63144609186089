import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Downshift from 'downshift'
import hoistNonReactStatics from 'hoist-non-react-statics'
import styled, { cx, css } from 'react-emotion'

import DropdownButton from './DropdownButton'
import DropdownMenu from './DropdownMenu'
import DropdownInput from './DropdownInput'

const StyledDropdownContainer = styled('div')`
  ${({ style }) => {
    return cx(
      css`
        position: relative;
        display: inline-block;
        width: 100%;
      `,
      css(style)
    )
  }}
`
export const DROPDOWN_TYPES = {
  BUTTON: 'button',
  INPUT: 'input'
}

const DownshiftContext = React.createContext()

export function withDownshift(Component) {
  function Wrapper(props, ref) {
    return (
      <DownshiftContext.Consumer>
        {({ downshift, items, filterKey, type, name }) => (
          <Component
            downshift={downshift}
            items={items}
            filterKey={filterKey}
            type={type}
            name={name}
            {...props}
            ref={ref}
          />
        )}
      </DownshiftContext.Consumer>
    )
  }

  Wrapper.displayName = `withDownshift(${Component.displayName || Component.name})`

  return hoistNonReactStatics(React.forwardRef(Wrapper), Component)
}
/**
 * Working of DropDown :
 * 1. When no item is pre-selected, on click of Input, show all values of DropDown
 * 2. When a item is selected, click on Input, show all values of Dropdown
 * 3. When a text is searched, filter and show only relevant items
 * 4. When a item is pre-selected and a junk value is typed and input is blurred, select the previous valid value
 */

const conditionalValues = (type, labelId) => {
  if (DROPDOWN_TYPES.BUTTON === type) {
    return { role: null, 'aria-labelledby': null }
  }
  return { role: 'combobox', 'aria-labelledby': `${labelId}` }
}

export default class Dropdown extends Component {
  render() {
    const {
      items,
      children,
      defaultSelectedItem,
      itemToString,
      onChange,
      type,
      filterKey,
      labelId,
      ...rest
    } = this.props

    let _itemToString

    if (typeof items[0] === 'object' && !itemToString) {
      console.warn(
        'Your items are objects. So you need to pass a function to translate each object as a string which can be shown as dropdown item labels'
      )
    } else {
      _itemToString = (item) => {
        if (typeof item !== 'object') {
          return String(item)
        } else {
          return itemToString(item)
        }
      }
    }

    return (
      <Downshift
        itemToString={_itemToString}
        initialSelectedItem={defaultSelectedItem}
        onChange={onChange}
        {...rest}>
        {(downshift) => (
          <StyledDropdownContainer
            {...downshift.getRootProps({ refKey: 'innerRef' })}
            {...conditionalValues(type, labelId)}
            onScroll={(e) => e.stopPropagation()}>
            <DownshiftContext.Provider
              value={{ downshift, items, filterKey, type, name: rest.name }}>
              {children ? (
                children
              ) : (
                <Fragment>
                  {DROPDOWN_TYPES.BUTTON === type && <DropdownButton itemToString={itemToString} />}
                  {DROPDOWN_TYPES.INPUT === type && <DropdownInput hasError />}
                  <DropdownMenu />
                </Fragment>
              )}
            </DownshiftContext.Provider>
          </StyledDropdownContainer>
        )}
      </Downshift>
    )
  }
}

Dropdown.defaultProps = {
  itemToString: (a) => a,
  type: DROPDOWN_TYPES.BUTTON,
  hasError: false,
  labelId: ''
}

Dropdown.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]).isRequired
  ).isRequired,
  defaultSelectedItem: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  itemToString: PropTypes.func,
  /**
   * decides whether the DropDown should be a button or a searchable <input>
   */
  type: PropTypes.oneOf(['button', 'input']),

  // will be needed only if the items are objects
  // needed if the "type" of the Dropdown is input,
  // the filterKey object key from which the filtering should happen
  filterKey: PropTypes.string,
  name: PropTypes.string,
  //When we want to mark a dropdown as error field in a form
  hasError: PropTypes.bool
}
