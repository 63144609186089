import React from 'react'
import Logo from '../Logo'
import { Link } from 'react-router-dom'

export default function FreshworksLogo({ homeRoute }) {
  return (
    <div style={{ width: '32px', height: '32px', margin: '16px auto' }}>
      <Link to={homeRoute}>
        <Logo isIcon size="large" />
      </Link>
    </div>
  )
}
