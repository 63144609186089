import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { DataManagerContext, REQUEST_STATE } from './DataManager'

export default class Transition extends Component {
  render() {
    const { children = null } = this.props

    return (
      <DataManagerContext.Consumer>
        {({ requestState }) => {
          if (requestState === REQUEST_STATE.inProgress) {
            return children
          } else {
            return null
          }
        }}
      </DataManagerContext.Consumer>
    )
  }
}

Transition.propTypes = {
  children: PropTypes.node.isRequired
}
