import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Box } from '../Grid'
import { withRouter } from 'react-router'
import IntlContext from '../IntlContext'
import Icon from '../Icon'
import { StyledButtonWithBackground, btnWithLogoCss, btnWithIconStyle } from './ButtonCommonStyle'
import { addQueryParams } from '../../utils'

const getGoogleOAuthUrl = (redirectUrl, queryParams) => {
  const url = new URL(redirectUrl)
  // Replace *.api.freshworks.com origin with the current domain's origin
  const replacedUrl = url.href.replace(url.origin, window.location.origin)
  return `${replacedUrl}${queryParams}`
}

const buttonColor = (palette) => palette.a[700]

const BtnTextBoxStyle = {
  padding: '0px 40px 0px 12px',
  fontSize: '14px'
}

const StyledGoogleButton = StyledButtonWithBackground(buttonColor, 'StyleGoogleButton')

function GoogleSignIn({
  location: { search },
  redirectUrl,
  translationPath,
  queryParams,
  ...props
}) {
  return redirectUrl === '' ? null : (
    <IntlContext.Consumer>
      {({ toIntlString }) => (
        <StyledGoogleButton
          {...props}
          onClick={() => {
            const composedSearch = queryParams ? `?${addQueryParams(search, queryParams)}` : search
            window.location = getGoogleOAuthUrl(redirectUrl, composedSearch)
          }}
          size="large"
          data-testid="signin-with-google">
          <Flex justifyContent="center" alignItems="center" css={btnWithLogoCss}>
            <Box css={btnWithIconStyle}>
              <Icon width={24} name="google_logo" />
            </Box>
          </Flex>
          <Box css={BtnTextBoxStyle} width={1} color="white">
            {toIntlString(translationPath, {
              provider: toIntlString('common_login.button.provider.google')
            })}
          </Box>
        </StyledGoogleButton>
      )}
    </IntlContext.Consumer>
  )
}

GoogleSignIn.propTypes = {
  redirectUrl: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  queryParams: PropTypes.object
}

export default withRouter(GoogleSignIn)
