import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Box } from './../Grid'
import { css } from 'emotion'

import IntlContext from '../IntlContext'

import { IMAGE_CDN_URL } from '../../config'
import { getAPIURL, RETRIEVE_USER_PROFILE_IMAGE } from '../../apis'
import styled from 'react-emotion'

const IMAGE_DIMENSIONS = {
  xlarge: 112,
  large: 72,
  medium: 50,
  small: 42,
  tiny: 34
}

const EDIT_IMAGE_DIMENSIONS = {
  xlarge: 30,
  large: 25,
  medium: 15,
  small: 10,
  tiny: 7
}

const PROFILE_PIC_MARGIN = {
  xlarge: 5,
  large: 5,
  medium: 2,
  small: 2,
  tiny: 1
}

const ProfilePicContainer = styled('div', {
  label: 'ProfilePicContainer'
})`
  position: relative;
  display: block;
  width: ${({ size }) => IMAGE_DIMENSIONS[size]}px;
  height: ${({ size }) => IMAGE_DIMENSIONS[size]}px;
  text-align: center;

  &:hover div {
    display: inherit;
  }
`

const EditProfile = function ({ className, handleEditClick }) {
  return (
    <IntlContext.Consumer>
      {({ toIntlString }) => {
        return (
          <div className={className} onClick={handleEditClick}>
            <img
              alt={toIntlString('my_profile.profile_image_edit')}
              src={IMAGE_CDN_URL`edit_profile.svg`}
            />
          </div>
        )
      }}
    </IntlContext.Consumer>
  )
}

const EditProfileStyled = styled(EditProfile, {
  label: 'EditProfile'
})`
  display: none;
  position: absolute;
  top: 0px;
  width: calc(100% - 2 * ${({ size }) => PROFILE_PIC_MARGIN[size]}px);
  height: calc(100% - 2 * ${({ size }) => PROFILE_PIC_MARGIN[size]}px);
  background-color: rgba(64, 63, 63, 0.5);
  cursor: pointer;
  border-radius: 50%;
  margin: ${({ size }) => PROFILE_PIC_MARGIN[size]}px;
  img {
    position: absolute;
    width: ${({ size }) => EDIT_IMAGE_DIMENSIONS[size]}px;
    height: ${({ size }) => EDIT_IMAGE_DIMENSIONS[size]}px;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
  }
`

const UserProfilePic = styled(Box, { label: 'UserProfilePic' })`
  height: calc(100% - 2 * ${({ size }) => PROFILE_PIC_MARGIN[size]}px);
  width: calc(100% - 2 * ${({ size }) => PROFILE_PIC_MARGIN[size]}px);
  border-radius: 50%;
  margin: ${({ size }) => PROFILE_PIC_MARGIN[size]}px;
  background: ${({ theme: { colors } }) => colors.background.disabled};
  position: absolute;
`

const UserProfilePicContainer = styled(Box, { label: 'UserProfilePicContainer' })`
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background: ${({ isActive }) => (isActive ? 'white' : 'rgba(207,215,223,0.5)')};

  :focus {
    outline: none;
    background: ${({ theme: { colors } }) => colors.pseudo.focus};
    border-radius: 50%;
  }
`

const ImageStyles = css`
  width: 100%;
  height: 100%;
  border-radius: 50%;

  &:-moz-loading {
    visibility: hidden;
  }
`

class ProfilePicture extends PureComponent {
  render() {
    const { onChangePhotoClicked, size, canEditPicture, isActive, ...rest } = this.props
    return (
      <IntlContext.Consumer>
        {({ toIntlString }) => {
          return (
            <ProfilePicContainer size={size} {...rest}>
              <UserProfilePicContainer isActive={isActive}>
                <UserProfilePic size={size}>
                  <img
                    alt={toIntlString('my_profile.profile_image')}
                    src={`${getAPIURL(RETRIEVE_USER_PROFILE_IMAGE)}&d=${Date.now()}`}
                    className={ImageStyles}
                  />
                </UserProfilePic>
              </UserProfilePicContainer>
              {canEditPicture && (
                <EditProfileStyled handleEditClick={onChangePhotoClicked} size={size} />
              )}
            </ProfilePicContainer>
          )
        }}
      </IntlContext.Consumer>
    )
  }
}

ProfilePicture.defaultProps = {
  size: 'xlarge',
  isActive: false
}

ProfilePicture.propTypes = {
  /* dimensions of the profile picture */
  size: PropTypes.oneOf(['xlarge', 'large', 'medium', 'small', 'tiny']),

  /* highlight when ProfilePicture is focused */
  isActive: PropTypes.bool,

  /* required if canEditPicture is true */
  onChangePhotoClicked: PropTypes.func,

  /* provides options on hover of profile picture for editing  */
  canEditPicture: PropTypes.bool
}

export default ProfilePicture
