import React from 'react'
import Transition from 'react-transition-group/Transition'

const duration = 400

const defaultStyle = {
  transition: `opacity ${duration}ms ease-out`,
  opacity: 0,
  height: '100%'
}

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exited: { opacity: 0 }
}

const Fade = ({ in: inProp, children, customStyles = {} }) => (
  <Transition in={inProp} timeout={duration} unmountOnExit>
    {(state) => (
      <div
        style={{
          ...defaultStyle,
          ...customStyles,
          ...transitionStyles[state]
        }}>
        {children}
      </div>
    )}
  </Transition>
)

export default Fade
