import React from 'react'
import { getLogoutUrlWithPostLogoutRedirectUrl } from '../../apis'
import { H5, LinkText, H3 } from '../../components/Typography'
import PropTypes from 'prop-types'
import { Flex, Box } from '../../components/Grid'
import Image from '../../components/Image'
import colors from '../../styles/themes/freshworks/colors'
import IntlContext from '../../components/IntlContext'
import Logo from '../../components/Logo'
import { isRenderedUnderPrefix } from '../../utils/constants'

const textAlignCenter = {
  textAlign: 'center'
}

const underline = { textDecoration: 'underline' }

const urlWithPageTitleMapping = {
  'set-password': 'set_password',
  invite: 'user_activation',
  'reset-password': 'common_login.reset_password'
}

const logoutUrl = getLogoutUrlWithPostLogoutRedirectUrl()

const SessionExists = ({ userEmail }) => {
  // because this page has different background even in previous version
  window.document.body.style.backgroundColor = colors.body.background.secondary

  // the better way was to pass this key from App.js down to this component
  // this involves prop drilling for 3 components
  // so did it this way using `urlWithPageTitleMapping`, this is the best way
  //in case we are using a prefix first element will be prefix, so we need to get second element
  const windowUrl = window.location.pathname.split('/')[isRenderedUnderPrefix ? 2 : 1],
    pageTitleTranslationKey = urlWithPageTitleMapping[windowUrl]

  return (
    <IntlContext.Consumer>
      {({ toIntlString }) => (
        <Flex justifyContent="center">
          <Box mt={45}>
            <Flex>
              <Image alt="Cloud" src={'error_cloud.svg'} width={210} />
              <Flex alignItems="flex-end">
                <Logo size="large" />
              </Flex>
            </Flex>

            <Box mt={60} css={textAlignCenter}>
              <H5>
                {toIntlString('session_exists.already_logged_in', {
                  email: userEmail
                })}{' '}
                <LinkText>{userEmail}</LinkText>
              </H5>
              <Box mt={2}>
                <H3>
                  {toIntlString('session_exists.please')}{' '}
                  <a href={logoutUrl} style={underline}>
                    <LinkText>{toIntlString('session_exists.logout')}</LinkText>
                  </a>{' '}
                  {toIntlString('session_exists.from_existing_session', {
                    page: toIntlString(`${pageTitleTranslationKey}.page_title`)
                  })}
                </H3>
              </Box>
            </Box>
          </Box>
        </Flex>
      )}
    </IntlContext.Consumer>
  )
}

SessionExists.propTypes = {
  userEmail: PropTypes.string
}

export default React.memo(SessionExists)
