import moment from 'moment'

import CONST from './constants'
import { logger } from './utility'

const isValidMillis = (millis) => new Date(millis).getTime() > 0

// utility to compare and check if a date
// is before another date
const isBeforeDay = (aDate, anotherDate) => {
  if (!moment.isMoment(aDate) || !moment.isMoment(anotherDate)) return false

  const aYear = aDate.year()
  const aMonth = aDate.month()

  const anotherYear = anotherDate.year()
  const anotherMonth = anotherDate.month()

  const isSameYear = aYear === anotherYear
  const isSameMonth = aMonth === anotherMonth

  if (isSameYear && isSameMonth) return aDate.date() <= anotherDate.date()
  if (isSameYear) return aMonth < anotherMonth
  return aYear < anotherYear
}
const isAfterDay = (aDate, anotherDate) => {
  if (!moment.isMoment(aDate) || !moment.isMoment(anotherDate)) return false
  return aDate.unix() >= anotherDate.unix()
}

// utility to identify if a date is a future date
const isInclusivelyAfterDay = (aDate, anotherDate) => {
  if (!moment.isMoment(aDate) || !moment.isMoment(anotherDate)) return false
  return isBeforeDay(aDate, anotherDate)
}

const isInclusivelyBeforeDay = (aDate, anotherDate) => {
  if (!moment.isMoment(aDate) || !moment.isMoment(anotherDate)) return false
  return isAfterDay(aDate, anotherDate)
}

const getInternationalizedDate = (millis, locale = CONST.DEFAULT_LANGUAGE) => {
  if (!isValidMillis(millis)) {
   logger.error('Invalid date')
    return
  }
  moment.locale(locale)
  return moment(millis).format('ll')
}

const isToday = (millis) => {
  if (!moment.isMoment(millis)) {
    logger.error('Invalid date')
    return
  }
  return moment(millis).isSame(moment(), 'day')
}

const getCurrentMillis = () => {
  const now = moment()
  return now.valueOf()
}

//! To restrict user from selecting more than 90days
const isWithinExpectedDaysRange = (startDate, selectedDateRange,numberOfDays) => {
  const maxSelectableDate = startDate.clone().add(numberOfDays, 'days')
  return selectedDateRange.isSameOrBefore(maxSelectableDate, 'day')
}

export default {
  isBeforeDay,
  isInclusivelyAfterDay,
  isInclusivelyBeforeDay,
  getInternationalizedDate,
  isToday,
  getCurrentMillis,
  isWithinExpectedDaysRange
}
