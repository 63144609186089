import { CONST } from '../../utils'
import { FRESHID_CLIENT_ID } from '../../config'

export const LOGIN_PAGE_PARAM_KEYS = {
  CLIENT_ID: 'client_id',
  REDIRECT_URI: 'redirect_uri',
  AUTH: 'auth',
  UNIFIED_URL: 'uf',
  PRODUCT_REDIRECT_URI: 'product_redirect_uri'
}

export const getRequiredQueryParams = (isUnifiedUrlContext) => {
  const requiredParams = [LOGIN_PAGE_PARAM_KEYS.REDIRECT_URI]
  // client_id is not a required query param in case of unified url
  if (!isUnifiedUrlContext) {
    requiredParams.push(LOGIN_PAGE_PARAM_KEYS.CLIENT_ID)
  }
  return requiredParams
}

export const getDefaultQueryParams = (isUnifiedUrlContext) => {
  const params = {
    [LOGIN_PAGE_PARAM_KEYS.REDIRECT_URI]: CONST.DEFAULT_REDIRECT_URL,
    [LOGIN_PAGE_PARAM_KEYS.AUTH]: 'no'
  }
  // client_id is not a default query param in case of unified url
  if (!isUnifiedUrlContext) {
    params[LOGIN_PAGE_PARAM_KEYS.CLIENT_ID] = FRESHID_CLIENT_ID
  }
  return params
}
