import React from 'react'
import PropTypes from 'prop-types'
import styled from 'react-emotion'
import { IMAGE_CDN_URL } from '../../config'
import { styledWithRTL } from './../../utils/styles'
import { useLocaleState } from './../../utils/LanguageProvider/LanguageProvider'

const ICONS_NOT_TO_ROTATE_ON_RTL = [
  'info',
  'google_logo',
  'tick',
  'facebook',
  'linkedin',
  'twitter',
  'link'
]
const getWidth = (_width) => (typeof _width === 'string' ? _width : `${_width}px`)
const IconContainer = styled('span')`
  ${({ rotateDeg, _width }) => `
    display: inline-flex;
    width: ${getWidth(_width)};
    height: ${getWidth(_width)};

    img {
      ${rotateDeg ? `transform: rotate(${rotateDeg}deg)` : ''};
      width: ${getWidth(_width)};
      height: ${getWidth(_width)};
    }
  `}
`

const RTLStyledImage = styledWithRTL('img')`
  width: 100%;
  ${({ shouldRotateOnRTL, isRTLLanguage }) => {
    return shouldRotateOnRTL && isRTLLanguage ? `transform: scaleX(-1);` : ``
  }}
`

const shouldRotateIcon = (shouldRotateOnRTL, name) =>
  ICONS_NOT_TO_ROTATE_ON_RTL.indexOf(name) === -1 && shouldRotateOnRTL

const getConditionalProps = (isInformativeIcon, name, altText) =>
  isInformativeIcon ? { alt: `${!!altText ? altText : `${name}-icon`}` } : { role: 'presentation' }

export default function Icon({
  width,
  name,
  extension,
  rotateDeg,
  shouldRotateOnRTL,
  isInformativeIcon,
  altText = '',
  ...rest
}) {
  const [{ isRTLLanguage }] = useLocaleState()
  return (
    <IconContainer _width={width} rotateDeg={rotateDeg} {...rest}>
      <RTLStyledImage
        shouldRotateOnRTL={shouldRotateIcon(shouldRotateOnRTL, name)}
        isRTLLanguage={isRTLLanguage}
        {...getConditionalProps(isInformativeIcon, name, altText)}
        src={IMAGE_CDN_URL`${name}.${extension}`}
      />
    </IconContainer>
  )
}

Icon.propTypes = {
  width: function (props, propName, componentName) {
    const suppliedValue = props[propName]
    const suppliedValueType = typeof suppliedValue
    if (!suppliedValue || (suppliedValueType !== 'number' && suppliedValueType !== 'string')) {
      return new Error(
        `Invalid prop ${propName} supplied to ${componentName}. Expecting a number or string with value 'auto' or percentage width (eg. '50%'). But got the following value ${suppliedValue}, of type ${suppliedValueType}`
      )
    }
  },
  extension: PropTypes.string,
  shouldRotateOnRTL: PropTypes.bool,
  /**
   * This flag is used to determine image type Informative or Decorative
   * Informative image should be conveyed to screen reader users
   * Decorative image should not be conveyed to screen reader users
   * According to WCAG Standards
   * https://www.w3.org/TR/wai-aria-practices-1.2/#presentation_role
   */
  isInformativeIcon: PropTypes.bool,
  /**
   * the name should correspond the name of the image file
   * located inside `assets/images`
   */
  name: PropTypes.string.isRequired
}

Icon.defaultProps = {
  width: 18,
  extension: 'svg',
  shouldRotateOnRTL: true,
  isInformativeIcon: false
}
