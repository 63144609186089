export default {
  fontFamily: `-apple-system, ".SFNSText-Regular", "San Francisco", BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", "Lucida Grande", Arial, sans-serif`,

  headings: {
    h1: {
      fontSize: '44px',
      lineHeight: `${44 * 1.5}px`,
      margin: '0px',
      letterSpacing: '0.5px'
    },
    h2: {
      fontSize: '32px',
      lineHeight: `${32 * 1.5}px`,
      margin: '0px',
      letterSpacing: '0.5px'
    },
    h3: {
      fontSize: '24px',
      lineHeight: `${24 * 1.5}px`,
      margin: '0px',
      letterSpacing: '0.5px'
    },
    h4: {
      fontSize: '18px',
      lineHeight: `${18 * 1.3}px`,
      margin: '0px'
    },
    h5: {
      fontSize: '16px',
      lineHeight: `${16 * 1.3}px`,
      margin: '0px'
    }
  },

  text: {
    regular: {
      fontWeight: 400
    },
    semiBold: {
      fontWeight: 500
    },
    bold: {
      fontWeight: 600
    }
  },

  body: {
    regular: {
      fontSize: '14px',
      lineHeight: `${14 * 1.5}px`,
      margin: '0px'
    },
    regularBold: {
      fontSize: '14px',
      fontWeight: 'bold',
      lineHeight: `${14 * 1.5}px`,
      margin: '0px'
    },
    secondary: {
      fontSize: '12px'
    },
    secondaryBold: {
      fontSize: '12px',
      lineHeight: `${12 * 1.5}px`,
      fontWeight: 600
    },
    secondary12px: {
      fontSize: '12px',
      lineHeight: '17px'
    },
    secondary12pxBold: {
      fontSize: '12px',
      fontWeight: 600
    },
    secondary10pxBold: {
      fontSize: '10px',
      fontWeight: 600,
      lineHeight: '18px'
    },
    SecondaryText9px: {
      fontSize: '9px',
      lineHeight: '11px',
      fontWeight: 500
    },
    dropdown: {
      fontSize: '13px',
      lineHeight: '30px'
    },
    label: {
      fontSize: '12px',
      lineHeight: '19px',
      fontWeight: 600
    },
    anchor: {
      light: {
        lineHeight: '17px'
      },
      bold: {
        fontWeight: 600,
        lineHeight: '15px'
      },
      fontSize: {
        small: '12px',
        medium: '14px',
        large: '16px'
      }
    },
    tagText: {
      fontSize: '12px',
      lineHeight: '14px',
      fontWeight: '500',
      letterSpacing: '0.2px'
    }
  },

  input: {
    text: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: '600'
    },
    textarea: {
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: '600'
    },
    disabled: {
      fontWeight: 'bold'
    },
    label: {
      fontSize: '12px',
      lineHeight: '20px',
      fontWeight: 600
    },
    error: {
      fontSize: '12px',
      lineHeight: '17px'
    },
    password: {
      letterSpacing: '1px'
    }
  }
}
