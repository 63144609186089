import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Box } from '../Grid'
import { withRouter } from 'react-router'
import { useIntl } from '../IntlContext'
import Icon from '../Icon'
import { StyledButtonWithBackground, btnWithLogoCss, btnWithIconStyle } from './ButtonCommonStyle'

const buttonColor = (palette) => palette['lightBlue']

const BtnTextBoxStyle = {
  padding: '0px 40px 0px 12px',
  fontSize: '14px'
}

const StyledShopifyButton = StyledButtonWithBackground(buttonColor, 'StyleShopifyButton')

function ShopifySignIn({
  location: { search = '' },
  config: { uiRedirectUri },
  translationPath,
  ...props
}) {
  const { toIntlString } = useIntl()
  return (
    <StyledShopifyButton
      {...props}
      onClick={() => {
        window.location.assign(uiRedirectUri + search)
      }}
      size="large"
      data-testid="signin-with-shopify">
      <Flex justifyContent="center" alignItems="center" css={btnWithLogoCss}>
        <Box css={btnWithIconStyle}>
          <Icon width={24} name="shopify_logo" />
        </Box>
      </Flex>
      <Box css={BtnTextBoxStyle} width={1} color="white">
        {toIntlString(translationPath, {
          provider: toIntlString('common_login.button.provider.shopify')
        })}
      </Box>
    </StyledShopifyButton>
  )
}

ShopifySignIn.propTypes = {
  translationPath: PropTypes.string.isRequired,
  queryParams: PropTypes.object
}

export default withRouter(ShopifySignIn)
