import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Popup from 'reactjs-popup'
import { withTheme } from 'emotion-theming'

import { PrimaryText } from '../Typography'
import { styledWithRTL } from '../../utils/styles'

const TOOLTIP_THEME = {
  DARK: 'dark',
  LIGHT: 'light'
}

const SUPPORTED_TOOLTIP_POSITIONS = {
  TOP: {
    LEFT: 'top left',
    RIGHT: 'top right',
    CENTER: 'top center'
  },
  BOTTOM: {
    RIGHT: 'bottom right',
    LEFT: 'bottom left',
    CENTER: 'bottom center'
  },
  RIGHT: {
    CENTER: 'right center'
  },
  LEFT: {
    CENTER: 'left center'
  },
  CENTER: {
    CENTER: 'center center'
  }
}
const DEFAULT_MAX_WIDTH = '600px'

const DEFAULT_TOOLTIP_POSITION = SUPPORTED_TOOLTIP_POSITIONS.TOP.CENTER

const baseContentStyle = (
  {
    colors: {
      components: { tooltip }
    }
  },
  isLight
) => ({
  background: `${tooltip[isLight ? TOOLTIP_THEME.LIGHT : TOOLTIP_THEME.DARK].backgroundColor}`,
  width: 'auto',
  padding: '2px 8px',
  border: `1px solid ${tooltip[isLight ? TOOLTIP_THEME.LIGHT : TOOLTIP_THEME.DARK].borderColor}`,
  borderRadius: '4px',
  boxShadow: `${tooltip[isLight ? TOOLTIP_THEME.LIGHT : TOOLTIP_THEME.DARK].boxShadow.content}`
})

const baseArrowStyle = (
  {
    colors: {
      components: { tooltip }
    }
  },
  isLight
) => ({
  background: `${tooltip[isLight ? TOOLTIP_THEME.LIGHT : TOOLTIP_THEME.DARK].backgroundColor}`,
  border: 'none',
  boxShadow: `${tooltip[isLight ? TOOLTIP_THEME.LIGHT : TOOLTIP_THEME.DARK].boxShadow.arrow}`
})

const StyledTooltipText = styledWithRTL(PrimaryText, {
  label: 'StyledTooltipText'
})`

  font-size: 12px;
  white-space: pre-wrap;
  max-width:  ${({ maxWidth }) => maxWidth};
  text-align: ${({ textAlign }) => textAlign};
  overflow-wrap: break-word;
`

class TooltipText extends Component {
  render() {
    let props = this.props
    const {
      on,
      open,
      contentStyle,
      arrowStyle,
      tooltipText,
      forceDisable,
      textAlign,
      light,
      theme,
      children,
      maxWidth = DEFAULT_MAX_WIDTH,
      ...rest
    } = props
    if ((open && on) !== undefined) {
      props = { open, ...rest }
    }
    return forceDisable ? (
      children
    ) : (
      <Popup
        contentStyle={{ ...baseContentStyle(theme, light), ...contentStyle }}
        arrowStyle={{ ...baseArrowStyle(theme, light), ...arrowStyle }}
        trigger={children}
        {...props}>
        <StyledTooltipText
          textType={light ? 'primary' : 'light'}
          textAlign={textAlign}
          maxWidth={maxWidth}>
          {tooltipText}
        </StyledTooltipText>
      </Popup>
    )
  }
}

export default withTheme(TooltipText)

export { SUPPORTED_TOOLTIP_POSITIONS, DEFAULT_TOOLTIP_POSITION }

TooltipText.defaultProps = {
  on: 'hover',
  position: DEFAULT_TOOLTIP_POSITION,
  forceDisable: false,
  light: false,
  textAlign: 'center'
}

TooltipText.propTypes = {
  /**
   * Action that triggers the tooltip
   */
  on: PropTypes.oneOf(['click', 'hover', 'focus']),
  /**
   * Bool to switch between displaying and hiding tooltip. Takes precedence over `on` prop
   */
  open: PropTypes.bool,
  /**
   * Position of the tooltip with respect to the trigger element
   * we have derived this from SUPPORTED_TOOLTIP_POSITIONS
   */
  position: PropTypes.oneOf(
    [].concat(
      ...Object.keys(SUPPORTED_TOOLTIP_POSITIONS).map((topLevel) =>
        Object.values(SUPPORTED_TOOLTIP_POSITIONS[topLevel])
      )
    )
  ),
  /**
   * Override tooltip content style
   */
  contentStyle: PropTypes.object,
  /**
   * Override tooltip arrow style
   */
  arrowStyle: PropTypes.object,
  /**
   * Text to display when the tooltip is triggered
   */
  tooltipText: PropTypes.string.isRequired,
  /**
   * bool to conditionally enable/disable tooltip
   */
  forceDisable: PropTypes.bool,
  /**
   * boolean to set light/dark theme of tooltip
   */
  light: PropTypes.bool,
  /**
   * aligns the text inside the tooltip
   */
  textAlign: PropTypes.oneOf(['left', 'right', 'center', 'justify']),
  /**
   * Custom max width of a tooltip
   */
  maxWidth: PropTypes.number
}
