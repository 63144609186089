import { Container } from 'unstated'
import { ArrayUtils } from './../utils'

class AppStore extends Container {
  state = {
    productsMapById: null
  }
  productList = []

  setProductList = (productList) => {
    this.productList = productList
    this.setState({
      productsMapById: ArrayUtils.mapByKey(productList, 'id')
    })
  }

  getProductList = () => {
    return this.productList
  }

  getProductById = (id) => {
    return this.state.productsMapById ? this.state.productsMapById.get(id) : null
  }
}

export default AppStore
