import CONST from '../../../utils/constants'

const {
  FORM_FIELD_CONSTANTS: { PASSWORD_LENGTH },
  PASSWORD_POLICY: { VALIDATED_IN }
} = CONST

export default {
  // this value (PASSWORD: 'TRUE') is dummy for now (i.e. currently we have Basic password settings default checked,
  // once we have password policy built in we need to have templates for these too)
  // PASSWORD: 'TRUE',
  PASSWORD: {
    enforcement_level: 'immediate',
    config: [
      {
        name: 'size',
        defaultValue: 8,
        minValue: 8,
        maxValue: 32,
        level: 0,
        defaultEnabled: true,
        translation: 'character_string',
        validatedIn: VALIDATED_IN.CLIENT,
        get values() {
          let val = []
          for (let i = this.minValue; i <= this.maxValue; i++) {
            val.push(i)
          }
          return val
        }
      },
      {
        name: 'noPersonalInfo',
        level: 0,
        validatedIn: VALIDATED_IN.SERVER,
        //Since backend is not sending noPersonalInfo property, it is by default enabled
        value: 1,
        defaultEnabled: true
      },
      {
        name: 'passwordExpiryDays',
        defaultValue: 30,
        minValue: 30,
        maxValue: 180,
        level: 1,
        defaultEnabled: false,
        translation: 'day_string',
        get values() {
          let val = []
          for (let i = this.minValue; i <= this.maxValue; i += 30) {
            val.push(i)
          }
          return val
        },
        containerType: 'Box'
      },
      {
        name: 'historyCount',
        defaultValue: 3,
        minValue: 1,
        maxValue: 5,
        level: 1,
        defaultEnabled: false,
        translation: 'password_string',
        validatedIn: VALIDATED_IN.SERVER,
        get values() {
          let val = []
          for (let i = this.minValue; i <= this.maxValue; i++) {
            val.push(i)
          }
          return val
        },
        containerType: 'Box'
      },
      {
        name: 'numericCharactersCount',
        level: 2,
        validatedIn: VALIDATED_IN.CLIENT,
        defaultEnabled: false
      },
      {
        name: 'mixedCaseCharactersCount',
        level: 2,
        validatedIn: VALIDATED_IN.CLIENT,
        defaultEnabled: false
      },
      {
        name: 'specialCharactersCount',
        level: 2,
        validatedIn: VALIDATED_IN.CLIENT,
        defaultEnabled: false
      }
    ]
  },
  MFA: {
    AVAILABLE_CUSTOMISATION: {
      ALL_USERS_AND_GROUPS: 'ALL_USERS_AND_GROUPS',
      ALL_ADMINS: 'ALL_ADMINS',
      SOME_USERS_AND_GROUPS: 'SOME_USERS_AND_GROUPS'
    },
    NO_CUSTOMISATION: 'NO_CUSTOMISATION',
    CUSTOMISATION: 'CUSTOMISATION',
    INCLUDING: 'INCLUDING',
    EXCLUDING: 'EXCLUDING',
    TYPES: {
      TOTP: 'TOTP',
      EMAIL: 'EMAIL'
    }
  },
  yupPasswordValidation: {
    size: {
      type: 'min',
      paramValue: PASSWORD_LENGTH.MIN
    },
    numericCharactersCount: {
      type: 'matches',
      paramValue: /(?=.*[a-zA-Z])(?=.*[0-9])/
    },
    mixedCaseCharactersCount: {
      type: 'matches',
      paramValue: /(?=.*[a-z])(?=.*[A-Z])/
    },
    specialCharactersCount: {
      type: 'matches',
      paramValue: /[!"#$%&'()*+,-./:;<=>?@[\]^_\`\\{|}~]/
    }
  },
  availablePasswordPolicyLevel: ['LOW', 'MEDIUM', 'HIGH', 'CUSTOM'],
  availableEnforcementLevel: ['IMMEDIATE', 'NEXT_LOGIN', 'PASSWORD_CHANGE'],
  ENTRY_POINTS: {
    MAX_ALLOWED_ENTRY_POINTS: 5,
    SERVICE: {
      GET_LOGIN_METHODS: 'GET_LOGIN_METHODS',
      GET_ENTRY_POINT_BY_ID: 'GET_ENTRY_POINT_BY_ID',
      CREATE_ENTRY_POINT: 'CREATE_ENTRY_POINT',
      DELETE_ENTRY_POINT_BY_ID: 'DELETE_ENTRY_POINT_BY_ID'
    },
    AUTH_METHOD_CONTEXT: {
      DEFAULT: 'DEFAULT',
      ENTRY_POINTS: 'ENTRY_POINTS'
    },
    USER_TYPES: {
      AGENT: 'AGENT',
      CONTACT: 'CONTACT'
    },
    ACCOUNT_TYPES: {
      ACCOUNTS: 'ACCOUNTS',
      PORTALS: 'PORTALS'
    }
  }
}
