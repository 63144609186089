import React, { useState, createContext } from 'react'
import { addLocaleData } from 'react-intl'
import en from 'react-intl/locale-data/en'

import defaultLangTranslation from '../../default_translations/en-us.json'
import {
  flattenMessages,
  setUserLanguageToLocalStorage,
  changeOmnibarLanguage,
  isRTLLanguage,
  updateDocumentDir
} from '../'
import { DEFAULT_LANGUAGE } from '../../config'
import localeFetch, { LOCALE_FETCH_ERRORS } from './localeFetch'

const initialLocaleConfig = {
  locale: DEFAULT_LANGUAGE,
  messages: defaultLangTranslation,
  hasLocaleChangedOnTheFly: false,
  forceDefaultLang: false,
  isRTLLanguage: false
}

const LocaleContext = createContext([initialLocaleConfig])

export default function LanguageProvider({ children }) {
  const [localeConfig, setLocale] = useState(initialLocaleConfig)

  const __setToLocalStorageAndUpdateState = (localeConfig) => {
    // change the omnibar language before updating language provider
    changeOmnibarLanguage(localeConfig.locale)

    // every time update the locale to local storage
    setUserLanguageToLocalStorage(localeConfig.locale)

    // update if the language is RTL language
    const isRTL = isRTLLanguage(localeConfig.locale)
    setLocale({ ...localeConfig, isRTLLanguage: isRTL })
    updateDocumentDir(isRTL)
  }

  const __computeAndSetLocale = (
    localeToBeChanged = DEFAULT_LANGUAGE,
    hasLocaleChangedOnTheFly = true,
    forceDefaultLang = false
  ) => {
    const defaultLocaleConfig = {
      locale: DEFAULT_LANGUAGE,
      messages: flattenMessages(defaultLangTranslation),
      hasLocaleChangedOnTheFly,
      forceDefaultLang
    }

    if (DEFAULT_LANGUAGE === localeToBeChanged) {
      addLocaleData(en)
      __setToLocalStorageAndUpdateState(defaultLocaleConfig)
      return
    }

    // fetch the local to be added for react-intl and json translations
    localeFetch(localeToBeChanged)
      .then(([intlLocaleData, messages]) => {
        addLocaleData(intlLocaleData)
        __setToLocalStorageAndUpdateState({
          locale: localeToBeChanged,
          messages,
          hasLocaleChangedOnTheFly
        })
      })
      .catch((error) => {
        // localeFetch rejects if multiple requests are fired too!
        // so explicitly checking for `NETWORK_ERROR`
        if (error.errorType === LOCALE_FETCH_ERRORS.NETWORK_ERROR) {
          window.Sentry && window.Sentry.captureException(new Error(error.reason))
          addLocaleData(en)
          __setToLocalStorageAndUpdateState({
            ...defaultLocaleConfig,
            forceDefaultLang: true
          })
        }
      })
  }

  return (
    <LocaleContext.Provider value={[localeConfig, __computeAndSetLocale]}>
      {children({
        locale: localeConfig.locale,
        messages: localeConfig.messages
      })}
    </LocaleContext.Provider>
  )
}

export const useLocaleState = () => React.useContext(LocaleContext)
