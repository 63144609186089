import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'

import Button from './Button'
import { addQueryParams } from '../../utils'

function CustomAuthSignInButton({
  location: { search },
  redirectUrl,
  displayName,
  queryParams,
  children,
  ...props
}) {
  return (
    <Button
      size="large"
      type="secondary"
      {...props}
      onClick={() => {
        const composedSearch = queryParams ? `?${addQueryParams(search, queryParams)}` : search
        window.location = `${redirectUrl}${composedSearch}`
      }}
      data-testid="sign_in_with_sso">
      {displayName && displayName.length > 0 ? displayName : children}
    </Button>
  )
}

CustomAuthSignInButton.defaultProps = {
  displayName: ''
}

CustomAuthSignInButton.propTypes = {
  /**
   * text to be displayed for `Sign in with SSO` button
   */
  displayName: PropTypes.string,
  redirectUrl: PropTypes.string.isRequired,
  queryParams: PropTypes.object
}

export default withRouter(CustomAuthSignInButton)
