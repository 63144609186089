const retry = (func, retryCount = 2, retryInterval = 500) => {
  return new Promise((resolve, reject) => {
    func()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retryCount === 1) {
            reject(error)
            return
          }
          retry(func, retryCount - 1, retryInterval).then(resolve, reject)
        }, retryInterval)
      })
  })
}

export default retry
