import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css, cx } from 'react-emotion'

class FieldLabel extends Component {
  render() {
    const { label, name, className, ...rest } = this.props
    return (
      <label htmlFor={name} className={className} {...rest}>
        {label}
      </label>
    )
  }
}

FieldLabel.propTypes = {
  name: PropTypes.string.isRequired,
  // support object, when we do translation the output we get is a react element
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool
}

const FreshworksStyledLabel = styled(FieldLabel)`
  width: 100%;
  ${({
    disabled,
    theme: {
      colors,
      typography: {
        input: { label }
      }
    }
  }) => {
    var styles = [css(label), css({ cursor: 'pointer', color: colors.label.primary })]
    disabled &&
      styles.push(
        css({
          cursor: 'not-allowed'
        })
      )
    return cx(...styles)
  }};
  &:after {
    ${({ required, theme: { palette }, label }) => {
      // show the required only when label is present
      // there would be a requirement where required is only for field validation
      // and the FormikText won't need any label
      if (required && label) {
        return `
          padding: 2px;
          content: '*';
          color: ${palette.p[800]};
        `
      }
    }};
  }
`

export default FreshworksStyledLabel
