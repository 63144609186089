import Button from './Button'
import { styledWithRTL } from '../../utils/styles'

const StyledButtonWithBackground = (btnColor, btnLabel) => styledWithRTL(Button, {
  label: btnLabel
})`
  ${({ theme: { colors, palette } }) =>
    `
      display: flex;
      align-items: center;
      padding: 2px;
      background-color: ${btnColor(palette)};
      box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.12),
        0 2px 0 0 rgba(0, 0, 0, 0.07),
        0 0 1px 0 rgba(24, 50, 71, 0.6);
      color: ${colors.text.primary};
      background-image: none;
      border: none;
      font-size: 16px;

      &:not([disabled]):hover {
        background-image: none;
        background-color: ${btnColor(palette)};
        box-shadow: 0 0 2px 0 ${colors.text.primary};
      }

      &:not([disabled]):active {
        background-image: none;
        background-color: ${btnColor(palette)};
        box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.12),
          0 2px 0 0 rgba(0, 0, 0, 0.07),
          0 0 1px 0 rgba(24, 50, 71, 0.6);
        box-shadow: 0 0 spx 0 ${colors.text.primary};
      }
    `}
`

const btnWithLogoCss = {
  borderRadius: '2px',
  backgroundColor: 'white',
  width: '44px',
  height: '41px'
}

const btnWithIconStyle = {
  color: 'white',
  width: '24px',
  height: '24px'
}

export { StyledButtonWithBackground, btnWithLogoCss, btnWithIconStyle }
