import React from 'react'
import { css } from 'react-emotion'
import { withDownshift } from './Dropdown'
import Icon from '../Icon'
import { FreshworksStyledTextInput, BorderRadius } from '../FormikField/FormikField'
import { styledWithRTL } from '../../utils/styles'

const StyledDropdownInput = styledWithRTL(FreshworksStyledTextInput)`
  position: relative;
  input {
    padding: 6px 20px 6px 12px;
    border-radius: ${BorderRadius};
    &:focus {
      border: none;
      box-shadow: 0 0 0 2px ${({ theme: { colors } }) => colors.pseudo.focus};
      transition: box-shadow 0.3s ease-in-out;
    }
  }
  ${({
    hasError,
    theme: {
      colors: {
        border: { error }
      }
    }
  }) =>
    hasError
      ? `
    border-color: ${error};
    &:hover {
      border-color: ${error};
    }
    input {
      &:focus {
        box-shadow: 0 0 0 2px ${error};
        transition: box-shadow 0.3s ease-in-out;
      }
    }
  `
      : ''}
  ${({ overridingStyles }) => css(overridingStyles)}
`

const inputStyle = { position: 'absolute', zIndex: -1 }

const getConditionalProps = (labelId) => !!labelId && { 'aria-labelledby': labelId }

const Input = (props) => {
  const StyledDiv = styledWithRTL('div')`
    background-color: transparent;
    position: absolute;
    right: 0;
    top: 0;
    text-align:start;
    padding: 4px 10px 6px 4px;
    cursor: pointer;
  `

  const {
    downshift: { getInputProps, toggleMenu, isOpen },
    name,
    overridingStyles,
    hasError = false,
    labelId = ''
  } = props

  return (
    <StyledDropdownInput hasError={hasError} overridingStyles={overridingStyles}>
      <input type="button" tabIndex="-1" style={inputStyle} value="Select options" />
      <input
        {...getInputProps({
          'data-testid': name
        })}
        {...getConditionalProps(labelId)}
        type="text"
        onClick={toggleMenu}
      />
      <StyledDiv onClick={toggleMenu}>
        <Icon width={8} name={isOpen ? 'chevron-down' : 'chevron-up'} />
      </StyledDiv>
    </StyledDropdownInput>
  )
}

const DropdownInput = withDownshift(Input)

export default DropdownInput
