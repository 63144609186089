export default {
  EMPTY_ENTITY_ID: 'EMPTY_ENTITY_ID',
  EMPTY_SSO_URL: 'EMPTY_SSO_URL',
  INVALID_SSO_URL: 'INVALID_SSO_URL',
  EMPTY_IDP_CERTIFICATE: 'EMPTY_IDP_CERTIFICATE',
  INVALID_SAML_IDP_CERTIFICATE: 'INVALID_SAML_IDP_CERTIFICATE',
  MISSING_IDP_SLO_URL: 'MISSING_IDP_SLO_URL',
  EMPTY_CLIENT_ID: 'EMPTY_CLIENT_ID',
  EMPTY_AUTHENTICATION_SCOPES: 'EMPTY_AUTHENTICATION_SCOPES',
  INVALID_SCOPES_OIDC: 'INVALID_SCOPES_OIDC',
  EMPTY_AUTHORIZAION_URI: 'EMPTY_AUTHORIZAION_URI',
  INVALID_AUTHORIZAION_URI: 'INVALID_AUTHORIZAION_URI',
  INVALID_OAUTH_TOKEN_URI: 'INVALID_OAUTH_TOKEN_URI',
  EMPTY_OAUTH_TOKEN_URI: 'EMPTY_OAUTH_TOKEN_URI',
  EMPTY_CLIENT_SECRET: 'EMPTY_CLIENT_SECRET',
  EMPTY_USER_INFO_URI: 'EMPTY_USER_INFO_URI',
  INVALID_USER_INFO_URI: 'INVALID_USER_INFO_URI',
  EMPTY_CERTIFICATE_CONFIG: 'EMPTY_CERTIFICATE_CONFIG',
  INVALID_KEY_CONFIG: 'INVALID_KEY_CONFIG',
  INVALID_IDP_SLO_URL: 'INVALID_IDP_SLO_URL'
}
