import CONST from './constants'
import isStorageAvailable from './isStorageAvailable'
import { isUndefined } from './utility'

const isRTLLanguage = (langCode) => CONST.RTL_LANGUAGES_LCID.indexOf(langCode) > -1
const STR_DIR_ATTRIBUTE = 'dir'
const STR_DIR_LTR_VAL = 'ltr'
const STR_DIR_RTL_VAL = 'rtl'
const POSITION_LEFT_STR = 'left'
const POSITION_RIGHT_STR = 'right'

const changeToRTLStrings = (str) => {
  return str.replace(
    new RegExp(`(${POSITION_LEFT_STR}|${POSITION_RIGHT_STR})`, 'ig'),
    function (match) {
      return match === POSITION_LEFT_STR ? POSITION_RIGHT_STR : POSITION_LEFT_STR
    }
  )
}

const getUserLanguageFromLocalStorageOrFallbackToDefault = () => {
  // gets the current language of user from local storage or falls back to default lang (english)
  return getUserLanguageFromLocalStorage() || CONST.DEFAULT_LANGUAGE
}

const getUserLanguageFromLocalStorage = () => {
  if (isStorageAvailable(CONST.WEB_STORAGE.LOCAL_STORAGE)) {
    return localStorage.getItem(CONST.USER_LANGUAGE_PATH)
  }
  return null
}

const setUserLanguageToLocalStorage = (language) => {
  if (isStorageAvailable(CONST.WEB_STORAGE.LOCAL_STORAGE)) {
    localStorage.setItem(CONST.USER_LANGUAGE_PATH, language)
  }
}

const updateDocumentDir = (isRTL) => {
  if (isUndefined(isRTL)) {
    isRTL = isRTLLanguage(getUserLanguageFromLocalStorageOrFallbackToDefault())
  }
  document.body.setAttribute(STR_DIR_ATTRIBUTE, isRTL ? STR_DIR_RTL_VAL : STR_DIR_LTR_VAL)
}

const getLocaleCurr = (value, currency) => {
  //refer https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat/NumberFormat
  let numVal = parseFloat(value)
  if (!isNaN(numVal)) {
    return numVal.toLocaleString(getUserLanguageFromLocalStorageOrFallbackToDefault(), {
      style: 'currency',
      currency: currency,
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    })
  }
  return value
}

export {
  isRTLLanguage,
  updateDocumentDir,
  getUserLanguageFromLocalStorageOrFallbackToDefault,
  getLocaleCurr,
  getUserLanguageFromLocalStorage,
  setUserLanguageToLocalStorage,
  changeToRTLStrings
}
