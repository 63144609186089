import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import delve from 'dlv'
import { useIntl } from '../IntlContext'
import styled from 'react-emotion'
import { AppStateContext } from '../AppDataProvider'
import { getDataFromAppStateContext } from '../AppDataProvider/AppStateContext'
import { CONST } from '../../utils'

const logoHeightAndWidth = {
  small: '16',
  medium: '24',
  large: '32',
  xlarge: '56'
}

const DEFAULT_ENTRY_POINT_DATA = {
  id: null,
  slug: null
}

const StyledImage = styled('img')`
  ${({ size, isIcon }) => {
    return `
      height: ${logoHeightAndWidth[size]}px;
      ${
        isIcon
          ? `width: ${logoHeightAndWidth[size]}px;`
          : `max-width: ${4 * logoHeightAndWidth[size]}px;`
      }
    `
  }}
`
const {
  CUSTOMIZED_IMAGES_TYPES: { ORG_LOGO, ORG_FAVICON },
  FRESHWORKS
} = CONST

const getLogoAndFaviconAltText = (customizedImagesTypes) => {
  let altLogoText = FRESHWORKS
  let altFaviconText = FRESHWORKS
  const orgDomain = window.location.hostname.split('.')[0]
  customizedImagesTypes.forEach((imageType) => {
    if (imageType === ORG_LOGO) {
      altLogoText = orgDomain
    }
    if (imageType === ORG_FAVICON) {
      altFaviconText = orgDomain
    }
  })
  return {
    altFaviconText,
    altLogoText
  }
}
const getEntityTypeParam = (isIcon) => `entity_type=${isIcon ? 'ORG_FAVICON' : 'ORG_LOGO'}`
const getLogoUrl = (entryPointId, entryPointSlug, isIcon, shouldInvalidateSource) => {
  const basePath = `${CONST.DEFAULT_REDIRECT_PATH}/api/v2`
  let src
  if (entryPointId) {
    src = `${basePath}/entrypoints/${entryPointId}/image?variant=ORIGINAL`
  } else if (entryPointSlug) {
    src = `${basePath}/entrypoints/slug/${entryPointSlug}/image?variant=ORIGINAL`
  } else {
    src = `${basePath}/organisation/-/image?variant=ORIGINAL&${getEntityTypeParam(isIcon)}`
  }
  return shouldInvalidateSource ? `${src}&${new Date().getTime()}` : src
}

function Logo({ entryPointData, invalidateSource, ...props }) {
  const { customizedImagesTypes = [] } = getDataFromAppStateContext(
    useContext(AppStateContext),
    'common.getOrgConfig',
    {}
  )
  const { altFaviconText, altLogoText } = getLogoAndFaviconAltText(customizedImagesTypes)
  const { id: entryPointId, slug: entryPointSlug } = entryPointData || DEFAULT_ENTRY_POINT_DATA
  const src = getLogoUrl(entryPointId, entryPointSlug, props.isIcon, invalidateSource)
  let altText = props.isIcon ? 'organisation.icon' : 'organisation.logo'
  const isEntryPointLogo = entryPointSlug || entryPointId
  const getCurrentUserDetails = getDataFromAppStateContext(
    useContext(AppStateContext),
    'getCurrentUserDetails',
    {}
  )
  const { toIntlString } = useIntl()
  const isPortalAccountUser = delve(getCurrentUserDetails, 'data.user.isPortalAccountUser', false)
  return isPortalAccountUser ? null : (
    <StyledImage
      {...props}
      src={src}
      alt={
        isEntryPointLogo
          ? toIntlString('security.entry_points.entry_policy_logo')
          : toIntlString(altText, { companyName: props.isIcon ? altFaviconText : altLogoText })
      }
    />
  )
}

Logo.propTypes = {
  isIcon: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge']),
  invalidateSource: PropTypes.bool,
  entryPointData: PropTypes.object
}

Logo.defaultProps = {
  size: 'medium',
  isIcon: false,
  invalidateSource: false
}

export default React.memo(Logo)
