import React, { Component, useEffect } from 'react'
import { ServerError } from '../Errors'
import useToast, { ERROR_TOAST_TIMEOUT, TOAST_LEVELS } from '../../components/Toast'
import useStore from '../../store/useStore'
import { useIntl } from '../IntlContext'

const TopErrorBoundary = ({ children }) => {
  const errorCode = useStore((state) => state.errorCode)
  const { toIntlString } = useIntl()
  const toast = useToast()
  useEffect(() => {
    if (errorCode === 429) {
      toast(toIntlString('error.too_many_requests'), {
        level: TOAST_LEVELS.ERROR,
        timeout: ERROR_TOAST_TIMEOUT
      })
      useStore.getState().setErrorCode(null)
    }
  }, [errorCode])

  return <TopErrorBoundaryWrapper>{children}</TopErrorBoundaryWrapper>
}

class TopErrorBoundaryWrapper extends Component {
  state = {
    error: null
  }

  static getDerivedStateFromError({ message }) {
    // catch and gracefully error out all
    // unhandled errors within the app
    return {
      error: message
    }
  }

  render() {
    const { error } = this.state
    const { children } = this.props

    if (error) {
      !!window.heap &&
        window.heap.track('Server Error Page', {
          error
        })
      const errorId = !!window.Sentry && window.Sentry.captureException(new Error(error))
      return <ServerError errorId={errorId} error={error} />
    } else {
      return children
    }
  }
}

export default TopErrorBoundary
