import {css} from 'emotion'
import React, {memo} from 'react'
import Loader from '.'
import {Box, Flex} from '../Grid'

const FullLoader = memo(() => (
  <Flex
    width="100vw"
    css={{height: '100vh'}}
    justifyContent="center"
    alignItems="center">
    <Box>
      <Loader
        className={css({
          margin: 'auto'
        })}
      />
    </Box>
  </Flex>
))

export default FullLoader
