import palette from './palette'
import { lighten, darken, rgba, transparentize } from 'polished'

const {
  e,
  s,
  c,
  j,
  p,
  a,
  jade,
  dodger,
  elephant,
  zircon,
  porcelain,
  smoke,
  athens,
  sunrise,
  pearlLusta,
  scotchMist,
  white,
  transparent,
  black,
  skylight,
  clara,
  passion,
  babylon,
  oceanBlue,
  minuteGreyDark,
  oceanDarkBlue,
  translucentBlue,
  creamBlue,
  ghostBlack,
  darkBlackBoxShadow,
  orangeIQ
} = palette

export default {
  body: {
    background: {
      primary: porcelain, //post login
      secondary: athens //pre login
    }
  },
  primary: a[800],
  link: a[800],
  white: white,
  black: black,
  text: {
    primary: e[900],
    secondary: s[700],
    light: white,
    disabled: s[300],
    placeholder: s[300],
    error: p[800],
    success: j[800],
    label: s[700],
    labelDark: s[750],
    warning: sunrise,
    extraLight: e[700],
    link: a[800],
    lynch: s[700],
    orange: c[600],
    darkOrange: c[700],
    gullGray: s[300],
    orangeIQ
  },
  toolTip: {
    normal: s[800],
    success: j[900],
    error: passion,
    ferrari: p[900],
    black: black
  },
  label: {
    primary: s[700]
  },
  icon: {
    primary: s[700],
    secondary: elephant
  },
  border: {
    primary: s[400],
    secondary: s[50],
    hover: s[700],
    active: elephant,
    disabled: s[50],
    error: p[800]
  },
  background: {
    primary: porcelain,
    hover: s[50],
    disabled: s[50],
    error: lighten(0.4, p[800]),
    secondary: s[10],
    light: s[25],
    selected: a[50]
  },
  layout: {
    asidePane: athens
  },
  card: {
    boxShadow: transparentize(0.9, e[800]),
    darkBoxShadow: transparentize(0.6, e[900])
  },
  components: {
    table: {
      selection: pearlLusta
    },
    alert: {
      background: scotchMist
    },
    button: {
      primary: {
        backgroundColor: e[900],
        backgroundGradient: {
          from: e[800],
          to: e[900]
        },
        hover: e[900],
        borderColor: e[900],
        color: white,
        disabled: {
          borderColor: s[400],
          backgroundGradient: {
            from: s[300],
            to: s[400]
          },
          color: s[100]
        },
        active: {
          borderColor: e[200],
          backgroundColor: e[800]
        }
      },
      secondary: {
        backgroundColor: white,
        backgroundGradient: {
          from: white,
          to: s[25]
        },
        hover: s[25],
        borderColor: s[100],
        color: e[900],
        disabled: {
          borderColor: s[100],
          backgroundGradient: {
            from: s[50],
            to: s[50]
          },
          color: s[300]
        },
        active: {
          borderColor: s[100],
          backgroundColor: white
        }
      },
      danger: {
        backgroundColor: p[900],
        backgroundGradient: {
          from: p[800],
          to: p[900]
        },
        hover: p[900],
        borderColor: p[900],
        color: white,
        disabled: {
          borderColor: p[300],
          backgroundGradient: {
            from: p[200],
            to: p[300]
          },
          color: p[100]
        },
        active: {
          borderColor: babylon,
          backgroundColor: p[900]
        }
      },
      link: {
        backgroundColor: transparent,
        backgroundGradient: {
          from: transparent,
          to: transparent
        },
        hover: s[50],
        borderColor: transparent,
        color: a[800],
        disabled: {
          borderColor: transparent,
          backgroundGradient: {
            from: transparent,
            to: transparent
          },
          color: a[800]
        },
        active: {
          borderColor: a[800],
          backgroundColor: transparent
        }
      },
      buttonGroup: {
        normal: e[500]
      }
    },
    dropdown: {
      button: {
        disabled: {
          border: s[100],
          background: s[50],
          color: s[300]
        }
      }
    },
    inlineMessage: {
      error: p[800],
      warning: sunrise,
      info: dodger,
      success: jade
    },
    inlineBanner: {
      border: {
        success: j[100],
        info: a[100],
        warning: c[100],
        error: p[100]
      },
      background: {
        success: j[50],
        info: a[50],
        warning: c[50],
        error: p[50]
      }
    },
    readMore: {
      border: {
        success: j[100],
        error: p[100],
        warning: c[100],
        info: s[100]
      },
      background: {
        success: j[50],
        error: p[50],
        warning: c[50],
        info: white
      }
    },
    panel: {
      gradientStart: white,
      gradientEnd: athens,
      border: zircon,
      background: smoke,
      level: {
        warning: {
          backgroundColor: scotchMist,
          borderColor: darken(0.2, scotchMist)
        },
        danger: {
          backgroundColor: lighten(0.35, p[800]),
          borderColor: lighten(0.1, p[800])
        }
      }
    },
    confirmModal: {
      background: s[50],
      border: zircon
    },
    modal: {
      overlayBackground: transparentize(0.5, e[900])
    },
    tag: {
      background: {
        success: j[50],
        error: p[50],
        neutral: a[50],
        warn: c[50],
        info: white,
        grey: s[50]
      },
      border: {
        success: j[100],
        error: p[100],
        neutral: a[100],
        warn: c[100],
        info: s[100],
        grey: transparent
      },
      color: {
        success: j[800],
        error: p[900],
        neutral: a[800],
        warn: '#C13E1B',
        info: e[900],
        grey: s[800]
      }
    },
    railNavigation: {
      backgroundColor: e[900],
      activeColor: rgba(white, 0.3)
    },
    listCard: {
      borderLeft: a[800],
      background: smoke
    },
    tabs: {
      borderColor: a[800],
      defaultColor: s[700],
      selectedColor: a[800]
    },
    tooltip: {
      light: {
        backgroundColor: white,
        borderColor: s[100],
        boxShadow: {
          content: `-1px 2px 8px ${darkBlackBoxShadow}`,
          arrow: '1px 1px 1px 0px rgba(0, 0, 0, 0.1)'
        }
      },
      dark: {
        backgroundColor: e[900],
        borderColor: 'transparent',
        boxShadow: {
          content: '0px 0px 4px 0px rgba(0,0,0,0.4)',
          arrow: '1px 1px 1px rgba(0, 0, 0, 0.1)'
        }
      }
    },
    accordion: {
      header: {
        borderColor: zircon
      },
      section: {
        background: s[10],
        borderColor: s[50]
      }
    },
    switch: {
      sColor: a[800],
      sBorderColor: clara
    },
    stepper: {
      headerText: e[900],
      bulletBorder: oceanDarkBlue,
      selectedHeaderText: translucentBlue,
      selectedBulletBorder: translucentBlue,
      separatorBackground: ghostBlack
    },
    slider: {
      borderColor: s[100],
      selectedSlider: oceanBlue,
      text: minuteGreyDark,
      disabledSlider: a[150]
    },
    numberInput: {
      containerBorder: creamBlue,
      containerOutline: a[800]
    },
    toast: {
      success: j[500],
      error: p[700],
      loading: a[800],
      warn: c[600]
    }
  },
  pseudo: {
    focus: a[800],
    highlight: skylight
  },
  alertLevel: {
    warning: c[50],
    success: j[50],
    info: a[50],
    error: p[50],
    borderColor: {
      warning: c[100],
      success: j[100],
      info: a[100],
      error: p[100]
    }
  }
}
