import React, { Component, createContext } from 'react'
import PropTypes from 'prop-types'
import { CancelToken, isCancel } from 'axios'
import dset from 'dset'

import { delay, getErrorList } from '../../utils'
import CONST from '../../utils/constants'

import activities from './activities'
import Transition from './Transition'
import IfSuccess from './IfSuccess'
import IfError from './IfError'
import Loader from './Loader'
import { AppStateUpdateContext, writeToAppStateContext } from '../AppDataProvider/AppStateContext'

import { AxiosWrapper } from './AxiosWrapper'
import useStore from '../../store/useStore'

const getDefaultErrorResponse = () => ({
  error: CONST.ERROR_STRINGS.UNEXPECTED_RESPONSE,
  message: CONST.ERROR_STRINGS.UNEXPECTED_RESPONSE,
  details: [
    {
      '@type': 'type.googleapis.com/google.rpc.BadRequest',
      field_violations: [
        {
          field: CONST.ERROR_STRINGS.UNEXPECTED_RESPONSE,
          description: CONST.ERROR_STRINGS.UNEXPECTED_RESPONSE
        }
      ]
    }
  ]
})

export const REQUEST_STATE = {
  uninitiated: 'UNINITIATED',
  inProgress: 'IN_PROGRESS',
  success: 'SUCCESS',
  error: 'ERROR'
}

export const DataManagerContext = createContext({})

export default class DataManager extends Component {
  static Transition = Transition
  static IfSuccessful = IfSuccess
  static IfErrorOccurred = IfError
  static Loader = Loader
  static contextType = AppStateUpdateContext
  initialState = {
    requestState: REQUEST_STATE.uninitiated,
    data: null,
    error: null,

    /**
     * we need to maintain temporary state post request
     * success or error. these states reset to their initial values
     * after a timeout set by `props.transitionDuration`
     */

    hooks: {
      requestDidFail: false,
      requestDidSucceed: false
    },

    retryingRequestAttempts: 0
  }

  state = this.initialState

  request = (configWhenInvoked = {}) => {
    if (configWhenInvoked.cancelOldRequests) {
      this.cancelRequest && this.cancelRequest('Cancelling old request and re-initiating new on')
    } else if (this.state.requestState === REQUEST_STATE.inProgress) {
      return
    }
    // before sending a request, we want to reset the older request instance
    // this will reset the associated internal states
    this.reset(() => {
      const {
        activity,
        axiosConfig: { method: _ignoredMethod, url: _ignoredUrl, ...config } = {},
        pathFragment,
        retryStrategy,
        requestDataInterceptor,
        responseDataInterceptor
      } = this.props
      //To use different pathsegments with the same DataManager used inside React Adopt.
      //React Adopt is not updating the DataManager instances used if we change the param
      const pathFragmentToUse = configWhenInvoked.pathFragment || pathFragment
      // we support the option to retry request if they fail.
      // disabling the retry mechanism in test env
      // so that we can avoid custom timeouts in testcases
      let retry = [0]
      if (process.env.NODE_ENV !== 'test') retry = [0, ...retryStrategy]

      const _request = retry
        .reduce((promiseChain, delayMs) => {
          return promiseChain.catch(() =>
            delay(delayMs).then(() => {
              this.setState({
                retryingRequestAttempts: this.state.retryingRequestAttempts + 1
              })
              const {
                method,
                url,
                isLoggingDisabled,
                getAdditionalHeadersWithPathName
              } = activities[activity]
              if (configWhenInvoked.params) {
                configWhenInvoked.params = {
                  ...config.params,
                  ...configWhenInvoked.params
                }
              }
              return AxiosWrapper.getInstance()({
                method,
                url,
                ...(typeof url === 'function' ? { url: url(...pathFragmentToUse) } : {}),
                ...config,
                ...configWhenInvoked,
                cancelToken: new CancelToken((c) => {
                  this.cancelRequest = c
                }),
                __interceptorConfig: {
                  requestDataInterceptor,
                  responseDataInterceptor,
                  activityName: activity,
                  isLoggingDisabled,
                  getAdditionalHeadersWithPathName
                }
              })
            })
          )
        }, Promise.reject())
        .catch((err) => {
          if (isCancel(err)) {
            console.warn(err.message)
          }

          throw err
        })
        .then((response) => {
          this.setState({
            retryingRequestAttempts: 0
          })

          return response
        })

      this.setState(
        {
          _request,
          requestState: REQUEST_STATE.inProgress
        },
        this.handleRequest
      )

      this.props.onReset()
    })
  }

  handleRequest = () => {
    this.state._request.then(
      (response) => {
        this.checkAndAddToCommonStore(response, true)
        this.setState(
          {
            requestState: REQUEST_STATE.success,
            data: response.data,
            hooks: { ...this.state.hooks, requestDidSucceed: true }
          },
          () => {
            this.props.onSuccess(response)
            this.props.transitionDuration &&
              delay(this.props.transitionDuration).then(() => {
                this.setState({
                  hooks: {
                    ...this.state.hooks,
                    requestDidSucceed: false
                  }
                })
              })
          }
        )
      },
      (error) => {
        if (!isCancel(error)) {
          const errorCode = error.response?.status
          useStore.getState().setErrorCode(errorCode)

          // converting the error key from lowercase(ex: 'server_error') to uppercase(ex: 'SERVER_ERROR')
          // to make it consistent across all pages
          // all pages are expected to check for errors in uppercase

          // This is causing issue when server returns 503
          // so do `dest` when error.response.data is a object
          // during 503 cases error.response.data will be a string
          // This toUpperCase() is not required at all,
          // because we should be using errors.fields[] as a standard error identification object

          // TODO: Fix in all place where we handled errors using
          // `error.response.data.error` instead use `error.response.data.error.details.0.field_violations.0.field`
          if (error.response && typeof error.response.data === 'object') {
            dset(
              error,
              'response.data.error',
              error.response.data && error.response.data.error
                ? error.response.data.error.toUpperCase()
                : ''
            )
          }
          const errorResponse = error.response || getDefaultErrorResponse()
          if (!errorResponse.config && error.config) {
            errorResponse.config = error.config
          }
          this.checkAndAddToCommonStore(errorResponse, false)
          this.setState(
            {
              requestState: REQUEST_STATE.error,
              error: errorResponse,
              hooks: { ...this.state.hooks, requestDidFail: true }
            },
            () => {
              this.props.onError({
                ...this.state.error,
                ...getErrorList(this.state.error && this.state.error.data)
              })
              this.props.transitionDuration &&
                delay(this.props.transitionDuration).then(() => {
                  this.setState({
                    hooks: {
                      ...this.state.hooks,
                      requestDidFail: false
                    }
                  })
                })
            }
          )
        }
      }
    )
  }

  checkAndAddToCommonStore(response = {}, isSuccess) {
    const {
      shouldCache,
      activity,
      defaultCacheErrorResponseHandler,
      defaultCacheSuccessResponseHandler
    } = this.props
    const data = isSuccess
      ? defaultCacheSuccessResponseHandler(response)
      : defaultCacheErrorResponseHandler(response)
    if (shouldCache) {
      writeToAppStateContext(this.context.setState, activity, data)
    }
  }

  reset = (onReset) => {
    this.setState(this.initialState, onReset)
  }

  render() {
    const { children } = this.props

    const { requestState, data, error, hooks, retryingRequestAttempts } = this.state

    const request = this.request

    return (
      <DataManagerContext.Provider value={this.state}>
        {typeof children === 'function'
          ? children({
              isLoading: requestState === REQUEST_STATE.inProgress,
              data,
              error,
              makeRequest: request,
              hooks,
              retryingRequestAttempts
            })
          : children}
      </DataManagerContext.Provider>
    )
  }

  componentDidMount() {
    !this.props.lazy && this.request()
  }

  componentWillUnmount() {
    // cancel the request
    this.cancelRequest && this.cancelRequest('Request cancelled because component unmounted')
  }
}

DataManager.propTypes = {
  activity: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),

  /**
   * Pass in a promise or a number of milliseconds to delay the request
   */

  lazy: PropTypes.bool,

  /**
   * You can pass in any config accepted by axios request
   * expect `method` and `url`
   * https://github.com/axios/axios#request-config
   */
  axiosConfig: PropTypes.object,

  /**
   * Duration in `ms` to update request status hook.
   * Hooks wont be updated if this prop is not defined
   */
  transitionDuration: PropTypes.number,
  retryStrategy: PropTypes.arrayOf(PropTypes.number),
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  onReset: PropTypes.func,

  /**
   * If you need to share the data between multiple places
   * then set cache to true with a predefined pattern
   * then the response either success or failure will be written
   * to the common AppStateContext from where you can use it.
   */
  shouldCache: PropTypes.bool,
  defaultCacheErrorResponseHandler: PropTypes.func,
  defaultCacheSuccessResponseHandler: PropTypes.func,

  /**
   * custom interceptors for response and request
   * This function `MUST` return a value
   */
  requestDataInterceptor: PropTypes.func,
  responseDataInterceptor: PropTypes.func,
  pathFragment: PropTypes.array
}

const defaultCacheResponseHandler = (val) => val

DataManager.defaultProps = {
  retryStrategy: [],
  onSuccess: () => {},
  onError: () => {},
  onReset: () => {},
  requestDataInterceptor: (arg) => arg,
  responseDataInterceptor: (arg) => arg,
  pathFragment: [],
  defaultCacheSuccessResponseHandler: defaultCacheResponseHandler,
  defaultCacheErrorResponseHandler: defaultCacheResponseHandler
}
