import React from 'react'
import { Box } from '../Grid'

import { OneColumnContentBox } from '../Layout'
import { H1, PrimaryText } from '../Typography'
import Image from '../Image'
import IntlContext from '../IntlContext'

export default function ServerError({ errorId, error }) {
  console.error('SERVER ERROR : Oops! something went wrong!, ', error)
  const heapUserId = window.heap && (window.heap.identity || window.heap.userId)
  return (
    <IntlContext.Consumer>
      {({ toIntlString }) => (
        <OneColumnContentBox>
          <OneColumnContentBox.OneColumnContentSection disableFormFocus orgLogo={false}>
            <Box css={{ textAlign: 'center' }}>
              <H1>{toIntlString('link_expired.title')}</H1>
            </Box>
            <Box css={{ textAlign: 'center' }} mt={3} mb={4}>
              <Image
                width={3 / 4}
                m="auto"
                src="default_error_fallback.png"
                alt={toIntlString('error.error')}
              />
            </Box>
            <Box css={{ textAlign: 'center' }} mt={3} mb={4}>
              {errorId && (
                <PrimaryText textType="error">
                  {`${toIntlString('error.error_id')} ${errorId}`}
                </PrimaryText>
              )}
              {heapUserId && (
                <PrimaryText textType="error">
                  {`${toIntlString('error.error_code')} ${heapUserId}`}
                </PrimaryText>
              )}
              <br />
              <PrimaryText>{toIntlString('error.unknown_error')}</PrimaryText>
              <PrimaryText data-testid="email-suggestion-error">
                {toIntlString('error.email_suggestion')}
              </PrimaryText>
              <a href="mailTo:support@freshworks.com">support@freshworks.com</a>
            </Box>
          </OneColumnContentBox.OneColumnContentSection>
        </OneColumnContentBox>
      )}
    </IntlContext.Consumer>
  )
}
