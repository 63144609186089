import React, { createContext, useState, useContext, Fragment } from 'react'
import { Flex, Box } from './../Grid'
import styled, { css, keyframes } from 'react-emotion'
import Transition from 'react-transition-group/Transition'

import Icon from '../Icon'
import { PrimaryText } from '../Typography'

import { CONST } from '../../utils'
import { cssWithRTL, styledWithRTL } from '../../utils/styles'
import APP_CONSTANTS from '../../utils/constants'

const TOAST_WIDTH = (window && window.innerWidth) < CONST.SCREEN_SIZES.md ? 250 : 410
const STACK_WIDTH = TOAST_WIDTH - 20
const Z_INDEX = 9999

const ToastContainer = styled(Flex, {
  label: 'ToastContainer'
})`
  ${({ theme: { colors }, toastIndex, isToastVisible }) => `
    position: relative;
    z-index: ${Z_INDEX - toastIndex};
    background: ${colors.white};
    box-shadow: 0px 2px 4px ${colors.border.primary};
    border: 1px solid ${colors.border.secondary};
    border-radius: 4px;
    overflow: hidden;
    top: 10px;
    visibility: ${isToastVisible ? 'visibile' : 'hidden'}
  `}
`

const ToastTypeRule = styled(Box, {
  label: 'ToastTypeRule'
})`
  ${({
    theme: {
      colors: {
        components: { toast }
      }
    },
    level
  }) => `
    width: 100%;
    height: 4px;
    background: ${toast[level] || TOAST_LEVELS.LOADING};
    border-radius: 4px 4px 0px 0px;
  `}
`

const ToastCloseButton = styled(Box, {
  label: 'ToastCloseButton'
})`
  position: absolute;
  cursor: pointer;
`

const ToastStackContainer = styled(Flex, {
  label: 'ToastStackContainer'
})`
  position: fixed;
  top: 0;
  left: 50%;
  z-index: ${Z_INDEX};
  transform: translateX(-50%);
  width: ${TOAST_WIDTH - 10}px;
`

const Stackbox = styled(Box, {
  label: 'Stackbox'
})`
  ${({ theme: { colors } }) => `
    background: ${colors.white};
    box-shadow: 0px 2px 4px ${colors.border.primary};
    border: 1px solid ${colors.border.secondary};
    overflow: hidden;
    z-index: ${Z_INDEX - 2};
    width: ${STACK_WIDTH}px;
    height: 8px;
    margin: auto;
  `}
`

const rotate = keyframes`
  to { transform: rotate(360deg); }
`

const LoadingStyles = css`
  animation: ${rotate} 1s infinite linear;
`

const ToastContentBox = styledWithRTL(Box)`
  width: 100%;
  padding-left:16px;
  word-wrap: break-word;
`

export const TOAST_LEVELS = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARN: 'warn',
  LOADING: 'loading'
}

export const ERROR_TOAST_TIMEOUT = 10000

const SlideTransition = ({ in: inProp, children, index, canTransform }) => {
  const defaultStyle = (index) => ({
    transition: `transform 200ms ease-out`,
    transform: `translateY(${index * -100}%)`,
    height: '100%',
    zIndex: `${Z_INDEX - index}`
  })

  const transitionStyles = (state, index) => {
    const TRANSITION_STATES = {
      ENTERING: 'entering',
      ENTERED: 'entered',
      EXITED: 'exited'
    }
    let transformStyleObj = {}
    if (state === TRANSITION_STATES.ENTERING) {
      transformStyleObj = { transform: `translateY(${index * -100}%)` }
    } else if (state === TRANSITION_STATES.ENTERED) {
      transformStyleObj = { transform: `translateY(${(index - 1) * 8}px)` }
    } else if (state === TRANSITION_STATES.EXITED) {
      transformStyleObj = { opacity: 0, transform: `translateY(-100%)` }
    }
    return transformStyleObj
  }

  const transformStyle = (canTransform, index) =>
    canTransform
      ? {}
      : {
          transition: `opacity 200ms ease-out, transform 200ms ease-out`,
          transform: `translateY(${index * -100 + 100}%)`,
          height: '100%',
          width: `${TOAST_WIDTH - index * 10}px`,
          margin: 'auto'
        }
  return (
    <Transition in={inProp} timeout={200} unmountOnExit>
      {(state) => (
        <div
          style={{
            ...defaultStyle(index),
            ...transitionStyles(state, index),
            ...transformStyle(canTransform, index)
          }}>
          {children}
        </div>
      )}
    </Transition>
  )
}

const ToastContent = ({ content, config: { level }, toastId, setCanTransform, closeToast }) => {
  const toastIconMap = {
    [TOAST_LEVELS.SUCCESS]: 'toast-success',
    [TOAST_LEVELS.ERROR]: 'toast-error',
    [TOAST_LEVELS.WARN]: 'toast-warn',
    [TOAST_LEVELS.LOADING]: 'toast-loading'
  }
  const onToastClose = () => {
    closeToast(toastId)
    setCanTransform(false)
  }
  return (
    <Fragment>
      <ToastTypeRule level={level} />
      <Box p={3} pr="40px" onClick={() => setCanTransform((canTransform) => !canTransform)}>
        <Flex>
          <Box
            data-testid={`toast-level-${level}`}
            pt={1}
            className={level === TOAST_LEVELS.LOADING ? LoadingStyles : ''}>
            <Icon name={toastIconMap[level] || toastIconMap[TOAST_LEVELS.LOADING]} width={16} />
          </Box>
          <ToastContentBox>
            <PrimaryText textWeight="semiBold" role="alert">
              {content}
            </PrimaryText>
          </ToastContentBox>
        </Flex>
      </Box>
      <ToastCloseButton
        className={cssWithRTL({
          top: '12px',
          right: '12px'
        })}
        data-testid="close-toast"
        tabIndex="0"
        role="button"
        onClick={() => {
          onToastClose()
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            onToastClose()
          }
        }}>
        <Icon name="close-bold" width={10} />
      </ToastCloseButton>
    </Fragment>
  )
}

const Toast = ({ content, config, index, toastId, canTransform, closeToast, setCanTransform }) => {
  if (content === APP_CONSTANTS.IGNORE_TOO_MANY_REQUESTS) {
    return null
  }
  const [showWithTransition, setShowWithTransition] = useState(false)
  const [stackState, setStackState] = useState(canTransform)
  if (canTransform) {
    // added timeout for async execution - for transition effect purpose
    setTimeout(() => setShowWithTransition(true))
    setTimeout(() => setStackState(canTransform), 150)
  } else {
    // added timeout for async execution - for transition effect purpose
    setTimeout(() => setStackState(canTransform))
    setTimeout(() => setShowWithTransition(true), 100)
  }
  return (
    <SlideTransition in={showWithTransition} index={index + 1} canTransform={!canTransform}>
      <ToastContainer
        tabIndex="0"
        isToastVisible={index === 0 || !stackState}
        flexDirection="column"
        toastIndex={index + 1}>
        <ToastContent
          content={content}
          config={config}
          toastId={toastId}
          setCanTransform={setCanTransform}
          closeToast={closeToast}
        />
      </ToastContainer>
      {index === 0 && canTransform && (
        <Stackbox
          className={cssWithRTL`
            border-radius: 0 0 4px 4px;
          `}
          data-testid="toast-stack-box"
        />
      )}
    </SlideTransition>
  )
}

const Context = createContext([])
const ToastContext = ({ children }) => {
  const DEFAULT_TIMEOUT = 5000
  const [toastStates, setToastStates] = useState([])
  const [canTransform, setCanTransform] = useState(false)
  const [timeOutQueue, setTimeOutQueue] = useState({})

  const DEFAULT_CONFIG = { level: 'loading', timeout: ERROR_TOAST_TIMEOUT, autoClose: true }

  const showToast = (content, config) => {
    config = { ...DEFAULT_CONFIG, ...config }
    setCanTransform(false)

    // generating random incremental toast id
    const toastId = new Date().getTime()
    const toastObj = { content, ...config, hasExpired: false }
    setToastStates((toastStates) => ({ ...toastStates, [toastId]: toastObj }))

    if (!!config.autoClose) {
      // set timeout to enable auto close
      const timeoutId = setTimeout(
        (toastId) => {
          setToastStates((toastStates) => {
            const { [toastId]: toastToExpire, ...restOfTheToasts } = toastStates
            toastToExpire.hasExpired = true
            return { [toastId]: toastToExpire, ...restOfTheToasts }
          })
          setCanTransform(false)
          // remove toast reference from timeout queue on auto close
          setTimeOutQueue((timeOutQueue) => {
            const { [toastId]: toastToExpire, ...rest } = timeOutQueue
            return rest
          })
        },
        config.timeout || DEFAULT_TIMEOUT,
        toastId
      )

      setTimeOutQueue((timeOutQueue) => ({
        ...timeOutQueue,
        [toastId]: timeoutId
      }))
    }
  }

  const closeToast = (toastId) => {
    if (toastStates.length === 2) {
      setCanTransform(false)
    }
    setToastStates((toastStates) => {
      const { [toastId]: toastToExpire, ...restOfTheToasts } = toastStates
      toastToExpire.hasExpired = true
      return { [toastId]: toastToExpire, ...restOfTheToasts }
    })
    // remove toast reference from timeout queue on toast close
    setTimeOutQueue((timeOutQueue) => {
      const { [toastId]: toastToExpire, ...rest } = timeOutQueue
      return rest
    })

    if (timeOutQueue.length > 0) {
      clearTimeout(timeOutQueue[toastId])
    }
  }

  let unexpiredIndexCount = -1
  return (
    <Context.Provider value={showToast}>
      <Fragment>
        <ToastStackContainer flexDirection="column">
          {Object.keys(toastStates).map((toastId, index) => {
            const { content, ...config } = toastStates[toastId]
            !config.hasExpired && unexpiredIndexCount++
            return (
              !config.hasExpired && (
                <Toast
                  key={index}
                  content={content}
                  config={config}
                  index={unexpiredIndexCount}
                  toastId={toastId}
                  canTransform={canTransform}
                  closeToast={closeToast}
                  setCanTransform={(canTransform) => {
                    setCanTransform(Object.keys(toastStates).length > 1 && canTransform)
                  }}
                />
              )
            )
          })}
        </ToastStackContainer>
        {children}
      </Fragment>
    </Context.Provider>
  )
}

const useToast = () => {
  const showToast = useContext(Context)
  return showToast
}

export default useToast
export { ToastContext }
