import React from 'react'
import PropTypes from 'prop-types'

import Dropdown, { DropdownButton, DropdownMenu, DropdownInput } from '../Dropdown'
import { CONST, logger } from '../../utils'
import { useIntl } from '../IntlContext'
import { DROPDOWN_TYPES } from '../Dropdown/Dropdown'

const overrideStyles = {
  boxShadow: 'none',
  background: 'transparent',
  lineHeight: '18px'
}

const LanguageDropdown = ({
  supportedLanguages,
  selectedLanguage,
  onLanguageChange,
  type,
  labelId,
  ...rest
}) => {
  let languageCodes = [],
    languageLabels = [],
    currentLanguage = ''

  const { toIntlString } = useIntl()

  Object.keys(supportedLanguages).forEach((langKeys) => {
    const languageLabel = supportedLanguages[langKeys]
    languageCodes.push(langKeys)
    languageLabels.push(languageLabel)

    if (langKeys.toLowerCase() === selectedLanguage.toLowerCase()) {
      currentLanguage = languageLabel
    }
  })

  if (currentLanguage === '') {
    logger.error('The provided selected language is not supported.')
  }

  const containerStyles =
    DROPDOWN_TYPES.INPUT === type
      ? {}
      : {
          top: '-210px',
          right: '0px',
          textAlign: 'left'
        }

  return (
    <Dropdown
      items={languageLabels}
      selectedItem={currentLanguage}
      type={type}
      onChange={(languageLabel) => {
        const index = languageLabels.indexOf(languageLabel)
        const languageCode = languageCodes[index]
        onLanguageChange(languageCode)
      }}
      labelId={labelId}
      {...rest}>
      {type === DROPDOWN_TYPES.INPUT && <DropdownInput labelId={labelId} />}
      <DropdownMenu
        overrideStylesForContainer={containerStyles}
        optionLabel={toIntlString('my_profile.button.choose_language')}
      />
      {type === DROPDOWN_TYPES.BUTTON && (
        <DropdownButton overrideStyles={overrideStyles} labelId={labelId} />
      )}
    </Dropdown>
  )
}

LanguageDropdown.defaultProps = {
  selectedLanguage: CONST.DEFAULT_LANGUAGE,
  type: DROPDOWN_TYPES.BUTTON,
  labelId: ''
}

LanguageDropdown.propTypes = {
  /**
   *  The key should be a IETF language code
   *  The value should be label of the language that will be
   *  shown in the dropdown.
   *  The preferred label should be in the language it is referring
   *  Like the label for russian should be Русский
   */
  supportedLanguages: PropTypes.object.isRequired,
  /**
   * IETF language code for the current language
   * like en-us or en-US (case is insensitive)
   */
  selectedLanguage: PropTypes.string.isRequired,
  onLanguageChange: PropTypes.func.isRequired,
  /**
   * decides whether the DropDown should be a button or a searchable <input>
   */
  type: PropTypes.oneOf(['button', 'input'])
}

export default LanguageDropdown
