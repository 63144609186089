import delve from 'dlv'
import { getConvertedPriceValue } from '../utils'

const PHONE_CREDIT_SPLIT = {
  NUMBERS_CREDITS: 'numbersCredits',
  INBOUND_CREDITS: 'inboundCredits',
  OUTBOUND_CREDITS: 'outboundCredits',
  INBOUND: '_incoming',
  OUTBOUND: '_outgoing',
  NUMBERS: '_numbers',
  ABOVE: 'above',
  BELOW: 'below'
}

const {
  INBOUND,
  OUTBOUND,
  NUMBERS,
  NUMBERS_CREDITS,
  INBOUND_CREDITS,
  OUTBOUND_CREDITS
} = PHONE_CREDIT_SPLIT

const getDefaultSplit = (price, defaultSplit, creditValue, currencyCode) => {
  let defaultCreditSplit = {}
  defaultSplit.map((rechargeOptions) => {
    if (
      getConvertedPriceValue(rechargeOptions.value * price, currencyCode, false) === creditValue
    ) {
      rechargeOptions.split.map((rechargeAmount) => {
        const rechargeSplit = getConvertedPriceValue(
          rechargeAmount.addonQuantity * price,
          currencyCode,
          false
        )
        const addonId = delve(rechargeAmount, 'addonId', '')
        if (addonId.includes(INBOUND)) {
          defaultCreditSplit[INBOUND] = Number(rechargeSplit.toFixed(2))
        } else if (addonId.includes(OUTBOUND)) {
          defaultCreditSplit[OUTBOUND] = Number(rechargeSplit.toFixed(2))
        } else if (addonId.includes(NUMBERS)) {
          defaultCreditSplit[NUMBERS] = Number(rechargeSplit.toFixed(2))
        }
      })
    }
  })
  return defaultCreditSplit
}

const getSumOfCallerSplit = (numbersCredits, inBoundCredits, outBoundCredits) => {
  return Number(numbersCredits + inBoundCredits + outBoundCredits).toFixed(2)
}

const getUpdatedCredit = (value, price, shouldIncreaseVal, creditValue) => {
  return shouldIncreaseVal
    ? value + price <= creditValue
      ? value + price
      : value
    : value - price >= 0
    ? value - price
    : value
}

const getIncreasedDecreasedSliderVal = (
  shouldIncreaseVal,
  creditValue,
  price,
  pcs,
  inBoundCredits,
  numbersCredits,
  outBoundCredits
) => {
  let updatedSplit = {
    [INBOUND]: inBoundCredits,
    [OUTBOUND]: outBoundCredits,
    [NUMBERS]: numbersCredits
  }

  if (pcs == INBOUND_CREDITS) {
    updatedSplit[INBOUND] = getUpdatedCredit(inBoundCredits, price, shouldIncreaseVal, creditValue)
  } else if (pcs == OUTBOUND_CREDITS) {
    updatedSplit[OUTBOUND] = getUpdatedCredit(
      outBoundCredits,
      price,
      shouldIncreaseVal,
      creditValue
    )
  } else if (pcs == NUMBERS_CREDITS) {
    updatedSplit[NUMBERS] = getUpdatedCredit(numbersCredits, price, shouldIncreaseVal, creditValue)
  }
  return updatedSplit
}

export { getDefaultSplit, getSumOfCallerSplit, getIncreasedDecreasedSliderVal, PHONE_CREDIT_SPLIT }
