import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MemoryRouter, BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'emotion-theming'
import { IntlProvider } from 'react-intl'

import IntlContext from './components/IntlContext'
import { ToastContext } from './components/Toast'
import { CONST, flattenMessages, getUrlPrefix } from './utils'
import LanguageProvider from './utils/LanguageProvider'

class AppWrapper extends Component {
  constructor() {
    super()
    this.basePathName = getUrlPrefix('/')
  }

  render() {
    const { theme, children, initialRoute = '', state = {} } = this.props
    /**
     * @Author Sumeet Sood
     * @Desc When array of objects is used as value of initialEntries then path matching do not take any account query
     * params and Addons component is not loaded in Bills which causes Addons.test.js to fail.
     * added this condition to use object syntax only when some state is passed
     * @Todo Check if this confition is required react-router version is updated
     */
    let initialEntries = []
    if (Object.keys(state).length === 0) initialEntries = [initialRoute]
    else initialEntries = [{ pathname: initialRoute, state }]
    return (
      <ThemeProvider theme={theme}>
        <ToastContext>
          <LanguageProvider>
            {({ locale, messages }) => (
              <IntlProvider
                locale={locale}
                defaultLocale={CONST.DEFAULT_LANGUAGE}
                messages={flattenMessages(messages)}>
                <IntlContext>
                  {/* initialRoute string is sent by TestWrapper,
                because certain tests needs to be start with a initial route */}
                  {initialRoute === '' ? (
                    <BrowserRouter basename={this.basePathName}>{children}</BrowserRouter>
                  ) : (
                    <MemoryRouter initialEntries={initialEntries}>{children}</MemoryRouter>
                  )}
                </IntlContext>
              </IntlProvider>
            )}
          </LanguageProvider>
        </ToastContext>
      </ThemeProvider>
    )
  }
}

AppWrapper.propTypes = {
  theme: PropTypes.object.isRequired,
  initialRoute: PropTypes.string
}

export default AppWrapper
