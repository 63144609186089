import React, { useContext, useEffect, useRef } from 'react'
import delve from 'dlv'
import { CONST } from './../../utils'

import PROFILE_FIELDS_DEF, { FIELD_NAME_PROFILE_PICTURE } from './ProfileFieldDefinitions'
import { AppStateContext } from '../../components/AppDataProvider'
import { initialStateOfProfileFieldsContext, useProfileFieldsContext } from './ProfileFieldsContext'
import { FreshworksStyledTextInput } from '../../components/FormikField/FormikField'
import TextInput from '../../components/FormikField/TextInput'

export const ProfileFields = ({ children, user = null, skipEnforcingRestrictions = false }) => {
  const {
    restrictions: { restrictionLevel, restrictedFields },
    updateRestrictionConfig
  } = useProfileFieldsContext() || initialStateOfProfileFieldsContext
  const profileFields = [...PROFILE_FIELDS_DEF]
  const fieldsList = getFieldsList(profileFields)
  const fieldListToBeSentToServer = getCanBeSentToServerFieldsArray(fieldsList)
  let isProfilePictureEditable = true
  const { getCurrentUserDetails } = useContext(AppStateContext)
  // user object is passed only in activation page.
  // Activation Page we won't get the current user details.
  // If the user object is not present then we take the admin value from getCurrentUserDetails.
  let isAdminUser = user ? user.admin : delve(getCurrentUserDetails, 'data.user.admin', false)
  let allowedFields = []
  if (restrictionLevel === CONST.USER_PROFILE_RESTRICTION_LEVELS.RESTRICTION_ALLOW_SOME) {
    allowedFields = getAllowUpdateFields(restrictedFields, fieldListToBeSentToServer)
    if (!isAdminUser && allowedFields.indexOf(FIELD_NAME_PROFILE_PICTURE) === -1) {
      isProfilePictureEditable = false
    }
  } else if (
    !isAdminUser &&
    restrictionLevel === CONST.USER_PROFILE_RESTRICTION_LEVELS.RESTRICTION_ALLOW_NONE
  ) {
    isProfilePictureEditable = false
  }
  let allowedFieldsMap = {}
  allowedFields.forEach((field) => {
    allowedFieldsMap[field] = true
  })
  //Restriction level enforced for users only.. not admins
  const allFieldsAllowed =
    !!isAdminUser ||
    !!skipEnforcingRestrictions ||
    restrictionLevel === CONST.USER_PROFILE_RESTRICTION_LEVELS.RESTRICTION_ALLOW_ALL
  let isAnyFieldEditable = false
  profileFields.forEach((fieldGroup) => {
    fieldGroup.fields.forEach((field) => {
      field.isEditable = allFieldsAllowed || !!allowedFieldsMap[field.name]
      if (!isAnyFieldEditable && field.isEditable) {
        isAnyFieldEditable = true
      }
    })
  })
  return (
    <>
      {children &&
        children({
          restrictionLevel,
          profileFields,
          isAnyFieldEditable,
          isProfilePictureEditable,
          updateRestrictionConfig,
          fieldListToBeSentToServer,
          fieldsList
        })}
    </>
  )
}

export const DropdownDisabledInput = ({ name, value }) => {
  const inputRef = useRef(null)
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = value
    }
  })
  return (
    <FreshworksStyledTextInput _type="text" _disabled={true}>
      <TextInput ref={inputRef} name={`${name}-disabled-placeholder`} disabled />
    </FreshworksStyledTextInput>
  )
}

//Array of field names that can be sent to server
//This is derived from profile fields
//Email Field is disabled by default and should not be sent to BE
export const getCanBeSentToServerFieldsArray = (fieldsList) =>
  fieldsList.filter((field) => field.shouldSendToServer).map((field) => field.name)

const getAllowUpdateFields = (restrictedFields, totalFieldsArray) =>
  totalFieldsArray.filter((val) => !restrictedFields.includes(val))

export const getFieldsList = (profileFields) =>
  profileFields
    .map(({ fields }) => [...fields])
    .reduce((accumulator, currentItem) => [...accumulator, ...currentItem], [])
