import React from 'react'
import Popover from '../Popover/Popover'
import { Box } from './../Grid'
import Button from '../Button'
import { css } from 'emotion'
import { IMAGE_CDN_URL } from '../../config'
import { styledWithRTL } from '../../utils/styles'
import { changeToRTLStrings } from '../../utils'
import { useLocaleState } from '../../utils/LanguageProvider/LanguageProvider'

const overridePadding = css({
  padding: '8px 14px'
})

const Trigger = () => {
  return (
    <Box
      className={css({
        width: '35px',
        height: '35px'
      })}>
      <Button type="secondary" overrideStyleClassName={overridePadding} data-testid="menu-trigger">
        <img
          src={IMAGE_CDN_URL`more_options.svg`}
          className={css({
            top: '2px',
            position: 'relative'
          })}
        />
      </Button>
    </Box>
  )
}

const Item = styledWithRTL('div', { label: 'Item' })`
  padding: 8px;
  border-radius: 2px;
  :hover {
    background-color: ${({
      theme: {
        palette: { s }
      }
    }) => s[25]};
  }
`

const Header = styledWithRTL('div', { label: 'Header' })`
  margin: -13px -8px 8px -8px;
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.background.light};
  cursor: default;
`
const Menu = ({ children, trigger, position, offsetX = 0, offsetY = 4, ...rest }) => {
  const [{ isRTLLanguage }] = useLocaleState()
  return (
    <Popover
      {...rest}
      position={isRTLLanguage ? changeToRTLStrings(position) : position}
      trigger={trigger}
      offsetY={offsetY}
      offsetX={offsetX}
      arrow={false}
      aria-labelledBy="pop-up"
      size="auto">
      {(...args) => {
        return children(...args)
      }}
    </Popover>
  )
}

Menu.Item = (props) => <Item {...props}>{props.children}</Item>
Menu.Header = (props) => <Header {...props}>{props.children}</Header>
Menu.displayName = 'Menu'
Menu.Item.displayName = 'Menu.Item'
Menu.Header.displayName = 'Menu.Header'

Menu.defaultProps = {
  position: 'bottom center',
  trigger: Trigger
}

export default Menu
