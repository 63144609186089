import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'react-emotion'
import { Box } from './../Grid'
import { rgba } from 'polished'
import { useIntl } from '../IntlContext'

const rotate = keyframes`
  to { transform: rotate(360deg); }
`

const POSSIBLE_SIZES = {
  small: 16,
  medium: 32,
  big: 40
}

const ANIMATION_DURATION = 0.75

const BORDER_WIDTH = {
  small: 3,
  medium: 5,
  big: 6
}

const StyledLoader = styled(Box)`
  ${({
    theme: {
      colors: { primary, white }
    },
    size,
    color,
    filled,
    invert = true
  }) => `
    position: relative;
    width: ${POSSIBLE_SIZES[size]}px;
    height: ${POSSIBLE_SIZES[size]}px;
    border: ${BORDER_WIDTH[size]}px solid ${rgba(invert ? white : primary, 0.4)};
    border-radius: 50%;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      border: ${BORDER_WIDTH[size]}px solid;
      top: -${BORDER_WIDTH[size]}px;
      left: -${BORDER_WIDTH[size]}px;
      border-color: ${invert ? white : primary} transparent transparent transparent;
      border-radius: 50%;
      animation: ${rotate} ${ANIMATION_DURATION}s infinite linear;
    }

    ${
      filled
        ? `
          &::before {
            content: '';
            position: absolute;
            width: 200%;
            height: 200%;
            background-color: ${invert ? primary : white};
            border-radius: 50%;
            transform: translateX(-25%) translateY(-25%);
            transform-origin: top bottom;
            transform-origin: center;
            ${filled ? `box-shadow: 0px 0px 4px 1px ${rgba(primary, 0.2)}` : ''}
          }
          `
        : null
    }

  `};
`

export default function Loader(props) {
  const { toIntlString } = useIntl()
  return (
    <StyledLoader
      role="alert"
      aria-label={toIntlString('common_login.page_loading')}
      data-testid="loading"
      {...props}
    />
  )
}

Loader.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'big']),
  filled: PropTypes.bool,
  invert: PropTypes.bool
}

Loader.defaultProps = {
  size: 'medium',
  filled: false,
  invert: false
}
