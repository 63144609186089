import React, { useEffect, useState } from 'react'
import { adopt } from 'react-adopt'
import delve from 'dlv'
import dset from 'dset'
import { matchPath, useLocation } from 'react-router'

import {
  checkIfPortalAccountUser,
  CONST,
  getRedirectUriFromError,
  getUserLanguageFromLocalStorage,
  getUserLanguageFromLocalStorageOrFallbackToDefault,
  heapAddUserProperties,
  isRTLLanguage,
  isValueUndefinedOrNull,
  setUserLanguageToLocalStorage
} from '../../utils'
import { useLocaleState } from '../../utils/LanguageProvider'
import { appStore } from '../../App'
import ROUTE_CONFIG, { ACTIVITY_CONSTANTS } from '../../utils/route-config'
import { Box } from '../Grid'
import orgUtils, { orgConfigInterceptor } from '../../utils/orgUtils'
import FullLoader from '../Loader/FullLoader'
import {
  AUTH_CONST,
  getValidatedAuthMethods,
  responseDataInterceptorAuthentications
} from '../../utils/auth-methods'

import DataManager from '../DataManager'
import { Fade } from '../Transitions'

import ErrorBoundary from './ErrorBoundary'
import AppStateContext, { AppStateUpdateContext } from './AppStateContext'
import { TPSLoader } from '../TPSLoader/TPSLoader'
import { USER_SESSION_DOES_NOT_EXIST } from '.'
import { canShowSubscriptionIconInNavBar } from '../../pages/Bills/utils'
import { DEFAULT_PERMISSIONS_LIST } from '../../pages/Users/AdminCenterRoles/utils'

const {
  ERROR_STRINGS: { ORGANISATION_DOMAIN_UPDATED },
  RESPONSE_STATUSES
} = CONST

const {
  GET_ACCOUNTS,
  GET_CLOUD_TYPES,
  GET_CURRENT_USER,
  GET_LOGIN_METHODS,
  GET_ORG_BUNDLES,
  GET_ORG_SUMMARY,
  GET_PRODUCTS
} = ACTIVITY_CONSTANTS

const PathConditionalRenderer = ({ canRenderWithoutData, render, children }) => {
  if (canRenderWithoutData) {
    return render()
  }
  return children
}
const isDomainUpdated = (error) =>
  error.status === RESPONSE_STATUSES.ERRORS.BAD_REQUEST &&
  delve(error, 'data.error', '') === ORGANISATION_DOMAIN_UPDATED

const isPrevLangRTL = isRTLLanguage(getUserLanguageFromLocalStorageOrFallbackToDefault())

const getAxiosParams = (search) => {
  let allUrlQueryParams = {}
  for (let [key, value] of new URLSearchParams(search).entries()) {
    allUrlQueryParams[key] = value
  }
  return { params: allUrlQueryParams }
}

const AuthenticationMethodsFailed = ({ error }) => {
  if (error.status === CONST.RESPONSE_STATUSES.ERRORS.NOT_FOUND) {
    return (
      <orgUtils.IsDomainAvailable
        onSuccess={() => {
          throw new Error(AUTH_CONST.ORG_DOMAIN_BEING_SETUP)
        }}
      />
    )
  }
  throw new Error(AUTH_CONST.AUTHENTICATIONS_METHOD_FAILED)
}

const mandatoryData = {
  [GET_PRODUCTS]: {
    activity: GET_PRODUCTS,
    onSuccess: (data) => {
      appStore.setProductList(data.data.productList)
    },
    retryStrategy: [500, 1000, 2000]
  },
  [GET_ORG_SUMMARY]: {
    shouldCache: true,
    activity: GET_ORG_SUMMARY,
    defaultCacheSuccessResponseHandler: ({ data }) => data,
    defaultCacheErrorResponseHandler: () => ({}),
    onSuccess: ({ data }) => {
      const region = delve(data, 'organisation.regionCode')
      if (region) {
        heapAddUserProperties({ region })
      }
    }
  },
  [GET_ORG_BUNDLES]: {
    shouldCache: true,
    activity: GET_ORG_BUNDLES,
    defaultCacheSuccessResponseHandler: ({ data }) => data,
    defaultCacheErrorResponseHandler: () => ({}),
    axiosConfig: { params: { include: 'bundle_types' } }
  },
  [GET_ACCOUNTS]: {
    shouldCache: true,
    activity: GET_ACCOUNTS,
    defaultCacheSuccessResponseHandler: ({ data }) => data,
    defaultCacheErrorResponseHandler: () => ({})
  },
  [GET_CLOUD_TYPES]: {
    shouldCache: true,
    activity: GET_CLOUD_TYPES,
    defaultCacheSuccessResponseHandler: ({ data }) => data,
    defaultCacheErrorResponseHandler: () => ({})
  },
  [GET_LOGIN_METHODS]: {
    shouldCache: true,
    activity: GET_LOGIN_METHODS,
    defaultCacheSuccessResponseHandler: ({ data }) => {
      const authModuleConfig = getValidatedAuthMethods(data)
      return authModuleConfig
    },
    responseDataInterceptor: responseDataInterceptorAuthentications,
    retryStrategy: [1000, 2000, 4000]
  }
}

const getCurrUserData = {
  [GET_CURRENT_USER]: {
    activity: GET_CURRENT_USER,
    defaultCacheErrorResponseHandler: () => USER_SESSION_DOES_NOT_EXIST,
    onError: (error) => {
      // if org domain is updated and user accesses old domain, catch the
      // backend error response and check if response is `HTTP 400` & error
      // reads `ORGANISATION_DOMAIN_UPDATED` then redirect implicitly to new domain
      if (isDomainUpdated(error)) {
        window.location.assign(getRedirectUriFromError(error))
        return
      }
      if (window.Sentry && window.Sentry.configureScope) {
        window.Sentry.configureScope((scope) => {
          scope.setTag('Heap User ID', window.heap && window.heap.userId)
        })
      }
    },
    axiosConfig: { params: { include: 'products' } },
    onSuccess: (response) => {
      const userID = delve(response, 'data.user.id')
      // track the user with unique id for Heap and Sentry
      if (window.heap && window.heap.track) {
        window.heap.identify(userID)
      }
      if (window.Sentry && window.Sentry.configureScope) {
        window.Sentry.configureScope((scope) => {
          scope.setTag('Heap User ID', window.heap && window.heap.userId)
          scope.setTag('Heap Identity FreshID User ID', userID)
        })
      }
      const isPortalAccountUser = checkIfPortalAccountUser(
        response.data.user,
        response.data.productList
      )
      dset(response, 'data.user.isPortalAccountUser', isPortalAccountUser)
      if (!response.data.productList) {
        dset(response, 'data.productList', [])
      }
      if (!response.data.bundleTypeList) {
        dset(response, 'data.bundleTypeList', [])
      }
      let permissions = delve(response, `data.user.roleInfo.orgPrivileges`, [])
      const firstPrivilege = delve(permissions, `0`, '')
      if (firstPrivilege in DEFAULT_PERMISSIONS_LIST) {
        permissions = DEFAULT_PERMISSIONS_LIST[firstPrivilege]
      }
      dset(response, 'data.user.roleInfo.orgPrivileges', permissions)
    }
  }
}

const mandatoryDataKeys = Object.keys(mandatoryData)

const getMatchingRouteConfig = (pathName) => {
  const matchingRouteConfig = ROUTE_CONFIG.filter((routeConfig) =>
    matchPath(pathName, {
      path: routeConfig.routePath,
      exact: true,
      strict: false
    })
  )
  return matchingRouteConfig.length ? matchingRouteConfig[0] : {}
}

const shouldIgnoreData = (dataToFetch, activity) => dataToFetch.indexOf(activity) === -1

const DataSources = adopt({
  getOrgSummary: ({ dataToFetch, render }) => (
    <PathConditionalRenderer
      canRenderWithoutData={shouldIgnoreData(dataToFetch, GET_ORG_SUMMARY)}
      render={render}>
      <DataManager {...mandatoryData[GET_ORG_SUMMARY]}>{render}</DataManager>
    </PathConditionalRenderer>
  ),
  getAllOrgBundles: ({ dataToFetch, render }) => (
    <PathConditionalRenderer
      canRenderWithoutData={shouldIgnoreData(dataToFetch, GET_ORG_BUNDLES)}
      render={render}>
      <DataManager {...mandatoryData[GET_ORG_BUNDLES]}>{render}</DataManager>
    </PathConditionalRenderer>
  ),
  getAccounts: ({ dataToFetch, render }) => (
    <PathConditionalRenderer
      canRenderWithoutData={shouldIgnoreData(dataToFetch, GET_ACCOUNTS)}
      render={render}>
      <DataManager {...mandatoryData[GET_ACCOUNTS]}>{render}</DataManager>
    </PathConditionalRenderer>
  ),
  getAllCloudTypes: ({ dataToFetch, render }) => (
    <PathConditionalRenderer
      canRenderWithoutData={shouldIgnoreData(dataToFetch, GET_CLOUD_TYPES)}
      render={render}>
      <DataManager {...mandatoryData[GET_CLOUD_TYPES]}>{render}</DataManager>
    </PathConditionalRenderer>
  ),
  getAllowedLoginMethods: ({ dataToFetch, axiosConfig, render }) => (
    <PathConditionalRenderer
      canRenderWithoutData={shouldIgnoreData(dataToFetch, GET_LOGIN_METHODS)}
      render={render}>
      <DataManager {...mandatoryData[GET_LOGIN_METHODS]} axiosConfig={axiosConfig}>
        {render}
      </DataManager>
    </PathConditionalRenderer>
  ),
  getAllProducts: ({ dataToFetch, render }) => (
    <PathConditionalRenderer
      canRenderWithoutData={shouldIgnoreData(dataToFetch, GET_PRODUCTS)}
      render={render}>
      <DataManager {...mandatoryData[GET_PRODUCTS]}>{render}</DataManager>
    </PathConditionalRenderer>
  )
})

const isDataReady = (activityStates, dataToFetch) =>
  dataToFetch.every(
    (datumToFetch) =>
      delve(activityStates, `${datumToFetch}.isLoading`) === false &&
      (!isValueUndefinedOrNull(delve(activityStates, `${datumToFetch}.data`)) ||
        !isValueUndefinedOrNull(delve(activityStates, `${datumToFetch}.error`)))
  )
const Composed = adopt({
  getOrgConfig: ({ render }) => (
    <DataManager activity="getOrgConfig" responseDataInterceptor={orgConfigInterceptor}>
      {render}
    </DataManager>
  ),
  getCurrentUserDetails: ({ render }) => (
    <DataManager {...getCurrUserData[GET_CURRENT_USER]}>{render}</DataManager>
  )
})

const AppDataProvider = ({ children }) => {
  return (
    <ErrorBoundary>
      <Composed>
        {(dataFromResponse) => {
          const { getOrgConfig, getCurrentUserDetails } = dataFromResponse
          const { isLoading, data, error } = getOrgConfig
          const isGetCurrUserDetailsLoading = delve(getCurrentUserDetails, 'isLoading', true)

          if (!isLoading && (data || error) && !isGetCurrUserDetailsLoading) {
            const orgConfig = delve(data, 'organisationConfig', {})
            if (data && orgConfig.tpsDisabled === false) {
              return (
                <TPSLoader>
                  {({ isTpsLoading }) => {
                    return isTpsLoading ? (
                      <FullLoader />
                    ) : (
                      <AppDataFetcher
                        organisationConfig={data}
                        app={children}
                        currentUserDetails={getCurrentUserDetails}
                      />
                    )
                  }}
                </TPSLoader>
              )
            }
            return (
              <>
                <AppDataFetcher
                  organisationConfig={data}
                  app={children}
                  currentUserDetails={getCurrentUserDetails}
                />
              </>
            )
          }
          return <FullLoader />
        }}
      </Composed>
    </ErrorBoundary>
  )
}
const AppDataFetcher = ({ app, organisationConfig, currentUserDetails }) => {
  const [state, setState] = useState({
    common: {
      getOrgConfig: organisationConfig
    },
    getCurrentUserDetails: currentUserDetails
  })
  const isAdminPermissionExists = delve(currentUserDetails, 'data.user.admin', true)
  const isCustomRolesExists = delve(currentUserDetails, 'data.user.roleInfo.hasRoles', false)
  const [isNavStateUpdated, setNavStateUpdated] = useState(false)
  const [navState, setNavState] = useState({
    [CONST.NAV_ITEMS.DASHBOARD]: false,
    [CONST.NAV_ITEMS.SECURITY]: false,
    [CONST.NAV_ITEMS.USERS_AND_GROUPS]: false,
    [CONST.NAV_ITEMS.SUBSCRIPTION]: false,
    [CONST.NAV_ITEMS.ORGANIZATION]: false,
    [CONST.NAV_ITEMS.AUDIT_LOGS]: false
  })

  const { pathname, search } = useLocation()
  const [localeConfig, setLocale] = useLocaleState()
  // dataToFetch -- List of APIs which we need to call on initial page load
  const [dataToFetch, setDataToFetch] = useState(null)
  const [shouldIgnoreOrgDetails, setShouldIgnoreOrgDetails] = useState(null)
  const [shouldIgnoreAuthDetails, setShouldIgnoreAuthDetails] = useState(null)

  useEffect(() => {
    const routeConfig = getMatchingRouteConfig(pathname)
    const mandatoryMetaAPIToIgnore = delve(routeConfig, 'mandatoryMetaAPIToIgnore', [])
    const dataToFetch = mandatoryDataKeys.filter(
      (datum) => mandatoryMetaAPIToIgnore.indexOf(datum) === -1
    )
    setDataToFetch(dataToFetch)
    // Ignoring accounts and bundle calls for non admin user
    if (!isAdminPermissionExists && !isCustomRolesExists) {
      setDataToFetch(
        dataToFetch.filter((item) => {
          return item !== 'getAccounts' && item !== 'getAllOrgBundles'
        })
      )
    }

    //hack
    setShouldIgnoreAuthDetails(!dataToFetch.some((element) => element === 'getAllowedLoginMethods'))
    setShouldIgnoreOrgDetails(
      !dataToFetch.some(
        (element) =>
          element === 'getAllOrgBundles' || element === 'getOrgSummary' || element === 'getAccounts'
      )
    )
  }, [pathname, isAdminPermissionExists, isCustomRolesExists])

  /**
   * @Desc This is required to provide handler to refresh activities in the cache
   */
  let refreshCache = {
    getAllowedLoginMethods: {}
  }

  const setNavigationState = (navigationItem, shouldDisplayNavItem) => {
    setNavState({
      ...navState,
      [navigationItem]: shouldDisplayNavItem
    })
  }

  return (
    <AppStateContext.Provider value={{ ...state, navigationState: navState }}>
      <AppStateUpdateContext.Provider value={{ setState, refreshCache, setNavigationState }}>
        {dataToFetch ? (
          <DataSources axiosConfig={getAxiosParams(search)} dataToFetch={dataToFetch}>
            {(dataFromResponse) => {
              const { getAllowedLoginMethods, getAllOrgBundles } = dataFromResponse

              refreshCache.getAllowedLoginMethods = getAllowedLoginMethods
              const isContentReady = isDataReady(dataFromResponse, dataToFetch)
              if (isContentReady) {
                const bundleTypes = delve(getAllOrgBundles, 'data.bundleTypes', [])
                const isAdmin = delve(state.getCurrentUserDetails, 'data.user.admin')
                const id = delve(state.getCurrentUserDetails, 'data.user.id')
                const privilegesArray = delve(
                  state.getCurrentUserDetails,
                  'data.user.roleInfo.orgPrivileges',
                  []
                )
                const privilegesSet = new Set(privilegesArray)

                //getRoute Accessability
                const canShowSubscriptionIcon = canShowSubscriptionIconInNavBar(
                  bundleTypes,
                  id,
                  isAdmin
                )
                if (!isNavStateUpdated) {
                  setNavState(
                    getUpdatedRouteState(isAdmin, canShowSubscriptionIcon, navState, privilegesSet)
                  )
                  setNavStateUpdated(true)
                }

                // when this is loaded in pre-login screens like Login, Set Password
                // we don't get data.user.locale, so defaulting to lang in local storage or fallback to DEFAULT_LANGUAGE
                const lang =
                  delve(state.getCurrentUserDetails, 'data.user.locale') ||
                  getUserLanguageFromLocalStorageOrFallbackToDefault()

                // There is a case where user can change the language on the fly
                // from UserManagementProfile, Activation and other pages
                // So if the language is changed (locally) `hasLocaleChangedOnTheFly === true`,
                // do not respect the server values

                // When the requested language could not load because of network failures
                // LangProvider forces itself to load default language `forceDefaultLang === true`
                // do not respect the server values

                // if we don't check these conditions, we get re-rendering issue

                // p.s. server values will be respected on next refresh of the page
                if (!localeConfig.hasLocaleChangedOnTheFly && !localeConfig.forceDefaultLang) {
                  // only set the language if its non-english
                  // this avoids re-rendering issue
                  if (localeConfig.locale !== lang) {
                    // set the language which we get from the DB
                    setLocale(lang, false)
                  } else {
                    // if the user language is changed from non-english to english
                    // we don't set it to LanguageProvider
                    // so update local storage to english
                    if (getUserLanguageFromLocalStorage() !== lang) {
                      setUserLanguageToLocalStorage(lang)
                    }
                  }
                }
                // Reload page if language associativity is changing
                if (!shouldIgnoreOrgDetails && isPrevLangRTL !== isRTLLanguage(lang)) {
                  setUserLanguageToLocalStorage(lang)
                  window.location.reload()
                  return
                }
              }
              if (!shouldIgnoreAuthDetails && getAllowedLoginMethods.error) {
                return <AuthenticationMethodsFailed error={getAllowedLoginMethods.error} />
              }

              return (
                <>
                  <Fade in={!isContentReady}>
                    <FullLoader />
                  </Fade>

                  <Box width={1}>
                    <Fade in={isContentReady}>{isContentReady ? app : null}</Fade>
                  </Box>
                </>
              )
            }}
          </DataSources>
        ) : null}
      </AppStateUpdateContext.Provider>
    </AppStateContext.Provider>
  )
}

const getUpdatedRouteState = (
  isAdmin,
  canShowSubscription,
  navigationState,
  privileges = new Set()
) => {
  return Object.keys(navigationState).reduce((navItems, key) => {
    if (key === CONST.NAV_ITEMS.DASHBOARD) {
      return {
        ...navItems,
        [key]: true
      }
    } else if (key === CONST.NAV_ITEMS.SUBSCRIPTION) {
      return {
        ...navItems,
        [key]: canShowSubscription
      }
    }
    return {
      ...navItems,
      [key]:
        isAdmin ||
        !!CONST.NAV_ITEMS_PRIVILEGES[key]?.some((readPermission) => {
          return privileges.has(readPermission)
        })
    }
  }, {})
}
export default AppDataProvider
