import axios from 'axios'
import humps from 'humps'
import deepForEach from 'deep-for-each'
import dset from 'dset'
import delve from 'dlv'
import { isStorageAvailable, ENV, heapTrack, getUrlPrefix } from '../../utils'
import CONST from '../../utils/constants'
import MockAdapter from 'axios-mock-adapter'
import activities from './activities'

const initialBaseURL = `${ENV.HOST}${ENV.NAMESPACE}`

const getBaseURL = (defaultBaseURL) => `${getUrlPrefix('')}${defaultBaseURL}`

const getCSRF = () => {
  return (
    (isStorageAvailable(CONST.WEB_STORAGE.SESSION_STORAGE) &&
      window.sessionStorage.getItem(CONST.SESSION_STORAGE_CSRF_KEY)) ||
    null
  )
}

const setCSRF = (response) => {
  let csrfToken = response.headers && response.headers[CONST.CSRF_RESPONSE_HEADER_KEY]
  isStorageAvailable(CONST.WEB_STORAGE.SESSION_STORAGE) &&
    csrfToken &&
    window.sessionStorage.setItem(CONST.SESSION_STORAGE_CSRF_KEY, csrfToken)
}

export const applyMock = () =>
  new MockAdapter(AxiosWrapper.getInstance(), {
    delayResponse: 300
  })

const sendResponseToHeap = (statusCode, config) => {
  const { activityName, isLoggingDisabled } = config

  if (isLoggingDisabled) {
    return
  }
  let eventName = ''

  const firstDigit = statusCode.toString().charAt(0)
  switch (firstDigit) {
    case '2':
      //sends all 2XX as success
      eventName = `api: ${humps.decamelize(activityName)}_success`
      break
    case '4':
      //Sends only 400 as error
      if (statusCode === 400) {
        eventName = `api: ${humps.decamelize(activityName)}_error`
      }
      break
    case '5':
      //Sends all 5XX as error
      eventName = `api: ${humps.decamelize(activityName)}_error`
      break
    default:
  }
  if (eventName) {
    heapTrack(eventName.toUpperCase(), { statusCode })
  }
}

function AxiosInstanceCreator() {
  this.instance = axios.create({
    baseURL: getBaseURL(initialBaseURL),
    timeout: 10000,
    headers: {
      'content-type': 'application/json'
    },
    withCredentials: true
  })

  this.instance.interceptors.request.use(function (config) {
    /**
     * FRESHID-2571 Send `X-XSRF-TOKEN` header as part
     * of all XHR requests to be successfully validated
     */
    let csrfToken = getCSRF()
    if (csrfToken) {
      config.params = config.params || {}
      config.params[CONST.CSRF_REQUEST_PARAM_KEY] = csrfToken
    }

    // Do something before request is sent
    if (config.data) {
      config.data = humps.decamelizeKeys(config.data)
      //check for empty string values in request payload and replace with null
      deepForEach(config.data, (value, key, subject, path) => {
        if (value === '') {
          // replacing props[0] with props.0
          // this string format is required by dset
          path = path.replace(/\[/g, '.').replace(/\]/g, '')
          dset(config.data, path, null)
        }
      })
    }
    // run through the interceptors provided by the component
    config.data = config.__interceptorConfig.requestDataInterceptor(config.data)
    if (delve(config, '__interceptorConfig.getAdditionalHeadersWithPathName', false)) {
      const additionalHeaders = config.__interceptorConfig.getAdditionalHeadersWithPathName() || {}
      config.headers = {
        ...config.headers,
        ...additionalHeaders
      }
    }
    return config
  })
  this.instance.interceptors.response.use(
    function (response) {
      sendResponseToHeap(response.status, response.config.__interceptorConfig)
      setCSRF(response)
      response.data = humps.camelizeKeys(response.data)
      // run through the interceptors provided by the component
      response.data = response.config.__interceptorConfig.responseDataInterceptor(response.data)
      return response
    },
    function (error) {
      if (error && error !== CONST.ERROR_STRINGS.UNEXPECTED_RESPONSE && error.response) {
        sendResponseToHeap(
          delve(error, 'response.status'),
          delve(error, 'response.config.__interceptorConfig', {})
        )
      }
      return Promise.reject(error)
    }
  )
}
const axiosInstance = new AxiosInstanceCreator()
export function AxiosWrapper() {}
AxiosWrapper.getInstance = () => {
  return axiosInstance.instance
}

AxiosWrapper.triggerRequest = (activity, config = {}, data) => {
  const curActivityConfig = activities[activity]
  if (!curActivityConfig) {
    throw new Error('Please send proper activity configuration')
  }
  const { method, url, isLoggingDisabled, getAdditionalHeadersWithPathName } = curActivityConfig
  const { pathFragment = [], __interceptorConfig: interceptorConfig = {},  ...rest } = config
  const axiosConfig = {
    __interceptorConfig: {
      requestDataInterceptor: (arg) => arg,
      responseDataInterceptor: (arg) => arg,
      activityName: activity,
      isLoggingDisabled,
      getAdditionalHeadersWithPathName,
      ...interceptorConfig
    },
    ...rest
  }
  const processedURL = typeof url === 'function' ? url(...pathFragment) : url
  const methodLower = method.toLowerCase()
  if (AxiosWrapper.withData.indexOf(methodLower) > -1) {
    return AxiosWrapper[methodLower](processedURL, data, axiosConfig)
  } else {
    return AxiosWrapper[methodLower](processedURL, axiosConfig)
  }
}
AxiosWrapper.withoutData = ['get', 'head', 'delete', 'options']
AxiosWrapper.withData = ['post', 'put', 'patch']

AxiosWrapper.withData.forEach((withData) => {
  AxiosWrapper[withData] = AxiosWrapper.getInstance()[withData]
})
AxiosWrapper.withoutData.forEach((withoutData) => {
  AxiosWrapper[withoutData] = AxiosWrapper.getInstance()[withoutData]
})
