import React from 'react'
import { css, cx } from 'react-emotion'
import { Box as Rbox, Flex as Rflex } from '@rebass/grid'
import { Box as RboxEm, Flex as RflexEm } from '@rebass/grid/emotion'
import { useLocaleState } from '../../utils/LanguageProvider/LanguageProvider'
import { cssWithRTL } from '../../utils/styles'

const withRTL = (Ele, EmEle) =>
  React.forwardRef(({ ml, mr, pl, pr, className, isRTL, children, ...rest }, ref) => {
    let isRTLLanguage = isRTL
    if (isRTL === undefined) {
      ;[{ isRTLLanguage }] = useLocaleState() || [{}]
    }
    className = cx(
      className || css``,
      cssWithRTL`
			direction: ltr;
		`
    )
    const marginAndPaddingProps = isRTLLanguage
      ? { ml: mr, mr: ml, pl: pr, pr: pl }
      : { ml, mr, pl, pr }
    const props = {
      ...rest,
      ...marginAndPaddingProps,
      className
    }
    if (ref) {
      props.ref = ref
      return <Ele {...props}>{children}</Ele>
    }
    return <EmEle {...props}>{children}</EmEle>
  })

export const Box = withRTL(Rbox, RboxEm)
export const Flex = withRTL(Rflex, RflexEm)
