import React from 'react'
import { Flex, Box } from '../Grid'

import { OneColumnContentBox } from '../Layout'
import { H1, PrimaryText } from '../Typography'
import IntlContext from '../IntlContext'
import Icon from '../Icon'
import { FormattedMessage } from 'react-intl'

const TEXT_ALIGN_CENTER = { textAlign: 'center' }
const LinkExpired = React.memo(() => {
  return (
    <IntlContext.Consumer>
      {({ toIntlString }) => (
        <OneColumnContentBox>
          <OneColumnContentBox.OneColumnContentSection disableFormFocus>
            <Flex flexDirection="column" css={TEXT_ALIGN_CENTER}>
              <Box mt={3}>
                <Icon width={32} name="invite_link_logo" alt={toIntlString('error.error')} />
              </Box>
              <Box>
                <H1>{toIntlString('link_expired.title')}</H1>
              </Box>
              <Box mt={3} mb={4}>
                <PrimaryText>
                  <FormattedMessage
                    id="link_expired.expired_or_no_access"
                    defaultMessage={toIntlString('link_expired.expired_or_no_access')}
                    values={{
                      email: (
                        <a href="mailTo:support@freshworks.com">
                          {toIntlString('error.contact_us')}
                        </a>
                      )
                    }}
                  />
                </PrimaryText>
              </Box>
            </Flex>
          </OneColumnContentBox.OneColumnContentSection>
        </OneColumnContentBox>
      )}
    </IntlContext.Consumer>
  )
})

export default LinkExpired
