export default {
  'en-US': 'English',
  'de-DE': 'Deutsch',
  'fr-FR': 'Français',
  'it-IT': 'Italiano',
  'nl-NL': 'Nederlands',
  'es-ES': 'Español',
  'pl-PL': 'Polski',
  'pt-PT': 'Português',
  'ar-SA': 'عربى',
  'he-IL': 'עברית',
  'ca-ES': 'Català',
  'zh-CN': '中文',
  'zh-TW': '繁體中文',
  'cs-CZ': 'Česky',
  'da-DK': 'Dansk',
  'et-EE': 'Eesti',
  'fi-FI': 'Suomi',
  'hu-HU': 'Magyar',
  'id-ID': 'Bahasa Indonesia',
  'ja-JP': '日本語',
  'ko-KR': '한국어',
  'lv-LV': 'Latviešu',
  'no-NO': 'Norsk',
  'pt-BR': 'Português (Brazil)',
  'ro-RO': 'Română ',
  'ru-RU': 'Русский',
  'sk-SK': 'Slovenčina',
  'sl-SI': 'Slovenščina',
  'es-MX': 'Español (Mexico)',
  'sv-SE': 'Svenska',
  'th-TH': 'Phasa Thai',
  'tr-TR': 'Türkçe',
  'uk-UA': 'Українська',
  'vi-VN': 'Việtnam',
  'cy-GB': 'Cymraeg',
  'hr-HR': 'Hrvatski'
}
// Note to DEVs : when adding a new locale here, please make sure that
// the appropriate momentjs locale import is also done in ./locale.js file
