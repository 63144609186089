import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import DataManager from '../DataManager'
import AuthenticationsFallback from './AuthenticationsFallback'
import { responseDataInterceptorAuthentications, AUTH_CONST } from '../../utils/auth-methods'
import constants from '../../utils/constants'
import { isUrlAbsolute, getUrlPrefix } from '../../utils/utility'

const { SECOND, MINUTE } = constants.DURATION
const AUTHENTICATION_METHODS_POLL_INTERVAL_IN_MS = 70 * SECOND
const MAX_POLL_DURATION_IN_MS = 6 * MINUTE

const getRetryStrategy = (interval, duration) =>
  [...Array(Math.floor(duration / interval))].map((e) => interval)

const OrgSetupInProgressMessage = React.memo(
  ({ redirectTo: redirectPath, history, location: { search } }) => {
    return (
      <Fragment>
        <DataManager
          activity="getAllowedLoginMethods"
          responseDataInterceptor={responseDataInterceptorAuthentications}
          retryStrategy={getRetryStrategy(
            AUTHENTICATION_METHODS_POLL_INTERVAL_IN_MS,
            MAX_POLL_DURATION_IN_MS
          )}
          onSuccess={() => {
            if (!isUrlAbsolute(redirectPath)) {
              return history.push({ pathname: redirectPath })
            }
            window.location.assign(`${redirectPath}${search}`)
          }}></DataManager>
        <AuthenticationsFallback
          type={AUTH_CONST.ORG_DOMAIN_BEING_SETUP}
          orgUrl={`${window.location.host}${getUrlPrefix('')}`}
        />
      </Fragment>
    )
  }
)

OrgSetupInProgressMessage.defaultProps = {
  redirectTo: window.location.origin
}

OrgSetupInProgressMessage.propTypes = {
  redirectTo: PropTypes.string
}

export default withRouter(OrgSetupInProgressMessage)
