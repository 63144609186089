import React from 'react'
import { FormattedMessage } from 'react-intl'

import { Flex, Box } from '../Grid'
import IntlContext from '../IntlContext'
import { H3, PrimaryText } from '../Typography'
import Logo from '../Logo'
import { Fade } from '../Transitions'
import { TOP_MARGIN } from '../Layout/OneColumnContentBox'
import Image from '../Image'
import Loader from '../Loader'
import { AUTH_CONST } from '../../utils/auth-methods'

const TEXT_ALIGN = { textAlign: 'center' }
const {
  AUTHENTICATIONS_METHOD_FAILED,
  NO_AUTHENTICATION_METHODS,
  NO_AUTH_OPTIONS_CONFIGURED,
  ORG_DOMAIN_BEING_SETUP
} = AUTH_CONST

const AuthenticationsFallback = React.memo(({ type, orgUrl = null }) => (
  <IntlContext.Consumer>
    {({ toIntlString }) => (
      <Flex flexDirection="column" width={1} mt={TOP_MARGIN}>
        <Box mb={4} css={TEXT_ALIGN} width={1}>
          {(type !== ORG_DOMAIN_BEING_SETUP && <Logo isIcon size="large" />) || (
            <Image alt="Freshworks" src={'freshworks_title_logo.svg'} width={210} mx="auto" />
          )}
        </Box>

        <Fade in={true}>
          <Box flex mt={6} mx="auto" style={TEXT_ALIGN} width={1}>
            {type === ORG_DOMAIN_BEING_SETUP && (
              <Flex justifyContent="center" mb={30} alignItems="center" mx="auto">
                <Loader />
              </Flex>
            )}
            <Box>
              <H3>
                {type === AUTHENTICATIONS_METHOD_FAILED &&
                  toIntlString('error.auth_methods_failed.title')}
                {type === NO_AUTHENTICATION_METHODS && toIntlString('error.no_auth_methods.title')}
                {type === NO_AUTH_OPTIONS_CONFIGURED &&
                  toIntlString('error.no_auth_options_configured.title')}
                {type === ORG_DOMAIN_BEING_SETUP && (
                  <FormattedMessage
                    id="error.org_domain_being_set_up.title"
                    defaultMessage={toIntlString('error.org_domain_being_set_up.title')}
                    values={{
                      org_domain: <a href={`${window.location.protocol}//${orgUrl}`}>{orgUrl}</a>
                    }}
                  />
                )}
              </H3>
            </Box>
            <Box mt={4} width={1}>
              <PrimaryText>
                {type === AUTHENTICATIONS_METHOD_FAILED && (
                  <FormattedMessage
                    id="error.auth_methods_failed.desc"
                    defaultMessage={toIntlString('error.auth_methods_failed.desc')}
                    values={{
                      support_email: (
                        <a href="mailTo:support@freshworks.com">
                          {toIntlString('error.support_email')}
                        </a>
                      )
                    }}
                  />
                )}

                {type === NO_AUTHENTICATION_METHODS && toIntlString('error.no_auth_methods.desc')}

                {type === ORG_DOMAIN_BEING_SETUP && (
                  <FormattedMessage
                    id="error.org_domain_being_set_up.desc"
                    defaultMessage={toIntlString('error.org_domain_being_set_up.desc')}
                    values={{
                      support_email: (
                        <a href="mailTo:support@freshworks.com">
                          {toIntlString('error.support_email')}
                        </a>
                      )
                    }}
                  />
                )}
              </PrimaryText>
            </Box>
          </Box>
        </Fade>
      </Flex>
    )}
  </IntlContext.Consumer>
))

export default AuthenticationsFallback
