import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'emotion'

const ItemConatinerStyle = css`
  margin: 12px auto;
  text-align: center;
  list-style-type: none;
`

export default function Item({ width, height, children }) {
  return (
    <li
      style={{
        width: `${width}px`,
        height: `${height}px`,
        lineHeight: `${height}px`
      }}
      className={ItemConatinerStyle}>
      {children}
    </li>
  )
}

Item.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  children: PropTypes.node.isRequired
}

Item.defaultProps = {
  width: 36,
  height: 36
}

Item.displayName = 'FreshworksSideNavigation.Item'
