import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled, { css, cx } from 'react-emotion'

import { withDownshift } from './Dropdown'
import Icon from '../Icon'
import { cssWithRTL } from '../../utils/styles'
import { RBACContext } from '../RBACProvider'

const StyledButtonForDropdown = styled('button')`
  ${({ theme, overrideStyles }) => {
    const {
      colors: {
        components: {
          dropdown: {
            button: { disabled }
          }
        },
        pseudo,
        text: { primary }
      },
      palette
    } = theme
    return cx(
      css`
        background: ${palette.white};
        color: ${primary};
        font-size: 14px;
        border: none;
        cursor: pointer;
        outline: none;
        padding: 0;
        border-radius: 2px;
        padding: 8px;
        height: 30px;
        align-items: center;
        box-shadow: 0 0 0 1px rgba(24, 50, 71, 0.1), 0 1px 4px 0 rgba(24, 50, 71, 0.15),
          0 6px 14px 0 rgba(24, 50, 71, 0.05);

        &[disabled] {
          -webkit-box-shadow: none;
          box-shadow: none;
          background: ${disabled.background};
          border: 1px solid ${disabled.border};
          color: ${disabled.color};
          cursor: not-allowed;
        }

        &.focused {
          box-shadow: 0 0px 0px 2px ${pseudo.focus};
        }
      `,
      css(overrideStyles)
    )
  }}};
`

const getSelectedItem = (itemToString, value) => {
  return itemToString ? itemToString(value) : value
}

const getIconName = (isOpen, disabled) => {
  let iconName = isOpen ? 'chevron-down' : 'chevron-up'

  if (disabled) {
    iconName += '-disabled'
  }

  return iconName
}

const getConditionalProps = (labelId) => !!labelId && { 'aria-label': labelId }

class DropdownButton extends Component {
  static contextType = RBACContext

  render() {
    const {
      downshift: { getToggleButtonProps, selectedItem, isOpen },
      children,
      itemToString,
      disabled,
      overrideStyles = {},
      buttonLabelComponent: ButtonLabelComponent,
      labelId = '',
      ...rest
    } = this.props

    const { canUserWrite = true } = this.context || {}

    const buttonLabel = getSelectedItem(itemToString, selectedItem)

    return (
      <StyledButtonForDropdown
        {...getToggleButtonProps({
          onClick: (event) => {
            event.stopPropagation()
          }
        })}
        overrideStyles={overrideStyles}
        innerRef={(containerEl) => (this.el = containerEl)}
        onFocus={() => this.el && this.el.classList.add('focused')}
        onBlur={() => this.el && this.el.classList.remove('focused')}
        disabled={disabled || !canUserWrite}
        {...getConditionalProps(labelId)}
        aria-expanded={isOpen}
        {...rest}>
        {children ? (
          children({
            label: buttonLabel,
            getToggleButtonProps,
            isOpen,
            selectedItem
          })
        ) : (
          <Fragment>
            {ButtonLabelComponent ? (
              <ButtonLabelComponent>{buttonLabel}</ButtonLabelComponent>
            ) : (
              buttonLabel
            )}
            <span className={cssWithRTL`padding-left: 8px;`}>
              <Icon name={getIconName(isOpen, disabled)} width={8} />
            </span>
          </Fragment>
        )}
      </StyledButtonForDropdown>
    )
  }
}

DropdownButton = withDownshift(DropdownButton)

DropdownButton.displayName = 'Dropdown.DropdownButton'

DropdownButton.defaultProps = {
  disabled: false,
  itemToString: (item) => item
}

DropdownButton.propTypes = {
  // an object representing styles
  // these styles will override the existing styles of the components
  overrideStyles: PropTypes.object,
  children: PropTypes.func,
  /**
   * itemToString is needed for button too if `item` is an object,
   * because when the item is selected, downshift return the whole item object
   * so we need to strip the button label value from the item
   *
   * May be is it possible to take this itemToString from the dropdown?
   */
  itemToString: PropTypes.func,
  disabled: PropTypes.bool,
  /**
   * Wraps button label with this component if provided
   * by default its a simple string
   */
  buttonLabelComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
}

export default DropdownButton
