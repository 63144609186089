import React, { Component } from 'react'
import Popup from 'reactjs-popup'
import { DropDownText } from '../Typography'
import PropTypes from 'prop-types'

const POPOVER_SIZE = {
  small: '160px',
  medium: '200px',
  large: '286px',
  auto: 'auto'
}

const contentStyle = {
  borderRadius: '2px',
  lineHeight: '30px',
  paddingTop: '10px',
  paddingRight: '8px',
  paddingBottom: '10px',
  paddingLeft: '8px',
  textAlign: 'left',
  fontSize: '12px',
  border: 'none',
  cursor: 'pointer',
  boxShadow:
    '0 0 0 1px rgba(24,50,71,0.1), 0 1px 4px 0 rgba(24,50,71,0.15), 0 6px 14px 0 rgba(24,50,71,0.05)',
  whiteSpace: 'nowrap'
}

const arrowStyle = {
  height: '7px',
  width: '7px',
  top: '17px',
  boxShadow: 'none',
  borderBottom: '1px solid rgba(24, 50, 71, 0.1)',
  borderRight: '1px solid rgba(24, 50, 71, 0.1)',
  margin: '-5px'
}

const overlayStyle = {
  zIndex: 1
}

class Popover extends Component {
  static Item = (props) => <DropDownText {...props}>{props.children}</DropDownText>

  render() {
    const {
      children,
      on,
      open,
      size,
      customArrowStyle,
      customContentStyle = {},
      ...rest
    } = this.props
    let props = { ...this.props }
    if (on && open !== undefined) {
      console.warn(
        `WARNING: Cannot use "on" and "open" props together in <Popover/> component, "on" takes the precedence.`
      )
      props = { on, ...rest }
    }

    // TODO: See if there is a better way to do it?
    contentStyle.width = POPOVER_SIZE[size]

    return (
      <Popup
        {...props}
        contentStyle={{ ...contentStyle, ...customContentStyle }}
        arrowStyle={customArrowStyle || arrowStyle}
        overlayStyle={overlayStyle}
        closeOnEscape={true}>
        {children}
      </Popup>
    )
  }
}

Popover.Item.displayName = 'Popover.Item'

Popover.defaultProps = {
  size: 'medium',
  position: 'right top'
}

Popover.propTypes = {
  /**
   * specify the width of the Popover
   */
  size: PropTypes.oneOf(['small', 'medium', 'large', 'auto'])
}

export default Popover
