import React, { Component, createContext, createRef } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import { Flex, Box } from './../Grid'

import { scrollTop } from '../../utils/utility'
import Header, { HEADER_HEIGHT } from '../Header'
import { styledWithRTL } from '../../utils/styles'
import { css } from 'emotion'
import { SIDEBAR_WIDTH } from '../FreshworksSideNavigation'

export const CONTENT_WIDTH = 672
export const CONTENT_PADDING_LEFT = 56
export const FOOTER_HEIGHT = 65
const HELP_SECTION_PADDING_TOP = 56
const MARGIN_TOP = '8px'
const MARGIN_X = '8px'
const HELP_SECTION_WIDTH = '340px'

const PrimaryLayoutContext = createContext()

function PrimaryContentHelpSection({ children }) {
  const HelpSectionContainer = styledWithRTL(Box, {
    label: 'HelpSectionContainer'
  })`
  ${({ theme: { palette } }) =>
    `
    padding-bottom: 24px;
    padding-top: ${HELP_SECTION_PADDING_TOP}px;
    height: calc(100vh - ${HEADER_HEIGHT}px - ${MARGIN_TOP});
    width: ${HELP_SECTION_WIDTH};
    background-color: ${palette.s[25]};
    `}`
  return (
    <PrimaryLayoutContext.Consumer>
      {({ helpSection: helpSectionEl }) => {
        return helpSectionEl
          ? createPortal(
              <HelpSectionContainer>
                <Box
                  width={1}
                  css={{
                    padding: '0 24px',
                    height: '100%',
                    overflow: 'auto'
                  }}>
                  {children}
                </Box>
              </HelpSectionContainer>,
              helpSectionEl
            )
          : null
      }}
    </PrimaryLayoutContext.Consumer>
  )
}

const FooterContainer = styledWithRTL(Box, {
  label: 'FooterContainer'
})`
  padding-left: ${CONTENT_PADDING_LEFT}px;
  bottom: 0;
  ${({ theme: { colors } }) =>
    `
      border-top: 1px solid ${colors.border.secondary};
    `}
`

// FRESHID-2954 Without fixed width for PrimaryContentBox
// SideNav will have cramped width at higher resolutions.
const PrimaryContainerStyles = css`
  width: calc(100vw - ${SIDEBAR_WIDTH}px - 2 * ${MARGIN_X});
`

const contentContainerStyles = css`
  height: 100vh;
  overflow: hidden;
`

export default class PrimaryContentBox extends Component {
  static propTypes = {
    showHeader: PropTypes.bool,
    showFooter: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    contentWidth: PropTypes.string
  }

  static defaultProps = {
    showHeader: false,
    showFooter: true
  }

  static PrimaryContentHelpSection = PrimaryContentHelpSection

  state = {
    footerEl: null,
    helpSectionEl: null
  }

  footerElRef = createRef()
  helpSectionRef = createRef()
  mainContainerElRef = createRef()

  static Footer = PrimaryLayoutContext

  componentDidMount() {
    this.setState({ footerEl: this.footerElRef.current })
    this.setState({ helpSectionEl: this.helpSectionRef.current })
  }

  scrollPrimaryContainerToTop() {
    scrollTop(this.mainContainerElRef.current)
  }

  getContentWidth() {
    return this.props.contentWidth ? this.props.contentWidth : `${CONTENT_WIDTH}px`
  }

  render() {
    const { showHeader, showFooter, children, className, ...rest } = this.props

    return (
      <Flex flexDirection="column" className={contentContainerStyles}>
        {showHeader && <Header />}
        <Box
          flex="1 1 auto"
          mt={MARGIN_TOP}
          mx={2}
          className={className}
          css={{
            position: 'relative',
            backgroundColor: 'white',
            borderRadius: '4px'
          }}
          {...rest}>
          <Flex justifyContent="space-between" className={PrimaryContainerStyles}>
            <Box width={1}>
              <Flex flexDirection="column">
                <Box
                  role="main"
                  pl={`${CONTENT_PADDING_LEFT}px`}
                  width={1}
                  css={{
                    height: `calc(100vh - ${showHeader ? HEADER_HEIGHT : 0}px - ${
                      showFooter ? FOOTER_HEIGHT : 0
                    }px - ${MARGIN_TOP})`,
                    overflow: 'auto'
                  }}
                  innerRef={this.mainContainerElRef}>
                  <PrimaryLayoutContext.Provider
                    value={{
                      el: this.state.footerEl,
                      helpSection: this.state.helpSectionEl
                    }}>
                    <Box
                      css={{
                        width: this.getContentWidth()
                      }}>
                      {typeof children === 'function'
                        ? children({
                            scrollPrimaryContainerToTop: this.scrollPrimaryContainerToTop.bind(this)
                          })
                        : children}
                    </Box>
                  </PrimaryLayoutContext.Provider>
                </Box>
                {showFooter && (
                  <FooterContainer width={1}>
                    <div
                      style={{
                        width: this.getContentWidth(),
                        height: `${FOOTER_HEIGHT}px`,
                        backgroundColor: 'white'
                      }}
                      ref={this.footerElRef}
                      id="primary-content-footer-placeholder"
                    />
                  </FooterContainer>
                )}
              </Flex>
            </Box>
            <Box innerRef={this.helpSectionRef} />
          </Flex>
        </Box>
      </Flex>
    )
  }
}
