import CONST from '../../../utils/constants'
import SECURITY_FIELDS_SERVER_ERRORS from './SecurityServerErrors'
import { SECURITY_AGENTS_STR } from '../utils'

const { SSO_SUPPORTED_INPUT_TYPES, UNSPECIFIED_IDP_TYPE } = CONST
const NAME_ID_FORMAT_DROPDOWN_VALUES = {
  EMAIL_ADDRESS: 'EMAIL_ADDRESS',
  UNSPECIFIED: 'UNSPECIFIED'
}
const SAML_SIGNATURE_VALUES = {
  RSA_SHA_256: 'SHA256_WITH_RSA',
  RSA_SHA_512: 'SHA512_WITH_RSA',
  RSA_SHA_1: 'SHA1_WITH_RSA'
}
// For Signing Algorithms, Shibboleth does not support SHA-512.
// Note: while adding new SAML_SIGNATURE_VALUES enum ensure that if this is supported by Shibboleth
const { RSA_SHA_512, ...SAML_SIGNATURE_VALUES_WITHOUT_SHA512 } = SAML_SIGNATURE_VALUES

const HTTP_BINDING_VALUES = {
  HTTP_POST: 'HTTP_POST',
  HTTP_REDIRECT: 'HTTP_REDIRECT'
}

const SAML_BASIC_FIELDS = [
  {
    name: 'assertion_url',
    path: 'config',
    disabled: true,
    type: SSO_SUPPORTED_INPUT_TYPES.TEXT,
    canSendToServerOnCreateModule: false,
    canShowInUI: true,
    defaultValue: '',
    isCopyOnly: true, //shown to the customer for copying
    idpOverrides: {
      AZURE_AD: {
        overrideLabel: true
      },
      ADFS: {
        overrideLabel: true
      },
      OKTA: {
        canShowInUI: false
      }
    }
  },
  {
    name: 'entity_url',
    path: 'config',
    disabled: true,
    type: SSO_SUPPORTED_INPUT_TYPES.TEXT,
    canSendToServerOnCreateModule: false,
    canShowInUI: true,
    defaultValue: '',
    isCopyOnly: true, //shown to the customer for copying
    idpOverrides: {
      AZURE_AD: {
        overrideLabel: true
      },
      ADFS: {
        overrideLabel: true
      },
      OKTA: {
        canShowInUI: false
      }
    }
  },
  {
    name: 'id',
    path: '',
    disabled: true,
    type: SSO_SUPPORTED_INPUT_TYPES.TEXT,
    canSendToServerOnCreateModule: false,
    canShowInUI: false,
    defaultValue: '',
    isCopyOnly: true, //shown to the customer for copying
    idpOverrides: {
      OKTA: {
        canShowInUI: true
      }
    }
  },
  {
    name: 'entity_id',
    path: 'config',
    disabled: false,
    type: SSO_SUPPORTED_INPUT_TYPES.TEXT,
    canSendToServerOnCreateModule: true,
    canShowInUI: true,
    defaultValue: '',
    required: true,
    isCopyOnly: false,
    idpOverrides: {
      ONELOGIN: {
        overrideLabel: true
      },
      AZURE_AD: {
        overrideLabel: true
      },
      ADFS: {
        overrideLabel: true
      }
    },
    supportedErrors: [SECURITY_FIELDS_SERVER_ERRORS.EMPTY_ENTITY_ID]
  },
  {
    name: 'sso_url',
    path: 'config',
    disabled: false,
    type: SSO_SUPPORTED_INPUT_TYPES.TEXT,
    canSendToServerOnCreateModule: true,
    canShowInUI: true,
    defaultValue: '',
    required: true,
    isCopyOnly: false,
    idpOverrides: {
      ONELOGIN: {
        overrideLabel: true
      },
      AZURE_AD: {
        overrideLabel: true
      }
    },
    supportedErrors: [
      SECURITY_FIELDS_SERVER_ERRORS.EMPTY_SSO_URL,
      SECURITY_FIELDS_SERVER_ERRORS.INVALID_SSO_URL
    ]
  },
  {
    name: 'signing_options',
    path: 'config',
    disabled: false,
    type: SSO_SUPPORTED_INPUT_TYPES.DROPDOWN,
    dropdownValues: [
      'ASSERTION_SIGNED_MESSAGE_SIGNED',
      'ASSERTION_SIGNED_MESSAGE_UNSIGNED',
      'ASSERTION_UNSIGNED_MESSAGE_SIGNED'
    ],
    canSendToServerOnCreateModule: true,
    canShowInUI: true,
    defaultValue: 'ASSERTION_UNSIGNED_MESSAGE_SIGNED',
    required: true,
    isCopyOnly: false,
    idpOverrides: {
      ADFS: {
        canShowInUI: false,
        defaultValue: 'ASSERTION_SIGNED_MESSAGE_UNSIGNED'
      },
      OKTA: {
        defaultValue: 'ASSERTION_SIGNED_MESSAGE_SIGNED'
      }
    }
  },
  {
    name: 'logout_url',
    path: 'config',
    disabled: false,
    type: SSO_SUPPORTED_INPUT_TYPES.TEXT,
    canSendToServerOnCreateModule: true,
    canShowInUI: true,
    defaultValue: '',
    required: false,
    isCopyOnly: false
  },
  {
    name: 'idp_certificate',
    path: 'config',
    disabled: false,
    large: true,
    type: SSO_SUPPORTED_INPUT_TYPES.TEXT_AREA,
    canSendToServerOnCreateModule: true,
    canShowInUI: true,
    defaultValue: '',
    required: true,
    isCopyOnly: false,
    idpOverrides: {
      ONELOGIN: {
        overrideLabel: true
      },
      AZURE_AD: {
        overrideLabel: true
      }
    },
    supportedErrors: [
      SECURITY_FIELDS_SERVER_ERRORS.EMPTY_IDP_CERTIFICATE,
      SECURITY_FIELDS_SERVER_ERRORS.INVALID_SAML_IDP_CERTIFICATE
    ]
  },
  {
    name: 'idp_type',
    path: 'config',
    disabled: false,
    type: SSO_SUPPORTED_INPUT_TYPES.TEXT,
    canSendToServerOnCreateModule: true,
    defaultValue: UNSPECIFIED_IDP_TYPE,
    canShowInUI: false,
    required: false
  }
]

const SAML_ADVANCED_FIELDS = [
  {
    name: 'name_id_format',
    path: 'config',
    disabled: false,
    type: SSO_SUPPORTED_INPUT_TYPES.DROPDOWN,
    dropdownValues: [
      NAME_ID_FORMAT_DROPDOWN_VALUES.UNSPECIFIED,
      NAME_ID_FORMAT_DROPDOWN_VALUES.EMAIL_ADDRESS
    ],
    // Since the options of dropdown are not pure text intl is not needed
    dropdownOptions: {
      EMAIL_ADDRESS: 'urn:oasis:names:tc:SAML:1.1:nameid-format:emailAddress',
      UNSPECIFIED: 'urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified'
    },
    canSendToServerOnCreateModule: true,
    canShowInUI: true,
    defaultValue: NAME_ID_FORMAT_DROPDOWN_VALUES.UNSPECIFIED,
    required: true,
    isCopyOnly: false,
    isDescriptionLess: true,
    idpOverrides: {
      ONELOGIN: {
        canShowInUI: false
      },
      AZURE_AD: {
        canShowInUI: false
      },
      SHIBBOLETH: {
        canShowInUI: false,
        defaultValue: NAME_ID_FORMAT_DROPDOWN_VALUES.EMAIL_ADDRESS
      },
      ADFS: {
        canShowInUI: false
      },
      OKTA: {
        canShowInUI: false
      }
    }
  },
  {
    name: 'want_assertions_encrypted',
    path: 'config',
    type: SSO_SUPPORTED_INPUT_TYPES.SWITCH,
    canSendToServerOnCreateModule: false,
    canShowInUI: true,
    defaultValue: false,
    hasAdditionalDesc: true,
    idpOverrides: {
      SHIBBOLETH: {
        hasAdditionalDesc: false
      }
    }
  },
  {
    name: 'authn_request_signed',
    path: 'config',
    type: SSO_SUPPORTED_INPUT_TYPES.SWITCH,
    canSendToServerOnCreateModule: false,
    canShowInUI: true,
    defaultValue: false
  },
  {
    name: 'signature_algorithm',
    path: 'config',
    type: SSO_SUPPORTED_INPUT_TYPES.RADIO,
    canSendToServerOnCreateModule: false,
    radioValues: SAML_SIGNATURE_VALUES,
    canShowInUI: true,
    defaultValue: SAML_SIGNATURE_VALUES.RSA_SHA_256,
    isDisplayBlock: true,
    idpOverrides: {
      SHIBBOLETH: {
        radioValues: SAML_SIGNATURE_VALUES_WITHOUT_SHA512
      }
    }
  },
  {
    name: 'sp_slo_enabled',
    path: 'config',
    type: SSO_SUPPORTED_INPUT_TYPES.SWITCH,
    canSendToServerOnCreateModule: false,
    canShowInUI: true,
    defaultValue: false,
    displayValidator: (ssoModule, userType) => userType === SECURITY_AGENTS_STR //currently displayValidator works for advanced fields only
  },
  {
    name: 'sp_slo_http_binding',
    path: 'config',
    type: SSO_SUPPORTED_INPUT_TYPES.RADIO,
    canSendToServerOnCreateModule: true,
    radioValues: HTTP_BINDING_VALUES,
    canShowInUI: true,
    defaultValue: HTTP_BINDING_VALUES.HTTP_POST,
    displayValidator: (ssoModule, userType) => userType === SECURITY_AGENTS_STR, //currently displayValidator works for advanced fields only
    isDisplayBlock: true,
    parentField: {
      field: 'sp_slo_enabled',
      displayValidator: (singleLogoutEnabled) => !!singleLogoutEnabled
    },
    idpOverrides: {
      SHIBBOLETH: {
        canShowInUI: false,
        defaultValue: HTTP_BINDING_VALUES.HTTP_REDIRECT
      }
    }
  },
  {
    name: 'idp_slo_url',
    path: 'config',
    type: SSO_SUPPORTED_INPUT_TYPES.TEXT,
    canSendToServerOnCreateModule: false,
    canShowInUI: true,
    defaultValue: '',
    displayValidator: (ssoModule, userType) => userType === SECURITY_AGENTS_STR, //currently displayValidator works for advanced fields only
    isDisplayBlock: true,
    isDescriptionLess: true,
    required: true,
    parentField: {
      field: 'sp_slo_enabled',
      displayValidator: (singleLogoutEnabled) => !!singleLogoutEnabled
    },
    supportedErrors: [
      SECURITY_FIELDS_SERVER_ERRORS.MISSING_IDP_SLO_URL,
      SECURITY_FIELDS_SERVER_ERRORS.INVALID_IDP_SLO_URL
    ]
  },
  {
    name: 'saml_http_binding',
    path: 'config',
    type: SSO_SUPPORTED_INPUT_TYPES.RADIO,
    canSendToServerOnCreateModule: true,
    radioValues: HTTP_BINDING_VALUES,
    canShowInUI: true,
    defaultValue: HTTP_BINDING_VALUES.HTTP_POST,
    isDisplayBlock: true,
    idpOverrides: {
      SHIBBOLETH: {
        canShowInUI: false,
        defaultValue: HTTP_BINDING_VALUES.HTTP_POST
      }
    }
  },
  {
    name: 'data_sync_enabled',
    path: '',
    type: SSO_SUPPORTED_INPUT_TYPES.SWITCH,
    canSendToServerOnCreateModule: false,
    canShowInUI: true,
    defaultValue: true,
    displayValidator: (ssoModule, userType) => userType === SECURITY_AGENTS_STR //currently displayValidator works for advanced fields only
  },
  {
    name: 'display_name',
    path: 'config',
    disabled: false,
    type: SSO_SUPPORTED_INPUT_TYPES.TEXT,
    defaultValue: '',
    canShowInUI: true,
    required: false
  },
  {
    name: 'sso_title',
    path: 'config',
    disabled: false,
    type: SSO_SUPPORTED_INPUT_TYPES.TEXT,
    defaultValue: '',
    canShowInUI: false,
    required: false
  }
]

export default {
  BASIC: SAML_BASIC_FIELDS,
  ADVANCED: SAML_ADVANCED_FIELDS
}
