import { CONST, getQueryParams, getAllQueryParamsExcept } from '.'

export const createQueryParamObject = (queryParamsString) => {
  let allUrlQueryParams = {}

  for (let [key, value] of new URLSearchParams(queryParamsString).entries()) {
    allUrlQueryParams[key] = value
  }

  return allUrlQueryParams
}

export const addQueryParams = (queryParamsString, newParamEntries) => {
  let uRLSearchParams = new URLSearchParams(queryParamsString)

  Object.keys(newParamEntries).forEach((key) => {
    !uRLSearchParams.has(key) && uRLSearchParams.append(key, newParamEntries[key])
  })

  return uRLSearchParams.toString()
}

/**
 * construct and return search query param usually to
 * set during `/login` route redirection when user session is not present,
 * so post login user will land in the route he was intending to access
 */
export const getQueryParamsWithRedirectUri = (routeProps) => {
  const {
    location: { search }
  } = routeProps

  // for daypass consent screen, we need redirect uri with product_redirect_uri queryParams
  // after appending product_redirect_uri to redirect_uri we will have to remove in search
  return getAllQueryParamsExcept(
    [CONST.PRODUCT_REDIRECT_URI],
    addQueryParams(search, {
      [CONST.REDIRECT_URI]: appendProductRedirectUriToRedirectUri(
        `${window.location.origin}${window.location.pathname}`,
        search
      )
    })
  )
}

//
export const appendQueryParamsToRedirectUri = (redirectUri, newParamEntries = {}) => {
  return `${redirectUri}?${addQueryParams('', newParamEntries)}`
}

export const appendProductRedirectUriToRedirectUri = (redirectUri, search = '') => {
  let urlSearchParams = new URLSearchParams(search)
  if (urlSearchParams.has(CONST.PRODUCT_REDIRECT_URI)) {
    let mandatoryQueryParams = {
      [CONST.PRODUCT_REDIRECT_URI]: getQueryParams(CONST.PRODUCT_REDIRECT_URI, search)
    }
    if (urlSearchParams.has(CONST.CLIENT_ID))
      mandatoryQueryParams[CONST.CLIENT_ID] = getQueryParams(CONST.CLIENT_ID, search)
    return appendQueryParamsToRedirectUri(redirectUri, mandatoryQueryParams)
  }
  return redirectUri
}
