import React from 'react'
import PropTypes from 'prop-types'
import styled from 'react-emotion'
import { SecondaryText } from '../Typography'

const InlineMessageStyle = styled(SecondaryText)`
  color: ${({ theme, level }) => theme.colors.components.inlineMessage[level]};
  padding: 2px;
  line-height: 20px;
`

const InlineMessage = ({ level, id, children, className }) => (
  <InlineMessageStyle level={level} className={className} id={id} role="alert">
    {children}
  </InlineMessageStyle>
)

InlineMessage.propTypes = {
  level: PropTypes.oneOf(['info', 'warning', 'error', 'success']),
  id: PropTypes.string.isRequired
}

InlineMessage.defaultProps = {
  level: 'error'
}

export default InlineMessage
