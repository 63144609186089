import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { css } from 'react-emotion'
import { withTheme } from 'emotion-theming'

import TooltipText, { SUPPORTED_TOOLTIP_POSITIONS } from '../TooltipText/TooltipText'

const navItemContainerStyle = css`
  margin: 8px auto;
  text-align: center;
`

const mobileNavItemContainerStyle = css`
  margin: 5px;
  text-align: center;
`

const linkStyle = css`
  display: inline-block;
  width: 100%;
  height: 100%;
`

const activeItemStyle = ({ colors }) => {
  const {
    components: {
      railNavigation: { activeColor }
    }
  } = colors
  return css`
    background: ${activeColor};
    border-radius: 4px;
  `
}

const getToolTipStyle = (theme) => ({
  width: '110px',
  padding: '5px',
  textAlign: 'center',
  backgroundColor: `${theme.palette.e[900]}`
})

const isActive = (match, location, path) => {
  const { pathname } = location || {}

  if (!path) {
    return false
  }

  // if path and pathname is `/` (dashboard)
  if (path === '/' && pathname === '/') {
    return true
  }

  // routes other then / (dashboard) return false
  // because path will be `/` for for /users routes too
  // `/` routes serves as a parent route and isActive executes for all routes in left nav
  if (path === '/') {
    return false
  }

  // to handle other routes
  // for ex: /user should be a active case for /user/1234 too
  const routePathFragments = pathname.split('/').filter((path) => !!path)
  const toPath = path.split('/').filter((path) => !!path)
  return routePathFragments[0] === toPath[0]
}

class NavItem extends Component {
  render() {
    const { to, height, children, tooltipText, theme, isMobile = false } = this.props

    return (
      <TooltipText
        contentStyle={{ ...getToolTipStyle(theme) }}
        tooltipText={tooltipText}
        position={SUPPORTED_TOOLTIP_POSITIONS.RIGHT.CENTER}
        forceDisable={isMobile}>
        <li
          style={{
            height: `${height}px`,
            lineHeight: `${height}px`,
            position: 'relative',
            paddingLeft: '10px',
            paddingRight: '10px'
          }}
          className={isMobile ? mobileNavItemContainerStyle : navItemContainerStyle}>
          <NavLink
            isActive={(match, location) => {
              return isActive(match, location, to)
            }}
            to={to}
            className={linkStyle}
            activeClassName={activeItemStyle(theme)}
            aria-label={tooltipText}>
            {children}
          </NavLink>
        </li>
      </TooltipText>
    )
  }
}

NavItem.propTypes = {
  height: PropTypes.number,
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  tooltipText: PropTypes.string.isRequired
}

NavItem.defaultProps = {
  height: 40
}

NavItem.displayName = 'FreshworksSideNavigation.NavItem'

export default withTheme(NavItem)
