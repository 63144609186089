import React from 'react'
import { useLocaleState } from './LanguageProvider'

// We still extend "Component" instead of writing a functional component
// to provide a option to be able to change the local for traditional "Component"
// this HOC exists
const withLocale = (Component) => {
  return (props) => {
    const [localeConfig, setLocale] = useLocaleState()

    return <Component localeConfig={localeConfig} setLocale={setLocale} {...props} />
  }
}

export default withLocale
