import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { DataManagerContext } from './DataManager'

export default class Transition extends Component {
  render() {
    // here sucess and error are React components representing
    // the intermediate state post error or success
    // imagine a button turning green and showing 'Saved'
    // for 3s and then reverting back to the old UI
    const { children = null, success, error } = this.props

    return (
      <DataManagerContext.Consumer>
        {({ hooks }) => {
          if (hooks.requestDidSucceed && success) {
            return success
          } else if (hooks.requestDidFail && error) {
            return error
          }

          return children
        }}
      </DataManagerContext.Consumer>
    )
  }
}

Transition.propTypes = {
  children: PropTypes.node.isRequired,
  success: PropTypes.node,
  error: PropTypes.node
}
