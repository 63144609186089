import React from 'react'
import { useLocation, Redirect } from 'react-router-dom'

import { getLogoutUrlWithPostLogoutRedirectUrl } from '../../apis'
import { LinkText, PrimaryText } from '../../components/Typography'
import PropTypes from 'prop-types'
import { Flex, Box } from '../../components/Grid'
import { useIntl } from '../../components/IntlContext'
import { OneColumnContentBox } from '../../components/Layout'
import Icon from '../../components/Icon'
import Button from '../../components/Button'
import { css } from 'react-emotion'
import constants from '../../utils/constants'
import { addQueryParams, CONST, getQueryParams, queryParamsToObject } from '../../utils'
import UserSession from '../../components/UserSession'

const { DEFAULT_REDIRECT_URL } = constants

const WordBreakStyle = css`
  word-break: break-word;
`

const SessionExistMessage = ({ userEmail, redirectUrl, loginAgainRequired = true }) => {
  const { search } = useLocation()

  const queryParamObject = queryParamsToObject(search)

  const postLogoutRedirectUrlObject = new URL(
    redirectUrl || getQueryParams(CONST.REDIRECT_URI) || CONST.LOGIN_REDIRECT_URL
  )
  if (
    !postLogoutRedirectUrlObject.searchParams.has(CONST.ORIGIN) &&
    queryParamObject[CONST.ORIGIN]
  ) {
    postLogoutRedirectUrlObject.searchParams.append(CONST.ORIGIN, 'GLOBAL_USER_SEARCH')
  }
  if (
    !postLogoutRedirectUrlObject.searchParams.has(CONST.LOGIN_HINT_PARAM_KEY) &&
    queryParamObject[CONST.LOGIN_HINT_PARAM_KEY]
  ) {
    postLogoutRedirectUrlObject.searchParams.append(
      CONST.LOGIN_HINT_PARAM_KEY,
      queryParamObject[CONST.LOGIN_HINT_PARAM_KEY]
    )
  }

  const { toIntlString } = useIntl()
  return (
    <OneColumnContentBox>
      <OneColumnContentBox.OneColumnContentSection showLanguageDropdown={false} orgLogo={false}>
        <Flex flexDirection="column" alignItems="center">
          <Box>
            <Icon width={60} name="sad_lock" />
          </Box>
          <Box mt={30} mb={25}>
            <PrimaryText textWeight="bold">
              {toIntlString('session_exists.you_already_logged_in', {
                email: ' '
              })}
              <LinkText className={WordBreakStyle}>{userEmail}</LinkText>
            </PrimaryText>
            {loginAgainRequired ? (
              <PrimaryText textWeight="bold">
                {toIntlString('session_exists.login_again')}
              </PrimaryText>
            ) : (
              <PrimaryText textWeight="bold">
                {toIntlString('session_exists.user_choice_question')}
              </PrimaryText>
            )}
          </Box>
          <Box>
            <Flex>
              <Box my="5px" mx="15px">
                <a
                  href={getLogoutUrlWithPostLogoutRedirectUrl(
                    encodeURIComponent(postLogoutRedirectUrlObject.href)
                  )}>
                  <PrimaryText textWeight="bold" textType="error">
                    {toIntlString('session_exists.logout')}
                  </PrimaryText>
                </a>
              </Box>
              {!loginAgainRequired && (
                <Button
                  onClick={() => {
                    window.location.href = DEFAULT_REDIRECT_URL
                  }}>
                  {toIntlString('session_exists.continue')}
                </Button>
              )}
            </Flex>
          </Box>
        </Flex>
      </OneColumnContentBox.OneColumnContentSection>
    </OneColumnContentBox>
  )
}

const SessionExists = ({ userEmail = '', ...props }) => {
  const { state = {}, search } = useLocation()
  // This page is shown when the user try to login via magic link
  // with another user session form the same org, we show this to confirm their choice
  // Further enhancement: this has to replaced in all the places for the global /Components/SessionExists
  // Further enhancement: move this into /pages/SessionExists
  const currentUserEmail = userEmail || state.loggedInUserEmail
  return (
    <React.Fragment>
      {!!currentUserEmail ? (
        // If the user email is readily available from props/route state we can display them (Parallel login page scenario)
        <SessionExistMessage
          redirectUrl={state?.redirectUri}
          userEmail={currentUserEmail}
          {...props}
        />
      ) : (
        // Else fetch it from the app state context based on the session availability (Reauth page Scenarios)
        <>
          <UserSession.IfUserSessionExists>
            {(user) => <SessionExistMessage userEmail={user.email} {...props} />}
          </UserSession.IfUserSessionExists>
          <UserSession.IfUserSessionAbsent>
            <Redirect to={{ pathname: CONST.LOGIN_PATHNAME }} />
          </UserSession.IfUserSessionAbsent>
        </>
      )}
    </React.Fragment>
  )
}

SessionExists.propTypes = {
  userEmail: PropTypes.string,
  redirectUrl: PropTypes.string,
  loginAgainRequired: PropTypes.bool
}

export default React.memo(SessionExists)
