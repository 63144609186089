import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'

const TextInput = React.forwardRef((props, ref) => {
  const {
    type,
    name,
    disabled,
    placeholder,
    inputAttrs,
    readOnly,
    maxLength,
    minLength,
    required,
    isErrorPresent
  } = props
  const getConditionalProps = () =>
    isErrorPresent
      ? { 'aria-describedby': `${name}-error_msg`, 'aria-invalid': !!isErrorPresent }
      : { 'aria-invalid': !!isErrorPresent }
  return (
    <Field name={name}>
      {({ field, form, ...props }) => {
        let inputEl

        if (type === 'textarea') {
          inputEl = <textarea wrap="soft" />
        } else {
          inputEl = <input type={type} />
        }

        return (
          <inputEl.type
            readOnly={readOnly}
            ref={ref}
            {...inputEl.props}
            name={name}
            id={name}
            placeholder={placeholder}
            disabled={disabled}
            aria-disabled={disabled}
            aria-required={required}
            maxLength={maxLength}
            minLength={minLength}
            {...getConditionalProps()}
            {...field}
            {...inputAttrs}
            data-testid={name}
          />
        )
      }}
    </Field>
  )
})

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  inputAttrs: PropTypes.object,
  minLength: PropTypes.number,
  maxLength: PropTypes.number
}

TextInput.defaultProps = {
  type: 'text'
}

export default TextInput
