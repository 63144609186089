import { ENV, CONST } from './../utils'
import { getUrlPrefix } from '../utils/utility'

const API_HOST = ENV.HOST
const API_NAMESPACE = ENV.NAMESPACE

export const BASE_URL = `${API_HOST}${API_NAMESPACE}`

export const UPLOAD_USER_PROFILE_IMAGE = '/images'
export const RETRIEVE_USER_PROFILE_IMAGE = `/users/-/image?variant=ORIGINAL`
export const RETRIEVE_USER_PROFILE_IMAGE_MEDIUM = `/users/-/image?variant=MEDIUM`
export const LOGOUT_URL = `/logout`
export const ORGANISATION_URL = `/organisations`

export const getAPIURL = (resourceURL) => `${getUrlPrefix('')}${BASE_URL}${resourceURL}`

export const apiStatuses = {
  YET_TO_LOAD: 0,
  IS_LOADING: 1,
  SUCCESS: 2,
  ERROR: 3
}

export const getLogoutUrlWithPostLogoutRedirectUrl = (
  postLogoutRedirectURI = '',
  orgUrlOrigin = ''
) =>
  `${orgUrlOrigin}${getAPIURL(LOGOUT_URL)}?${CONST.POST_LOGOUT_REDIRECT_URI}=${
    postLogoutRedirectURI || orgUrlOrigin || window.location.href
  }`

export const logoutUser = (orgUrlOrigin = '') => {
  window.location = getLogoutUrlWithPostLogoutRedirectUrl('', orgUrlOrigin)
}
