import React, { createContext, Fragment, useContext } from 'react'
import { intlShape } from 'react-intl'
import defaultLangTranslation from './../default_translations/en-us.json'
import { flattenMessages } from './../utils/utility'

const defaultMessages = flattenMessages(defaultLangTranslation)

const Context = createContext()

export default function IntlContext({ children }, context) {
  return (
    <Fragment>
      <Context.Provider
        value={{
          ...context.intl,
          toIntlString: (translationPath, values = {}) => {
            return context.intl.formatMessage(
              {
                id: translationPath,
                defaultMessage: defaultMessages[translationPath]
              },
              { ...values }
            )
          }
        }}>
        {children}
      </Context.Provider>
    </Fragment>
  )
}

IntlContext.contextTypes = { intl: intlShape }

IntlContext.Consumer = Context.Consumer

const useIntl = () => useContext(Context)
export { useIntl }

export const withIntl = (Ele) => ({ children, ...rest }) => (
  <IntlContext.Consumer>
    {({ toIntlString }) => (
      <Ele toIntlString={toIntlString} {...rest}>
        {children}
      </Ele>
    )}
  </IntlContext.Consumer>
)
