import React, { useContext } from 'react'
import { Box } from '../Grid'
import { css } from 'emotion'
import IntlContext from '../IntlContext'
import { getFeedbackBotEnv, getUserName } from '../../utils'
import { Helmet } from 'react-helmet'
import { getAllAccountsFromProductsArray } from '../../pages/Dashboard/utils'
import Button from '../Button'
import { PrimaryText } from '../Typography'
import { AppStateContext } from '../AppDataProvider'
import { getDataFromAppStateContext } from '../AppDataProvider/AppStateContext'

const PointerCursorAndLineHeight = css`
  cursor: pointer;
  line-height: 12px;
`

const BOT_TEST_ACCOUNTS = ['bottest.freshdesk.com', 'myfreshserviceaccount.com']

const validAccountsForFeedbackBot = [
  ...BOT_TEST_ACCOUNTS,
  'support.freshdesk.com',
  'lighthouse.freshservice.com',
  'web.freshchat.com',
  'freshdesksupport.freshcaller.com',
  'freshdesk.freshsales.io',
  'freshworks.freshrelease.com',
  'freshworks.freshmarketer.com',
  'tgif.freshworks.com',
  'freshworks.success.freshworks.com'
]

//The feedback button is enabled only for freshworks internal usage
export const getAllAccountsValidToEnableFeedBackButton = (productList) => {
  const allAccountsSubscribedByTheUser = getAllAccountsFromProductsArray(productList)
  const userAccounts = allAccountsSubscribedByTheUser.filter((account) => {
    // filter the accounts present in validAccountsForFeedbackBot
    return (
      account.status === 'ACTIVATED' && validAccountsForFeedbackBot.indexOf(account.domain) > -1
    )
  })
  return userAccounts
}

const botEnv = getFeedbackBotEnv()

const FeedbackBotResources = React.memo(({ email, name }) => {
  return (
    <Helmet>
      <style>{`
      #fbots-wdgt.joe-hide{
        display: none!important;
      }
    `}</style>
      <script>
        {(function () {
          try {
            ;(function (d, w, c) {
              if (!d.getElementById('spd-busns-spt')) {
                var n = d.getElementsByTagName('script')[0],
                  s = d.createElement('script')
                var loaded = false
                s.id = 'spd-busns-spt'
                s.async = 'async'
                s.setAttribute('data-self-init', 'false')
                s.setAttribute('data-init-type', 'delayed')
                s.src = 'https://cdn.in-freshbots.ai/assets/share/js/freshbots.min.js'
                s.setAttribute('data-client', '96b0d980a28b7dee9dba3bd490ccf09b071cb9ed')
                s.setAttribute('data-bot-hash', '1875641acf5903160d821b37abfcc7b50c92df20')
                s.setAttribute('data-env', 'prod')
                s.setAttribute('data-region', 'in')
                if (c) {
                  s.onreadystatechange = s.onload = function () {
                    if (!loaded) {
                      c()
                    }
                    loaded = true
                  }
                }
                n.parentNode.insertBefore(s, n)
              }
            })(document, window, function () {
              window.Freshbots.initiateWidget(
                {
                  autoInitChat: false,
                  getClientParams: function () {
                    return {
                      'cstmr::eml': email,
                      initiated_from: 'freshid',
                      'cstmr::nm': name,
                      env: botEnv
                    }
                  }
                },
                function (successResponse) {},
                function (errorResponse) {}
              )
            })
          } catch (e) {
            console.log('Error loading FeedbackBot')
          }
        })()}
      </script>
    </Helmet>
  )
})

const FeedbackBot = () => {
  const getCurrentUserDetails = getDataFromAppStateContext(
    useContext(AppStateContext),
    'getCurrentUserDetails',
    {}
  )
  let userEmail = ''
  let restOfUser = {}
  let enableFeedbackButton = false
  if (!!getCurrentUserDetails.data) {
    enableFeedbackButton =
      getAllAccountsValidToEnableFeedBackButton(getCurrentUserDetails.data.productList).length > 0
    const { email, ...rest } = getCurrentUserDetails.data.user
    userEmail = email
    restOfUser = rest
  }
  return (
    <div>
      {enableFeedbackButton && (
        <Box pr={4}>
          <FeedbackBotResources email={userEmail} name={getUserName(restOfUser)} />
          <IntlContext.Consumer>
            {({ toIntlString }) => (
              <Button
                type="secondary"
                htmlType="button"
                data-testid="dashboard.support_bot"
                onClick={() => {
                  window.Freshbots.initiateChat(true)
                  window.Freshbots.showWidget(true)
                }}>
                <PrimaryText className={PointerCursorAndLineHeight}>
                  {toIntlString('components.feedback_bot.feedback_button')}
                </PrimaryText>
              </Button>
            )}
          </IntlContext.Consumer>
        </Box>
      )}
    </div>
  )
}

export default FeedbackBot
