import React, { memo, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { REACT_APP_HEAP_ID, REACT_APP_SENTRY_DNS, REACT_APP_SENTRY_RELEASE } from '../../config'

const HeapLoader = memo(() => (
  <Helmet>
    <script type="text/javascript">
      {`
      try{
        window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=t.forceSSL||"https:"===document.location.protocol,a=document.createElement("script");a.type="text/javascript",a.async=!0,a.src=(r?"https:":"http:")+"//cdn.heapanalytics.com/js/heap-"+e+".js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(a,n);for(var o=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],c=0;c<p.length;c++)heap[p[c]]=o(p[c])};
        var pathsToRedact = ['invite', 'reset-password', 'unlock', 'set-password'];
        var REDACT_STR = '********';
        var regExp = new RegExp('\\/+(' + pathsToRedact.join('|') + ')+\\/');
        var PROPS_TO_REDACT = {
          pageview_properties: ['path', 'previous_page'],
          session_properties: ['path', 'referrer'],
          event_properties: ['href']
        };
        function getStringFromMatches(str, matches) {
          return str.substring(0, matches.index) + matches[0] + REDACT_STR
        }
        function redactedValueIfRequired(payload, prop) {
          var str = payload[prop]
          var matches = str.match(regExp);
          if (matches && matches.length) {
            payload[prop] = getStringFromMatches(str, matches);
          }
        }
        heap.load(${REACT_APP_HEAP_ID}, {
          rewrite: function (payload) {
            for (var prop in PROPS_TO_REDACT) {
              var attributes = PROPS_TO_REDACT[prop];
              for (var i = 0; i < attributes.length; i++) {
                if (payload[prop] && payload[prop][attributes[i]]) {
                  redactedValueIfRequired(payload[prop], [attributes[i]]);
                }
              }
            }
            return payload;
          }
        });
      }catch(e){}
      onHeapLoad();
    `}
    </script>
  </Helmet>
))

const SentryLoader = memo(() => (
  <Helmet>
    <script
      src="https://browser.sentry-cdn.com/7.49.0/captureconsole.min.js"
      crossorigin="anonymous"
      onerror="onSentryLoad()"></script>
    <script type="text/javascript" data-testid="sentry-loader-script">
      {`
    function initSentry(){
      try{
        Sentry.init({
          dsn: '${REACT_APP_SENTRY_DNS}',
          release: '${REACT_APP_SENTRY_RELEASE}',
          integrations: [
            new Sentry.Integrations.CaptureConsole({
              levels: ['error']
            })
          ],
          tracesSampleRate: 1.0,
          beforeSend(event) {
            //** Exclude errors from browser extensions
            if (event.exception && event.exception.values) {
              const exceptionValue = event.exception.values[0];
              if (exceptionValue.stacktrace) {
                const frames = exceptionValue.stacktrace.frames;
                if (frames && frames.some(frame => frame.filename && (
                  frame.filename.includes('extensions/') || 
                  frame.filename.includes('chrome-extension://') || 
                  frame.filename.includes('safari-extension://') || 
                  frame.filename.includes('moz-extension://')
                ))) {
                  return null;
                }
              }
            }

            //** Exclude errors from third-party scripts
            if (event.request && event.request.url) {
              const thirdPartyScripts = ['recaptcha', 'googleapis', 'facebook', 'twitter'];
              if (thirdPartyScripts.some(script => event.request.url.includes(script))) {
                return null;
              }
            }

            //** Exclude irrelevant console errors
            if (event.message) {
              //** Add any console error message here which we want to exclude to reduce noice 
              const irrelevantMessages = ['Script error'];
              if (irrelevantMessages.some(msg => event.message.includes(msg))) {
                return null;
              }
            }

            //** Exclude reCAPTCHA related errors
              if (event.exception && event.exception.values) {
                const exceptionValue = event.exception.values[0];
                if (exceptionValue.stacktrace) {
                  const frames = exceptionValue.stacktrace.frames;
                  if (frames && frames.some(frame => frame.module && frame.module.includes('recaptcha'))) {
                    return null;
                  }
                }
              }


            //** Custom grouping logic
            if (event.exception) {
              //** default keyword will use sentry' default grouping mechanism 
              event.fingerprint = ['{{ default }}', event.message];
            }
            // ** Returning this event object after above filtering
            return event;
          }
        });
      } catch(e){}
      onSentryLoad();
    }
    `}
    </script>

    <script
      src="https://browser.sentry-cdn.com/5.4.3/bundle.min.js"
      crossOrigin="anonymous"
      onload="initSentry()"
      onerror="onSentryLoad()"></script>
  </Helmet>
))

export const TPSLoader = memo(({ children }) => {
  const [isHeapLoading, setIsHeapLoading] = useState(true)
  const [isSentryLoading, setIsSentryLoading] = useState(true)
  if (!window.onHeapLoad) {
    window.onHeapLoad = () => setIsHeapLoading(false)
  }
  if (!window.onSentryLoad) {
    window.onSentryLoad = () => setIsSentryLoading(false)
  }
  useEffect(() => {
    return () => {
      delete window.onHeapLoad
      delete window.onSentryLoad
    }
  }, [])
  return (
    <>
      <HeapLoader />
      <SentryLoader />
      {children({ isTpsLoading: isHeapLoading || isSentryLoading })}
    </>
  )
})
